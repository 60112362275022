import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ISession } from "./../../shared/data/interfaces/session/session.interface";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private path = `${environment.apiCadastro.auth}`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  getToken(code:string) {
    let params = new HttpParams().set('code', code);
    return this.http.get<ISession>(`${this.url}/saml/auth`,{params : params});
  }

}