import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouterModule } from "@angular/router";
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedModule } from "src/app/shared/shared.module";
import { CoreModule } from "./../core/core.module";
import { AceitePoliticaComponent } from "./aceite-politica.component";
import { AceitePoliticaRoutingModule } from "./aceite-politica-routing.module";
import { AceitePoliticaDetalhesComponent } from './components/aceite-politica-detalhes/aceite-politica-detalhes.component';
import { AceitePoliticaConcluidoComponent } from "./components/aceite-politica-concluido/aceite-politica-concluido.component";

@NgModule({
  declarations: [
    AceitePoliticaComponent,
    AceitePoliticaDetalhesComponent,
    AceitePoliticaConcluidoComponent
  ],
  imports: [
    CommonModule,
    AceitePoliticaRoutingModule,
    SharedModule,
    CoreModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDividerModule,
    RouterModule,
    MatTooltipModule,
  ],
})

export class AceitePoliticaModule { }
