<div class="header">
  <div class="header__title">Log Detalhes</div>
  <mat-icon matDialogClose>close</mat-icon>
</div>

<div class="body">
  <div class="grid">
    <app-log-item title="Data Exclusão" value="{{ data.DataExclusao | date:'dd/MM/yyy HH:mm' }}"></app-log-item>
    <app-log-item title="Nome" value="{{ data.Nome }} {{ data.Sobrenome }}"></app-log-item>
    <app-log-item title="Documento" [value]="data.Documento"></app-log-item>
    <app-log-item title="Tipo Documento" [value]="data.TipoDocumento"></app-log-item>
    <app-log-item title="Chamado" [value]="data.Chamado"></app-log-item>
    <app-log-item title="Origem" [value]="data.Origem"></app-log-item>
    <app-log-item title="Usuário" [value]="data.Usuario"></app-log-item>
  </div>
  
  <div class="descricao">
    <div class="descricao__label">Descrição</div>
    <div>{{ data.Descricao || '-' }}</div>
  </div>
</div>

<div class="footer">
  <button mat-button class="add-button" mat-dialog-close="">Fechar</button>
</div>
