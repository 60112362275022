<div class="container">
  <!-- <mat-toolbar style="background-color: #FAFAFA">
    <span style="margin-left: -18px;">Direitos do Titular > Pedidos do Titular</span>
    <div class="example-spacer ">
    </div>
  </mat-toolbar> -->

  <p class="page-title">Direitos do Titular > Pedidos do Titular</p>

  <div class="direitos-titular__container">
    <div class="direitos-titular__wrapper">
      <div class="direitos-titular__card">
        <div class="direitos-titular__titulo">Total de solicitações</div>
        <div class="direitos-titular__valor">{{ length }}</div>
      </div>
      <div class="direitos-titular__card">
        <div class="direitos-titular__titulo">Novas solicitações</div>
        <div class="direitos-titular__valor">{{ totalNova }}</div>
      </div>
      <div class="direitos-titular__card">
        <div class="direitos-titular__titulo">Solicitações em aberto</div>
        <div class="direitos-titular__valor">{{ totalAberto }}</div>
      </div>
      <div class="direitos-titular__card">
        <div class="direitos-titular__titulo">Solicitações concluídas</div>
        <div class="direitos-titular__valor">{{ totalConcluido }}</div>
      </div>
    </div>

    <div class="direitos-titular__tabela">
      <mat-card>
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="protocolo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>PROTOCOLO</th>
            <td mat-cell *matCellDef="let element">{{ element.Protocolo }}</td>
          </ng-container>

          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NOME/EMAIL </th>
            <td mat-cell *matCellDef="let element">{{ element.NomeCliente }} <br> {{element.EmailCliente}}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
            <td mat-cell *matCellDef="let element">{{ element.Status }}</td>
          </ng-container>

          <ng-container matColumnDef="dataCriacao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CRIADO EM </th>
            <td mat-cell *matCellDef="let element"> {{ element.DataCriacao | date:'dd/MM/yyy HH:mm' }} </td>
          </ng-container>

          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right;">
              <ng-container *rhsupercUtilsIsAllowed="'Admin'">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/lgpd/direitos-titular/{{element.Id}}">
                  <span>Detalhes</span>
                </button>
                <!-- <button mat-menu-item (click)="alterarStatus(element.Id, 1)">
                  <span>Abrir</span>
                </button> -->
                <button mat-menu-item class="text-success" (click)="alterarStatus(element.Id, 3)">
                  <span>Concluir</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item class="text-danger" (click)="alterarStatus(element.Id, 4)">
                  Cancelar
                </button>
              </mat-menu>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)"> </mat-paginator>

      </mat-card>
    </div>
  </div>
</div>