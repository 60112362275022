import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';

import { SolicitacaoService } from 'src/app/lgpd/services/solicitacao.service';
import { Solicitacao } from 'src/app/shared/interfaces/solicitacao.interface';
import { StatusSolicitacao } from 'src/app/shared/interfaces/status-solicitacao.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-direitos-titular',
  templateUrl: './direitos-titular.component.html',
  styleUrls: ['./direitos-titular.component.scss']
})
export class DireitosTitularComponent implements OnInit {

  name = "politicas";
  loading = true;
  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  filtro: string = "";
  totalNova: number = 0;
  totalAberto: number = 0;
  totalConcluido: number = 0;

  buscaPorSistemaModel = "Padrão";

  solicitacoesList: Solicitacao[] = [];
  displayedColumns: string[] = [
    "protocolo",
    "nome",
    "status",
    "dataCriacao",
    "menu",
  ];

  dataSource: MatTableDataSource<Solicitacao> = new MatTableDataSource<Solicitacao>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private solicitacaoService: SolicitacaoService,
    private loadingService: LoadingService,
    private toastService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadSolicitacoes();
  }

  loadSolicitacoes() {
    this.loadingService.setLoading(true);
    this.loading = true;

    this.solicitacaoService
      .listarTodos(this.pageIndex, this.pageSize)
      .then((listas) => {
        this.length = listas.TotalRecords;
        this.solicitacoesList = listas.Data;

        this.totalNova = this.solicitacoesList.filter(f => f.Status == "Novo").length;
        this.totalAberto = this.solicitacoesList.filter(f => f.Status == "Aberto").length;
        this.totalConcluido = this.solicitacoesList.filter(f => f.Status == "Concluido").length;

        this.dataSource = new MatTableDataSource(this.solicitacoesList);

        this.loading = false;
        this.loadingService.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
        this.loadingService.setLoading(false);
      });
  }

  updatePagination(event:PageEvent){
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadSolicitacoes();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  changePage(page: PageEvent) {
    this.pageIndex = page.pageIndex + 1;
    this.loadSolicitacoes();
  }

  alterarStatus(solicitacaoId: string, status: number) {
    const dados = {
      SolicitacaoId: solicitacaoId,
      Status: status
    } as StatusSolicitacao;

    this.loadingService.setLoading(true);
    this.solicitacaoService.alterarStatus(dados).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        let message = status == 1 ? "aberto" : status == 2 ? "enviado" : status == 3 ? "concluido" : "cancelado";
        this.toastService.success(`Pedido ${message} com sucesso!`);
        this.loadSolicitacoes();
      },
      (err) => {
        console.log(err);
        let message = status == 1 ? "abrir" : status == 2 ? "enviar" : status == 3 ? "concluir" : "cancelar";
        this.toastService.error(`Erro ao ${message} o pedido selecionado.`);
        this.loadingService.setLoading(false);
      });
  }

}
