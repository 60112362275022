<div class="container">

  <div class="page-title">SITES</div>

  <br>

  <div>
    <mat-card>
      <div class="sites__header" fxLayout="row" fxLayoutAlign="space-between center">

        <div class="sites__pesquisa">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>
              filter_alt
            </mat-icon>
            Filtro
          </button>
          <mat-menu #menu="matMenu">
            <p class="sites__menu-titulo">
              Buscar por sistemas:
            </p>
            <div *ngFor="let sistema of sistemasOrigem">
              <div class="sites__pesquisa-filtro">
                <mat-checkbox (change)=filtrarSistema(sistema)>{{sistema.Nome}}</mat-checkbox>
              </div>
            </div>
          </mat-menu>

          <div class="sites__pesquisa-container">
            <mat-icon>search</mat-icon>
            <input [ngModel]="search$ | async" (ngModelChange)="search$.next($event)" placeholder="Pesquisar por: Url" type="text">
          </div>
        </div>

        <button *rhsupercUtilsIsAllowed="'Admin'" mat-raised-button class="add-button" routerLink="/lgpd/sites/add" routerLinkActive="active">ADICIONAR</button>

      </div>

      <ng-container *ngIf="sitesList.length > 0">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sites__nome"> Site </th>
            <td mat-cell *matCellDef="let element" class="sites__nome"> {{ element.Url }} </td>
          </ng-container>

          <ng-container matColumnDef="sistemaOrigem">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px;"> SISTEMA ORIGEM </th>
            <td mat-cell *matCellDef="let element" style="padding-left: 10px;"> {{ element.SistemaOrigem.Nome }} </td>
          </ng-container>

          <ng-container matColumnDef="ativo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px;"> ATIVO </th>
            <td mat-cell *matCellDef="let element" style="padding-left: 10px;"> {{ element.Ativo == true ? "Ativo" :"Inativo" }} </td>
          </ng-container>

          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right;">
              <ng-container *rhsupercUtilsIsAllowed="'Admin'">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/lgpd/sites/edit/{{element.Id}}">
                  <span>Editar</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item class="text-danger" (click)="deletar(element.Id)">
                  Deletar
                </button>
              </mat-menu>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)"> </mat-paginator>

      </ng-container>

      <h2 *ngIf="sitesList.length == 0">Não há registro de sites</h2>

    </mat-card>
  </div>
</div>