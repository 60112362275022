<div class="container">

  <div class="page-title">GERENCIAR COOKIES</div>

  <br>

  <div>
    <mat-card>
      <div class="cookies__header" fxLayout="row" fxLayoutAlign="space-between center">

        <div class="cookies__pesquisa">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>
              filter_alt
            </mat-icon>
            Filtro
          </button>
          <mat-menu #menu="matMenu">
            <p class="cookies__menu-titulo">
              Buscar por Sites:
            </p>
            <div *ngFor="let site of sites">
              <div class="cookies__pesquisa-filtro">
                <mat-checkbox (change)=filtrarSite(site)>{{site.Url}}</mat-checkbox>
              </div>
            </div>

            <mat-divider></mat-divider>

            <p class="cookies__menu-titulo">
              Grupo de Cookies:
            </p>
            <div *ngFor="let grupoCookie of gruposCookie">
              <div class="cookies__pesquisa-filtro">
                <mat-checkbox (change)=filtrarGrupoCookie(grupoCookie)>{{grupoCookie.Descricao}}</mat-checkbox>
              </div>
            </div>
          </mat-menu>

          <div class="cookies__pesquisa-container">
            <mat-icon>search</mat-icon>
            <input [ngModel]="search$ | async" (ngModelChange)="search$.next($event)" placeholder="Pesquisar por: Nome, site, finalidade ou grupo" type="text">
          </div>
        </div>

        <ng-container *rhsupercUtilsIsAllowed="'Admin'">
        <button mat-raised-button class="add-second-button" routerLink="/lgpd/grupos-cookie/add" routerLinkActive="active">ADICIONAR GRUPO</button>
        <button mat-raised-button class="add-button" routerLink="/lgpd/cookies/add" routerLinkActive="active">ADICIONAR COOKIE</button>
        </ng-container>

      </div>

      <ng-container *ngIf="listCookies.length > 0">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NOME </th>
            <td mat-cell *matCellDef="let element"> {{element.Nome}} </td>
          </ng-container>

          <ng-container matColumnDef="site">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SITE </th>
            <td mat-cell *matCellDef="let element"> {{element.Site.Url}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> STATUS </th>
            <td mat-cell *matCellDef="let element"> {{element.StatusFormatado}} </td>
          </ng-container>

          <ng-container matColumnDef="finalidade">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> FINALIDADE </th>
            <td mat-cell *matCellDef="let element"> {{element.Finalidade }} </td>
          </ng-container>

          <ng-container matColumnDef="grupo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> GRUPO </th>
            <td mat-cell *matCellDef="let element"> {{element.GrupoCookie.Descricao }} </td>
          </ng-container>

          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right;">
              <ng-container *rhsupercUtilsIsAllowed="'Admin'">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/lgpd/cookies/edit/{{element.Id}}">
                  <span>Editar</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item class="text-danger" (click)="deletar(element.Id)">
                  Deletar
                </button>
              </mat-menu>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)"> </mat-paginator>

      </ng-container>

      <h2 *ngIf="listCookies.length == 0">Não há registro de cookies</h2>

    </mat-card>
  </div>
</div>