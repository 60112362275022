import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy, Inject } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { takeUntil, map, distinctUntilChanged } from 'rxjs/operators';
import { isAllowed, isNotAllowed } from './is-allowed';
import { Observable } from "rxjs";
import { InjectionToken } from '@angular/core';

export interface IsAllowedConfig {
  roles$?: Observable<string[] | null>;
}

export const IsAllowedConfigToken = new InjectionToken<IsAllowedConfig>('IsAllowedConfigToken');


@Directive({
  selector: '[rhsupercUtilsIsAllowed], [rhsupercUtilsIsNotAllowed]'
})
export class IsAllowedDirective implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();

  @Input('rhsupercUtilsIsAllowed') set isAllowed(value:  string | string[] | undefined | null) {
    this.config$.next({
      type: 'allowed',
      roles: value ?? []
    });
  }
  @Input('rhsupercUtilsIsNotAllowed') set isNotAllowed(value:  string | string[] | undefined | null) {
    this.config$.next({
      type: 'notAllowed',
      roles: value ?? []
    });
  }

  private readonly config$ = new BehaviorSubject<{
    type: 'allowed' | 'notAllowed',
    roles: string[] | string
  }>({
    type: 'allowed',
    roles: [],
  });

  readonly show$ = combineLatest([
    this.config.roles$ ?? of<string[]>([]),
    // this.config.roles$ ?? '',
    this.config$
  ]).pipe(
    takeUntil(this.destroy$),
    map(
      ([roles, config]) => config.type === 'allowed' ?
        isAllowed(config.roles, roles ?? []) :
        isNotAllowed(config.roles, roles ?? [])
    ),
    distinctUntilChanged(),
  );

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
    @Inject(IsAllowedConfigToken) private readonly config: IsAllowedConfig,
  ) { }

  ngOnInit(): void {
    this.show$.subscribe( show => {
      this.viewContainer.clear();

      if(show) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
