import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PoliticaService } from 'src/app/lgpd/services/politica.service';

import { SiteService } from 'src/app/lgpd/services/site.service';
import { TipoPoliticaService } from 'src/app/lgpd/services/tipo-politica.service';
import { Politica } from 'src/app/shared/interfaces/politica';
import { Site } from 'src/app/shared/interfaces/site.interface';
import { TipoPolitica } from 'src/app/shared/interfaces/tipo-politica.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-add-politica',
  templateUrl: './add-politica.component.html',
  styleUrls: ['./add-politica.component.scss']
})
export class AddPoliticaComponent implements OnInit {
  politica!: Politica;
  sites!: Site[];
  tiposPolitica!: TipoPolitica[];

  dadoPoliticaForm!: FormGroup;
  textoPoliticaForm!: FormGroup;
  configuracaoPoliticaForm!: FormGroup;

  loading = false;

  acoesList = [
    "Rascunho",
    "Publicada",
  ];

  visibilidadesList = [
    "Publica",
    "Privada",
  ];

  constructor(
    private politicaService: PoliticaService,
    private siteService: SiteService,
    private tipoPoliticaService: TipoPoliticaService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    this.politica = {} as Politica;
    const id = this.route.snapshot.paramMap.get('id');

    this.createForms();
    this.listarSites();
    this.listarTiposPolitica();

    if (id != null) {
      this.politicaService.dadosPolitica(id).subscribe(
        (res) => {
          this.politica = res.Data;

          this.dadoPoliticaForm.controls['Site'].setValue(this.politica.Site.Url);
          this.dadoPoliticaForm.controls['TipoPolitica'].setValue(this.politica.TipoPolitica.Descricao);
          this.configuracaoPoliticaForm.controls['Acao'].setValue(this.politica.Acao);
          this.configuracaoPoliticaForm.controls['Visibilidade'].setValue(this.politica.Visibilidade);

          this.loadingService.setLoading(false);
        },
        (err) => {
          console.log(err);
          this.toastService.error("Erro ao buscar os dados da Politica selecionada.");
          this.loadingService.setLoading(false);
        });
    }
    this.loadingService.setLoading(false);
  }

  createForms() {
    this.dadoPoliticaForm = new FormGroup({
      TipoPolitica: new FormControl("", [Validators.required]),
      Site: new FormControl("", [Validators.required]),
      Versao: new FormControl({ value: "", disabled: true }),
    });

    this.textoPoliticaForm = new FormGroup({
      Texto: new FormControl("", [Validators.required]),
    });

    this.configuracaoPoliticaForm = new FormGroup({
      Acao: new FormControl(""),
      Visibilidade: new FormControl(""),
      PoliticaAtual: new FormControl(""),
      Mensagem: new FormControl("", [Validators.required]),
      MensagemAtualizacao: new FormControl("", [Validators.required]),
      MensagemRespostaPositiva: new FormControl(""),
      MensagemRespostaNegativa: new FormControl(""),
      ReenviarConfirmacao: new FormControl("false"),
    });
  }

  listarSites() {
    this.loading = true;
    this.siteService.listarTodos().subscribe(
      (res) => {
        this.loading = false;
        this.sites = res.Data;
      },
      (err) => console.error(err)
    );
  }

  listarTiposPolitica() {
    this.loading = true;
    this.tipoPoliticaService.listarTodos().subscribe(
      (res) => {
        this.loading = false;
        this.tiposPolitica = res.Data;
      },
      (err) => console.error(err)
    );
  }

  salvar() {
    this.loadingService.setLoading(true);

    if (!this.dadoPoliticaForm.valid) {
      this.loadingService.setLoading(false);
      this.dadoPoliticaForm.markAllAsTouched();
      this.toastService.error(
        "Para prosseguir, preencha os campos obrigatórios da aba Dados da Política!"
      );
      return;
    }

    if (!this.textoPoliticaForm.valid) {
      this.loadingService.setLoading(false);
      this.textoPoliticaForm.markAllAsTouched();
      this.toastService.error(
        "Para prosseguir, preencha o campo obrigatório da aba Texto!"
      );
      return;
    }

    if (!this.configuracaoPoliticaForm.valid) {
      this.loadingService.setLoading(false);
      this.configuracaoPoliticaForm.markAllAsTouched();
      this.toastService.error(
        "Para prosseguir, preencha os campos obrigatórios da aba Configuração!"
      );
      return;
    }

    const dataForm = this.dadoPoliticaForm.getRawValue();
    const textoForm = this.textoPoliticaForm.getRawValue();
    const configuracaoForm = this.configuracaoPoliticaForm.getRawValue();

    let politica = this.dadoPoliticaForm.getRawValue() as Politica;
    politica = Object.assign(politica, textoForm);
    politica = Object.assign(politica, configuracaoForm);

    politica.Id = this.politica.Id != null ? this.politica.Id : undefined;
    politica.Site = this.sites.find(f => f.Url == dataForm.Site) as Site;
    politica.SiteId = politica.Site.Id != null ? politica.Site.Id : undefined;
    politica.TipoPolitica = this.tiposPolitica.find(f => f.Descricao == dataForm.TipoPolitica) as TipoPolitica;
    politica.TipoPoliticaId = politica.TipoPolitica.Id != null ? politica.TipoPolitica.Id : undefined;
    politica.Ativo = this.politica.Ativo;

    this.politicaService.salvar(politica).subscribe(
      (res) => {
        this.loadingService.setLoading(false);

        if (!politica.Id || politica.Id == undefined) {
          this.toastService.success("Política cadastrada com sucesso!");
        } else {
          this.toastService.success("Política atualizada com sucesso!");
        }

        setTimeout(() => {
          this.router.navigateByUrl('/lgpd/politicas');
        }, 2000);
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        if (!politica.Id || politica.Id == undefined) {
          this.toastService.error(err.error.Message, "Erro ao realizar o cadastro da política.");
        } else {
          this.toastService.error(err.error.Message, "Erro ao atualizar o cadastro da política.");
        }
      });
  }
}
