import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { TipoEmail } from "src/app/shared/interfaces/tipo-email.interface";
import { RespostaPadrao } from "src/app/shared/requests/resposta-padrao.interface";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TipoEmailService {
  private path = `${environment.apiCadastro.admin}/tiposemail`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  listarTodos() {
    return this.http.get<RespostaPadrao<TipoEmail[]>>(this.url);
  }

}