import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-aceite-politica-concluido',
  templateUrl: './aceite-politica-concluido.component.html',
  styleUrls: ['./aceite-politica-concluido.component.scss']
})
export class AceitePoliticaConcluidoComponent implements OnInit {
  loading = false;
  titlePage: string = "";

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.titlePage = this.route.snapshot.queryParamMap.get('title') || "Politica de Privacidade";
  }
}
