<div class="container">

  <div class="page-title">LOGS DE OPT-IN</div>

  <br>

  <div>
    <mat-card>
      <div class="logs__header" fxLayout="row" fxLayoutAlign="space-between center">

        <div class="logs__pesquisa" *ngIf="!loadingEmpresa">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>
              filter_alt
            </mat-icon>
            Filtro
          </button>
          <mat-menu #menu="matMenu">
            <p class="logs__menu-titulo">
              Buscar por sistemas:
            </p>

            <div *ngFor="let sistema of sistemasOrigem">
              <div class="logs__pesquisa-filtro">
                <mat-checkbox (change)=filtrarSistema(sistema)>{{sistema.Nome}}</mat-checkbox>
              </div>
            </div>

            <mat-divider></mat-divider>

            <p class="logs__menu-titulo">
              Sites
            </p>
            <div *ngFor="let site of sites">
              <div class="logs__pesquisa-filtro">
                <mat-checkbox (change)=filtrarSite(site)>{{site.Url}}</mat-checkbox>
              </div>
            </div>

            <mat-divider></mat-divider>

            <p class="logs__menu-titulo">
              Buscar por valor:
            </p>
            <div *ngFor="let valor of valores">
              <div class="logs__pesquisa-filtro">
                <mat-radio-button (change)=filtrarValores(valor)>{{ valor.label }}</mat-radio-button>
              </div>
            </div>
          </mat-menu>

          <div class="logs__pesquisa-container">
            <mat-icon>search</mat-icon>
            <input [ngModel]="search$ | async" (ngModelChange)="search$.next($event)" placeholder="Pesquisar por: CPF ou Nome" type="text">
          </div>
        </div>

      </div>

      <ng-container *ngIf="logsList.length > 0">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CLIENTE </th>
            <td mat-cell *matCellDef="let element"> {{element.Cliente}} </td>
          </ng-container>

          <ng-container matColumnDef="sistemaOrigem">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SISTEMA ORIGEM </th>
            <td mat-cell *matCellDef="let element"> {{element.SistemaOrigem}} </td>
          </ng-container>

          <ng-container matColumnDef="site">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SITE </th>
            <td mat-cell *matCellDef="let element"> {{element.Site}} </td>
          </ng-container>

          <ng-container matColumnDef="acao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AÇÃO </th>
            <td mat-cell *matCellDef="let element"> {{element.Acao}} </td>
          </ng-container>

          <ng-container matColumnDef="optin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> OPT-IN </th>
            <td mat-cell *matCellDef="let element"> {{element.Optin}} </td>
          </ng-container>

          <ng-container matColumnDef="dataAcao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DATA CRIAÇÃO </th>
            <td mat-cell *matCellDef="let element"> {{element.DataAcao | date:'dd/MM/yyy HH:mm'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)"> </mat-paginator>

      </ng-container>

      <h2 *ngIf="logsList.length == 0">Não há registro de logs</h2>

    </mat-card>
  </div>
</div>