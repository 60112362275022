<div class="container">

    <div class="page-title">LOGS DE OPT-OUT</div>
  
    <br>
  
    <div>
      <mat-card>
        <div class="logs__header" fxLayout="row" fxLayoutAlign="space-between center">  
          <div class="logs__pesquisa-container">
            <mat-icon>search</mat-icon>
            <input [ngModel]="search$ | async" (ngModelChange)="search$.next($event)" placeholder="Pesquisar por: CPF ou Motivo Optout" type="text">
          </div>
        </div>
  
        <ng-container *ngIf="logsList.length > 0">
          <table mat-table [dataSource]="dataSource" matSort>
  
            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLIENTE </th>
              <td mat-cell *matCellDef="let element"> {{element.Nome}} </td>
            </ng-container>
            <ng-container matColumnDef="documento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> DOCUMENTO </th>
              <td mat-cell *matCellDef="let element"> {{element.Documento}} </td>
            </ng-container>
  
            <ng-container matColumnDef="empresa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA </th>
              <td mat-cell *matCellDef="let element"> {{element.Empresa}} </td>
            </ng-container>
  
            <ng-container matColumnDef="motivo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> MOTIVO </th>
              <td mat-cell *matCellDef="let element"> {{element.Motivo}} </td>
            </ng-container>
            <ng-container matColumnDef="motivoOutros">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> MOTIVO OUTROS</th>
              <td mat-cell *matCellDef="let element"> {{element.MotivoOutros}} </td>
            </ng-container>
  
            <ng-container matColumnDef="dataCriacao">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> DATA CRIAÇÃO </th>
              <td mat-cell *matCellDef="let element"> {{element.DataCriacao | date:'dd/MM/yyy HH:mm'}} </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)"> </mat-paginator>
  
        </ng-container>
  
        <h2 *ngIf="logsList.length == 0">Não há registro de logs</h2>
  
      </mat-card>
    </div>
  </div>