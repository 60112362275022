<c-loading *ngIf="loading">
</c-loading>

<div class="container" *ngIf="!loading">

  <div style="text-align: center">
    <img src="assets/logo-login.svg" />
  </div>

  <div class="aceite-politica">

    <div class="aceite-politica__item">
      <div class="aceite-politica__item-wrapper">
        <div class="aceite-politica__item-corpo">
          <div class="titulo">
            {{ politica.Descricao }}
          </div>
          <div class="aceite-politica__container-descricao">
            <div class="aceite-politica__item-descricao" [innerHtml]="politica.Texto"></div>
          </div>
        </div>
      </div>

      <div class="aceite-politica__rodape"> </div>
    </div>

    <div class="botao__acoes" *ngIf="!atualizacao">
      <button class="botao__acoes-cancel" type="button" mat-button style="margin-right: 20px;">
        Cancelar
      </button>
      <button class="botao__acoes-save" type="button" mat-button (click)="aceitar()">
        Aceitar
      </button>
    </div>
  </div>

</div>