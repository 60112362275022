import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LogEdicao } from "src/app/shared/interfaces/log-acoes.interface";

@Component({
  selector: "app-log-edicao-detalhes",
  templateUrl: "./log-edicao-detalhes.component.html",
  styleUrls: ["./log-edicao-detalhes.component.scss"],
})
export class LogEdicaoDetalhesComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: LogEdicao) {}
}
