import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { LogOptOut } from 'src/app/shared/interfaces/log-optout.interface';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { LogOptOutService } from 'src/app/lgpd/services/log-optout-service';

@Component({
  selector: 'app-logs-optout-list',
  templateUrl: './logs-optout-list.component.html',
  styleUrls: ['./logs-optout-list.component.scss'],
})
export class LogsOptoutListComponent implements OnInit {

  name = "logs";
  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  filtro: string = "";
  loading = true;
  loadingEmpresa = true;

  logsList: LogOptOut[] = [];
  search$ = new BehaviorSubject('');
  searchString: string = "";

  displayedColumns: string[] = [
    "nome",
    "empresa",
    "motivo",
    "motivoOutros",
    "dataCriacao",
    "documento"
  ];
  dataSource: MatTableDataSource<LogOptOut> = new MatTableDataSource<LogOptOut>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private logOptOut: LogOptOutService,
    private loadingService: LoadingService,
  ) {
    this.listenSearch()
  }

  ngOnInit(): void {
    this.loadLogs();
  }

  loadLogs() {
    this.loadingService.setLoading(true);
    this.loading = true;

    const params = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      searchString: this.searchString
    }

    this.logOptOut
      .listarTodos(params)
      .then((listas) => {
        this.length = listas.TotalRecords;
        this.logsList = listas.Data;

        this.dataSource = new MatTableDataSource(this.logsList);
        this.loading = false;
        this.loadingService.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        this.loadingService.setLoading(false);
      });
  }

  updatePagination(event: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadLogs();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  changePage(page: PageEvent) {
    this.pageIndex = page.pageIndex + 1;
    this.loadLogs();
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.searchString = res;
      } else {
        this.searchString = "";
      }
      this.loadLogs();
    });
  }


}
