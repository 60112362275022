import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-log-item',
  templateUrl: './log-item.component.html',
  styleUrls: ['./log-item.component.scss'],
})
export class LogItemComponent {
  @Input() title = "";
  @Input() set value(value: any | any[]) {
    if (Array.isArray(value)) {
      this.label = value.join(", ");
    } else {
      this.label = value;
    }
  }

  label = "";
}
