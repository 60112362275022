import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { SolicitacaoService } from 'src/app/lgpd/services/solicitacao.service';
import { Comentario } from '../../interfaces/comentario.interface';
import { LoadingService } from '../../services/loading.service';
import { RolesService } from '../../services/roles.service';

@Component({
  selector: 'app-add-comentario',
  templateUrl: './add-comentario.component.html',
  styleUrls: ['./add-comentario.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddComentarioComponent implements OnInit {

  comentario!: Comentario;
  files: File[] = [];

  loading = false;

  form!: FormGroup;

  constructor(
    private solicitacaoService: SolicitacaoService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private rolesService: RolesService,
    public dialogRef: MatDialogRef<AddComentarioComponent>,
    public cdRef: ChangeDetectorRef,
    private toastService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: {
      solicitacaoId: string,
      comentarioId: string,
      nomeUsuario: string,
      enviarEmail: boolean
    }
  ) { }

  ngOnInit(): void {
    // this.loadingService.setLoading(true);
    // this.loading = true;

    this.comentario = {} as Comentario;

    this.createForms();

    // // rotina para edição de comentário
    // if (this.data.comentarioId != null) {
    //   this.solicitacaoService.dadosComentario(this.data.comentarioId).subscribe(
    //     (res) => {
    //       this.comentario = res.Data;
    //       // this.loadingService.setLoading(false);
    //       // this.loading = false;
    //       this.cdRef.detectChanges();
    //     },
    //     (err) => {
    //       console.log(err);
    //       // this.loadingService.setLoading(false);
    //       // this.loading = false;
    //     });
    // }
  }

  get username(): string {
    return this.rolesService.getUsername();
  }

  createForms() {
    this.form = new FormGroup({
      Comentario: new FormControl("", [Validators.required]),
    });
  }

  salvar() {
    const dadosForm = this.form.getRawValue();

    const formData = new FormData();

    for (let file of this.files) {
      formData.append(file.name, file);
    }

    const dados = {
      SolicitacaoId: this.data.solicitacaoId,
      Comentario: dadosForm.Comentario,
      Id: this.comentario?.Id || null,
      NomeUsuario: this.data.nomeUsuario,
      EnviarEmail: this.data.enviarEmail
    } as Comentario;

    if(this.comentario.Comentario == null || !this.comentario.Comentario.trim()){
      this.toastService.error("O comentário não pode ser vazio.");
      this.loadingService.setLoading(false);
      return;
    }

    this.solicitacaoService.salvarComentario(dados, formData).subscribe(
      (res: any) => {
        const comentarioId = res?.Id == null ? this.comentario.Id : res.Id;

        if (this.files.length > 0)
          this.solicitacaoService.uploadFileComentario(formData, comentarioId).subscribe(
            (res: any) => {
              this.toastService.success("Comentário adicionado com sucesso.");
              this.loadingService.setLoading(false);
              this.dialogRef.close();
            },
            (err: HttpErrorResponse) => {
              this.toastService.error("Erro ao adicionar comentário.");
              this.loadingService.setLoading(false);
            });
        else {
          this.toastService.success("Comentário adicionado com sucesso.");
          this.loadingService.setLoading(false);
          this.dialogRef.close();
        }
      },
      (err: HttpErrorResponse) => {
        this.toastService.error("Erro ao adicionar comentário.");
        this.loadingService.setLoading(false);
      });
  }

  onFileSelected(event: any) {
    this.files = event.target.files as File[];
    this.cdRef.detectChanges();
  }

}
