import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { TipoEmailService } from 'src/app/adm/services/tipo-email.service';
import { TipoTelefoneService } from 'src/app/adm/services/tipo-telefone.service';
import { Lead } from 'src/app/shared/interfaces/lead.interface';
import { TipoEmail } from 'src/app/shared/interfaces/tipo-email.interface';
import { TipoTelefone } from 'src/app/shared/interfaces/tipo-telefone.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { environment } from 'src/environments/environment';
import { LeadService } from '../../services/lead.service';

@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLeadComponent implements OnInit {

  tiposEmail: TipoEmail[] = [];
  tiposTelefone: TipoTelefone[] = [];
  tipoEmail: TipoEmail = {} as TipoEmail;
  tipoTelefone: TipoTelefone = {} as TipoTelefone;
  dadosForm!: FormGroup;
  nome: string = "";
  email: string = "";
  sobrenome: string = "";
  numero: string = "";
  cpf: string = "";

  loading = false;
  loadingComponentSubmitted = false

  constructor(
    private tipoEmailService: TipoEmailService,
    private tipoTelefoneService: TipoTelefoneService,
    private leadService: LeadService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastrService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.listarTiposEmail();
    this.listarTiposTelefone();
  }

  createForm() {
    this.dadosForm = new FormGroup({
      AceiteOptin: new FormControl(false, [Validators.required]),
      AceiteOptinProgram: new FormControl(false, [Validators.required]),
    });
  }

  listarTiposEmail() {
    this.loading = true;
    this.tipoEmailService.listarTodos().subscribe(
      (res) => {
        this.loading = false;
        this.tiposEmail = res.Data;
        this.tipoEmail = this.tiposEmail.find(f => f.Tipo == "Pessoal") as TipoEmail;
      },
      (err) => console.error(err)
    );
  }

  listarTiposTelefone() {
    this.loading = true;
    this.tipoTelefoneService.listarTodos().subscribe(
      (res) => {
        this.loading = false;
        this.tiposTelefone = res.Data;
        this.tipoTelefone = this.tiposTelefone.find(f => f.Tipo == "Celular") as TipoTelefone;
      },
      (err) => console.error(err)
    );
  }

  resetCampos() {
    this.nome = "";
    this.email = "";
    this.numero = "";
    this.cpf = "";
    this.sobrenome = "";
    this.dadosForm.controls['AceiteOptin'].setValue(false);
    this.dadosForm.controls['AceiteOptinProgram'].setValue(false);
    this.cdRef.markForCheck();
  }

   salvar() {
    if (this.nome != "" && this.email != "" && this.numero != "" && this.cpf != "") {

      const lead = {} as Lead;

      lead.Nome = this.nome;
      lead.Documento = this.cpf;
      lead.DataNascimento = "";
      lead.TipoDocumento = "CPF";
      lead.SobreNome = this.sobrenome;

      lead.Emails = [{
        Endereco: this.email,
        Tipo: this.tipoEmail.Tipo,
      }];

      lead.Telefones = [{
        DDI: "55",
        DDD: this.numero.slice(0, 2),
        Numero: this.numero.slice(2, 11),
        Tipo: this.tipoTelefone.Tipo,
      }];

      lead.Atributos = [
        {
          Nome: "optin_comunicacao",
          Valor: this.dadosForm.get("AceiteOptin")?.value == true ? "1" : "0"
        },
        {
          Nome: "Programa Fidelidade",
          Valor: this.dadosForm.get("AceiteOptinProgram")?.value ?"1" : "0"
        }, 
        {
          Nome: "ModoBrincar",
          Valor: "1"
        },
        {
          Nome: "ModoBrincarOrigem",
          Valor: this.route.snapshot.queryParams['origem'] ?? ""
        },
      ]
      this.loadingComponentSubmitted = true
      this.leadService.salvar(lead).subscribe(
        (res) => {
          this.loadingService.setLoading(false);
          this.toastService.success("Lead cadastrado com sucesso!");
          this.loadingComponentSubmitted = false
          this.resetCampos();
        },
        (err) => {
          this.loadingService.setLoading(false);
          this.loadingComponentSubmitted = false
          this.toastService.error(err.error.Message, "Erro ao realizar o cadastro do lead.");
        });

    } else {
      this.loadingService.setLoading(false);
      this.dadosForm.markAllAsTouched();
      this.toastService.error(
        "Para prosseguir, preencha todos os campos!"
      );
    }
  }

}
