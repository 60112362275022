<div class="container">

    <div class="page-title">ADICIONAR ATRIBUTO CAMPANHAS </div>

        <mat-card style="margin-top: 10px" class="mat-elevation-z8" *ngIf="!loading">
          <form [formGroup]="dadoAtributoForm">
  
            <mat-grid-list cols="3" rowHeight="70px">
                <mat-grid-tile [colspan]="1" [rowspan]=1>
                    <mat-form-field appearance="fill" class="ancho">
                      <mat-label>Nome Atributo Campanha</mat-label>
                      <input matInput formControlName="Nome">
                    </mat-form-field>
                  </mat-grid-tile>      
  
              <mat-grid-tile [colspan]="1" [rowspan]=1>
                <mat-form-field appearance="fill" class="ancho">
                  <mat-label>Empresa</mat-label>
                  <mat-select formControlName="Empresa">
                    <mat-option *ngFor="let empresa of empresaList" [value]="empresa">
                      {{ empresa }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-grid-tile>
  
            </mat-grid-list>
          </form>
        </mat-card>

    <mat-grid-list cols="1" rowHeight="70px">
      <mat-grid-tile [colspan]="1" [rowspan]=1>
        <div class="add-atributo__acoes">
          <button class="add-atributo__acoes-save" type="button" mat-button (click)="salvar()">
            Salvar
          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  
  </div>