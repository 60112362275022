import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";

import { NgxMaskModule } from "ngx-mask";

import { CrmRoutingModule }  from './crm-login-routing.module'
import { CrmLoginComponent } from "./crm-login/crm-login.component"; 
import { CoreModule } from "src/app/core/core.module";
import { SharedModule } from "src/app/shared/shared.module";
import { InterceptorModule } from "../shared/interceptors/interceptor.module";
import { ClipboardModule } from '@angular/cdk/clipboard';


@NgModule({
  imports: [
    CommonModule,
    CrmRoutingModule,
    SharedModule,
    CoreModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    FormsModule,
    InterceptorModule,
    NgxMaskModule.forChild(),
    ClipboardModule
  ],
  exports: [],
  declarations: [
    CrmLoginComponent

  ],
  providers: [
    
  ],
})

export class CrmLoginModule {}