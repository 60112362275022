import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { TipoPoliticaService } from 'src/app/lgpd/services/tipo-politica.service';
import { TipoPolitica } from 'src/app/shared/interfaces/tipo-politica.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-add-tipo-politica',
  templateUrl: './add-tipo-politica.component.html',
  styleUrls: ['./add-tipo-politica.component.scss']
})
export class AddTipoPoliticaComponent implements OnInit {

  tipoPolitica!: TipoPolitica;

  dadosForm!: FormGroup;

  loading = false;

  constructor(
    private tipoPoliticaService: TipoPoliticaService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    this.loading = true;
    this.tipoPolitica = {} as TipoPolitica;
    const id = this.route.snapshot.paramMap.get('id');

    this.createForms();

    if (id != null) {
      this.tipoPoliticaService.dadosPolitica(id).subscribe(
        (res) => {
          this.tipoPolitica = res.Data;

          this.loadingService.setLoading(false);
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loadingService.setLoading(false);
          this.loading = false;
        });
    }
    this.loading = false;
    this.loadingService.setLoading(false);
  }

  createForms() {
    this.dadosForm = new FormGroup({
      Descricao: new FormControl("", [Validators.required]),
    });
  }

  salvar() {
    this.loadingService.setLoading(true);

    let tipoPolitica = this.dadosForm.getRawValue() as TipoPolitica;

    tipoPolitica.Id = this.tipoPolitica.Id != null ? this.tipoPolitica.Id : undefined;

    this.tipoPoliticaService.salvar(tipoPolitica).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        let message = tipoPolitica.Id == undefined ? "cadastrado" : "atualizado";
        this.toastService.success(`Tipo de politica ${message} com sucesso!`);
        this.router.navigateByUrl('/lgpd/tipos-politica');
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        let message = tipoPolitica.Id == undefined ? "cadastrar" : "atualizar";
        this.toastService.error(`Erro ao ${message} o tipo de politica.`);
      });
  }
}
