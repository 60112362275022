<div class="container">
  <p class="page-title">
    Portal da Privacidade
  </p>

  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Privacidade">
      <app-portal-privacidade-detalhes>
      </app-portal-privacidade-detalhes>
    </mat-tab>
    <!-- <mat-tab label="Segurança">
      <app-portal-privacidade-seguranca>
      </app-portal-privacidade-seguranca>
    </mat-tab>
    <mat-tab label="Comunicações">
      <app-portal-privacidade-comunicacoes>
      </app-portal-privacidade-comunicacoes>
    </mat-tab> -->
  </mat-tab-group>

</div>