<c-loading *ngIf="loading"></c-loading>

<div class="container" *ngIf="!loading">
  <div style="text-align: center">
    <img src="assets/logo-login.svg" />
  </div>

  <div class="politica-aceita animate__backInUp">
    <h1 class="politica-aceita__title">{{ titlePage }}</h1>
    <h3 class="politica-aceita__subtitle">Aceita com sucesso!</h3>
    <span class="politica-aceita__warning">Já pode fechar essa página.</span>
  </div>
</div>
