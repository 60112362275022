<div class="container">

  <div class="page-title">Clientes</div>

  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Todos"></mat-tab>
  </mat-tab-group>

  <div>

    <mat-card>
      <div class="clientes__header" fxLayout="row" fxLayoutAlign="space-between center">

        <div class="clientes__pesquisa">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>
              filter_alt
            </mat-icon>
            Filtro
          </button>
          <mat-menu #menu="matMenu">
            <p class="clientes__menu-titulo">
              Buscar por sistemas:
            </p>
            <div *ngFor="let sistema of sistemasOrigem">
              <div class="clientes__pesquisa-filtro">
                <mat-checkbox (change)=filtrarSistema(sistema)>{{sistema.Nome}}</mat-checkbox>
              </div>
            </div>

            <mat-divider></mat-divider>
            <p class="clientes__menu-titulo">
              Clientes
            </p>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="clientesModel" (change)="loadClientes()">
              <button mat-menu-item>
                <mat-radio-button color="primary" labelPosition="before" [value]="'Todos'">
                  Todos
                </mat-radio-button>
              </button>
              <button mat-menu-item>
                <mat-radio-button color="primary" labelPosition="before" [value]="'Ativo'">
                  Ativo
                </mat-radio-button>
              </button>
              <button mat-menu-item>
                <mat-radio-button color="primary" labelPosition="before" [value]="'Inativo'">
                  Inativo
                </mat-radio-button>
              </button>
            </mat-radio-group>

          </mat-menu>

          <div class="clientes__pesquisa-container">
            <mat-icon>search</mat-icon>
            <input [ngModel]="search$ | async" (ngModelChange)="search$.next($event)" placeholder="Pesquisar por: nome, documento, email, telefone ou bandeira" type="text">
          </div>
        </div>

        <button *rhsupercUtilsIsAllowed="'Admin'" mat-raised-button class="add-button" routerLink="/adm/clientes/add" routerLinkActive="active">ADICIONAR</button>

      </div>

      <ng-container *ngIf="listClientes.length > 0">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="clientes__nome" style="padding-left: 2px;"> NOME </th>
            <td mat-cell *matCellDef="let element" class="clientes__nome" style="padding-left: 2px;">
              {{element.Nome}} {{element.SobreNome}}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px;"> EMAIL </th>
            <td mat-cell *matCellDef="let element" style="word-wrap: break-word; padding-left: 10px;">
              {{element.Emails.length !== 0 ? element.Emails[0].Endereco : ""}}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px;"> CLIENTE </th>
            <td mat-cell *matCellDef="let element" style="padding-left: 10px;"> {{element.Status}} </td>
          </ng-container>

          <ng-container matColumnDef="bandeira">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px;"> EMPRESA(S) </th>
            <td mat-cell *matCellDef="let element" style="padding-left: 10px;"> {{element.Empresa.Nome}} </td>
          </ng-container>

          <ng-container matColumnDef="sistemas">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px;"> SISTEMA(S) </th>
            <td mat-cell *matCellDef="let element" style="padding-left: 10px;"> {{element.Sistemas}} </td>
          </ng-container>

          <ng-container matColumnDef="atualizacao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="clientes__atualizacao" style="padding-left: 10px;"> ATUALIZAÇÃO </th>
            <td mat-cell *matCellDef="let element" class="clientes__atualizacao" style="padding-left: 10px;"> {{element.DataAtualizacao | date:'dd/MM/yyy HH:mm'}} </td>
          </ng-container>

          <ng-container matColumnDef="ativo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px;"> STATUS </th>
            <td mat-cell *matCellDef="let element" style="padding-left: 10px;"> {{ element.Ativo == true ? "Ativo" :"Inativo" }} </td>
          </ng-container>

          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right;">
              <ng-container *rhsupercUtilsIsNotAllowed="'GPO','Consulta'">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item routerLink="/adm/clientes/edit/{{element.Id}}">
                    <span>Editar</span>
                  </button>
                  <button mat-menu-item (click)="alterarAtivo(element.Id, true)" *rhsupercUtilsIsAllowed="'Admin'">
                    <span>Ativar</span>
                  </button>
                  <button mat-menu-item (click)="alterarAtivo(element.Id, false)" *rhsupercUtilsIsAllowed="'Admin'">
                    <span>Inativar</span>
                  </button>
                  <!-- <button mat-menu-item class="text-success" (click)="alterarStatus(element.Id, 1)">
                    <span>Aprovar Cliente</span>
                  </button> -->
                  <mat-divider></mat-divider>
                  <button mat-menu-item class="text-danger" (click)="remover(element.Id)" *rhsupercUtilsIsAllowed="'Admin'">
                    Remover
                  </button>
                </mat-menu>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30, 100]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)">
        </mat-paginator>

      </ng-container>
      <h2 *ngIf="listClientes.length == 0">Não há registro de clientes</h2>

    </mat-card>
  </div>

</div>
