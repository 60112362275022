import { SessionService } from "src/app/shared/services/session.service";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class OnlyCrmGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const resultado = this.sessionService.getSession();

    if (!resultado) {
      this.toastr.error("Você não tem permissão para acessar essa página.");
      return false
    }
    
    return resultado ? true : this.router.navigate(["/crm-login"]);
  }
}
