import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { CookieService } from 'src/app/lgpd/services/cookie.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SiteService } from 'src/app/lgpd/services/site.service';
import { GrupoCookieService } from 'src/app/lgpd/services/grupo-cookie.service';

import { CookieList } from 'src/app/shared/interfaces/cookie-list.interface';
import { Site } from 'src/app/shared/interfaces/site.interface';
import { GrupoCookie } from 'src/app/shared/interfaces/grupo-cookie.interface';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cookies-list',
  templateUrl: './cookies-list.component.html',
  styleUrls: ['./cookies-list.component.scss']
})
export class CookiesListComponent implements OnInit {

  name = "cookies";
  loading = true;
  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  filtro: string = "";

  buscaPorSistemaModel = "Padrão";
  cookiesModel = "Todos";

  sites: Site[] = [];
  gruposCookie: GrupoCookie[] = [];
  grupoCookieIdsSelected?: string[] = [];
  sitesIdsSelected?: string[] = [];
  search$ = new BehaviorSubject('');
  searchString: string = "";

  listCookies: CookieList[] = [];
  displayedColumns: string[] = [
    "nome",
    "site",
    "status",
    "finalidade",
    "grupo",
    "menu",
  ];

  dataSource: MatTableDataSource<CookieList> = new MatTableDataSource<CookieList>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private cookieService: CookieService,
    private loadingService: LoadingService,
    private siteService: SiteService,
    private grupoCookieService: GrupoCookieService,
    private toastService: ToastrService,
  ) {
    this.listenSearch();
  }

  ngOnInit(): void {
    this.loadCookies();
    this.listarGruposCookie();
    this.listarSites();
  }

  loadCookies() {
    this.loadingService.setLoading(true);
    this.loading = true;

    const params = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      searchString: this.searchString,
      gruposCookiesIds: this.grupoCookieIdsSelected,
      sitesIds: this.sitesIdsSelected
    }

    this.cookieService
      .listarTodos(params)
      .then((listas) => {
        this.length = listas.TotalRecords;
        this.listCookies = listas.Data;

        this.listCookies.forEach(f => {
          f.StatusFormatado = !f.Status ? "Inativo" : "Ativo";
        });

        this.dataSource = new MatTableDataSource(this.listCookies);
        this.loading = false;
        this.loadingService.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        this.loadingService.setLoading(false);
      });
  }

  updatePagination(event:PageEvent){
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadCookies();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  changePage(page: PageEvent) {
    this.pageIndex = page.pageIndex + 1;
    this.loadCookies();
  }

  deletar(id: string) {
    this.loadingService.setLoading(true);
    this.cookieService.deletar(id).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        this.toastService.success("Cookie deletado com sucesso!");
        this.loadCookies();
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        this.toastService.error("Erro ao deletar o cookie selecionado.");
      });
  }

  listarSites() {
    this.siteService.listarTodos().subscribe(
      (res) => {
        this.sites = res.Data;
      },
      (err) => console.error(err)
    );
  }

  listarGruposCookie() {
    this.grupoCookieService.listarTodos().subscribe(
      (res) => {
        this.gruposCookie = res.Data;
      },
      (err) => console.error(err)
    );
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.searchString = res;
      } else {
        this.searchString = "";
      }
      this.loadCookies();
    });
  }

  filtrarGrupoCookie(grupoCookie: GrupoCookie) {
    if (!this.grupoCookieIdsSelected?.includes(grupoCookie.Id)) {
      this.grupoCookieIdsSelected?.push(grupoCookie.Id);
    } else {
      const index = this.grupoCookieIdsSelected?.indexOf(grupoCookie.Id, 0);

      this.grupoCookieIdsSelected?.splice(index, 1);
    }

    this.loadCookies();
  }

  filtrarSite(site: Site) {
    if (!this.sitesIdsSelected?.includes(site.Id || "")) {
      this.sitesIdsSelected?.push(site.Id || "");
    } else {
      const index = this.sitesIdsSelected?.indexOf(site.Id || "", 0);

      this.sitesIdsSelected?.splice(index, 1);
    }

    this.loadCookies();
  }
}
