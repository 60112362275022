import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";

import { NgxMaskModule } from "ngx-mask";

import { ClipboardModule } from '@angular/cdk/clipboard';
import { AdmRoutingModule } from "src/app/adm/adm-routing.module";
import { AdmComponent } from 'src/app/adm/adm/adm.component';
import { AddClienteComponent } from "src/app/adm/clientes/components/add-cliente/add-cliente.component";
import { ClientesComponent } from "src/app/adm/clientes/pages/list/list.component";
import { AtributoCampanhasComponent } from "src/app/adm/atributo-campanhas/pages/add-atributo/add-atributo-campanhas.component";

import { ClienteService } from "src/app/adm/services/cliente.service";
import { CoreModule } from "src/app/core/core.module";
import { SharedModule } from "src/app/shared/shared.module";
import { InterceptorModule } from "../shared/interceptors/interceptor.module";
import { RemoveClienteDialogComponent } from "./clientes/components/remove-cliente-dialog/remove-cliente-dialog.component";
import { SistemasOrigemListComponent } from './sistemas-origem/pages/sistemas-origem-list/sistemas-origem-list.component';

@NgModule({
  imports: [
    CommonModule,
    AdmRoutingModule,
    SharedModule,
    CoreModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    FormsModule,
    InterceptorModule,
    NgxMaskModule.forChild(),
    ClipboardModule
  ],
  exports: [],
  declarations: [
    AddClienteComponent,
    ClientesComponent,
    AtributoCampanhasComponent,
    AdmComponent,
    SistemasOrigemListComponent,
    RemoveClienteDialogComponent
  ],
  providers: [
    ClienteService
  ],
})

export class AdmModule {}
