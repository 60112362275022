import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Politica } from 'src/app/shared/interfaces/politica';
import { PoliticaList } from 'src/app/shared/interfaces/politica-list.interface';
import { ParamsDefault } from 'src/app/shared/requests/params-padrao.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PoliticaService {
  private path = `${environment.apiCadastro.lgpd}/politica`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
    // private sessionService: SessionService
  ) { }

  listarTodosPaginado(filter: ParamsDefault): Promise<RespostaPadrao<PoliticaList[]>> {
    let params = new HttpParams()
      .set('pageNumber', String(filter.pageNumber))
      .set('pageSize', String(filter.pageSize));

    if (filter.searchString != null && filter.searchString.length > 0) {
      params = params.set('searchString', String(filter.searchString))
    }

    if (filter.tiposPoliticaIds != null && filter.tiposPoliticaIds.length > 0) {
      params = params.set('tiposIds', JSON.stringify(filter.tiposPoliticaIds));
    }

    if (filter.sitesIds != null && filter.sitesIds.length > 0) {
      params = params.set('sitesIds', JSON.stringify(filter.sitesIds));
    }

    return this.http.get<RespostaPadrao<PoliticaList[]>>(
      this.url,
      {
        params,
        // headers,
      }
    )
      .toPromise();
  }

  listarTodos() {
    const path = `${environment.apiCadastro.lgpd}/politicas`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.get<RespostaPadrao<PoliticaList[]>>(url);
  }

  salvar(dados: Politica) {
    if (dados.Id == null)
      return this.http.post<Politica>(this.url, dados);
    else
      return this.http.put<Politica>(this.url, dados);
  }

  dadosPolitica(id: string) {
    return this.http.get<RespostaPadrao<Politica>>(`${this.url}/${id}`);
  }

  deletar(id: string) {
    return this.http.delete(`${this.url}/${id}`);
  }

}
