import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { AceitePolitica } from 'src/app/shared/interfaces/aceite-politica.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { AceitePoliticaService } from '../../services/aceite-politica.service';

@Component({
  selector: 'app-aceite-politica-detalhes',
  templateUrl: './aceite-politica-detalhes.component.html',
  styleUrls: ['./aceite-politica-detalhes.component.scss']
})
export class AceitePoliticaDetalhesComponent implements OnInit {

  atributoId: string = "";
  clienteId: string = "";
  atualizacao: boolean = false;
  loading = false;

  politica!: AceitePolitica;

  constructor(
    private aceitePoliticaService: AceitePoliticaService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private loadingService: LoadingService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.atributoId = this.route.snapshot.paramMap.get('atributoId') as string;
    this.clienteId = this.route.snapshot.paramMap.get('clienteId') as string;
    this.atualizacao = JSON.parse(this.route.snapshot.paramMap.get('atualizacao') || 'false');

    this.loadDadosPolitica();

    this.loadingService.loading$.subscribe((loadingStatus) => {
      this.loading = loadingStatus;
      this.cdRef.detectChanges();
    });

  }

  loadDadosPolitica() {
    this.loadingService.setLoading(true);
    this.loading = true;

    this.aceitePoliticaService.dadosPolitica(this.atributoId).subscribe(
      (res) => {
        this.politica = res;

        this.loadingService.setLoading(false);
        this.loading = false;
      },
      (err) => {
        this.loadingService.setLoading(false);
        this.loading = false;
      });
  }

  aceitar() {
    this.loadingService.setLoading(true);
    this.loading = true;

    this.aceitePoliticaService.aceitarPolitica(this.atributoId, this.clienteId).subscribe(
      (res: any) => {
        this.loadingService.setLoading(false);
        this.loading = false;
        this.router.navigate(['/aceite-politica/concluido'], { queryParams: { title: this.politica.Descricao } });
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open(err.error.Message, "", { duration: 4000 });
        this.loadingService.setLoading(false);
      });
  }

}
