<div class="container">

  <div class="page-title">LOGS COOKIES</div>

  <br>
    <mat-card>
      <div class="logs__header">
        <form class="logs__pesquisa" [formGroup]="form">
          <mat-form-field appearance="fill">
            <mat-label>Data Inicial</mat-label>
            <input formControlName="dataInicial" matInput mask="M0/0000" [dropSpecialCharacters]="false" [showMaskTyped]="true" (keydown.enter)="filterLogs()">
          </mat-form-field>
          
          <mat-form-field appearance="fill">
            <mat-label>Data Final</mat-label>
            <input formControlName="dataFinal" matInput mask="M0/0000" [dropSpecialCharacters]="false" [showMaskTyped]="true" (keydown.enter)="filterLogs()">
          </mat-form-field>
        </form>
        <button mat-raised-button class="add-button" [disabled]="form.invalid" (click)="filterLogs()">
          <mat-icon>search</mat-icon>
          <span>Filtrar</span>
        </button>
      </div>
      
      <!-- Tratar lista vazia -->
      <ng-container *ngIf="dataSource.data.length">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> MÊS / ANO </th>
            <td mat-cell *matCellDef="let element"> {{ element.formattedDate }} </td>
          </ng-container>
          
          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right;">
              <ng-container *rhsupercUtilsIsAllowed="'Admin'">
                <button mat-icon-button aria-label="Ações" (click)="downloadCsv(element.date)">
                  <mat-icon>download</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <!-- <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)"> </mat-paginator> -->

      </ng-container>

      <h2 *ngIf="!dataSource.data.length">Não há registro de logs</h2>

    </mat-card>
</div>
