<div class="container">

  <div class="page-title"> GERENCIAR POLÍTICAS > LAYOUT POLÍTICA </div>

  <mat-tab-group animationDuration="0ms">

    <mat-tab label="Dados da Política">
      <mat-card style="margin-top: 10px" class="mat-elevation-z8" *ngIf="!loading">
        <form [formGroup]="dadoPoliticaForm">

          <mat-grid-list cols="3" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Tipo Política</mat-label>
                <mat-select formControlName="TipoPolitica">
                  <mat-option *ngFor="let tipoPolitica of tiposPolitica" [value]="tipoPolitica.Descricao">
                    {{ tipoPolitica.Descricao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Site</mat-label>
                <mat-select formControlName="Site">
                  <mat-option *ngFor="let site of sites" [value]="site.Url">
                    {{ site.Url }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1 *ngIf="politica.Id != null">
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Versão</mat-label>
                <input formControlName="Versao" #Versao matInput [(ngModel)]="politica.Versao" maxlength="10" readonly>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Texto">
      <mat-card style="margin-top: 10px" class="mat-elevation-z8" *ngIf="!loading">
        <form [formGroup]="textoPoliticaForm">

          <mat-grid-list cols="1" rowHeight="380px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Texto</mat-label>
                <textarea matInput formControlName="Texto" [(ngModel)]="politica.Texto" cdkTextareaAutosize cdkAutosizeMinRows="20" cdkAutosizeMaxRows="20"></textarea>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Configuração">
      <mat-card style="margin-top: 10px" class="mat-elevation-z8" *ngIf="!loading">
        <form [formGroup]="configuracaoPoliticaForm">

          <mat-grid-list cols="2" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Ação</mat-label>
                <mat-select formControlName="Acao">
                  <mat-option *ngFor="let acao of acoesList" [value]="acao">
                    {{ acao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Visibilidade</mat-label>
                <mat-select formControlName="Visibilidade">
                  <mat-option *ngFor="let visibilidade of visibilidadesList" [value]="visibilidade">
                    {{ visibilidade }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <mat-grid-list cols="2" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <section style="width: 100%; margin-left: 24px;">
                <mat-checkbox formControlName="PoliticaAtual" [(ngModel)]="politica.PoliticaAtual">Política Atual</mat-checkbox>
              </section>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]=1 *ngIf="politica.Id != null">
              <section style="width: 100%; margin-left: 24px;">
                <mat-checkbox formControlName="ReenviarConfirmacao" [(ngModel)]="politica.ReenviarConfirmacao">Reenviar Confirmação</mat-checkbox>
              </section>
            </mat-grid-tile>
          </mat-grid-list>

          <mat-grid-list cols="1" rowHeight="380px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Mensagem</mat-label>
                <textarea 
                  matInput
                  formControlName="Mensagem"
                  #Mensagem [(ngModel)]="politica.Mensagem"
                  maxlength="150"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="20"
                  cdkAutosizeMaxRows="20"></textarea>
                <mat-hint align="end">{{Mensagem.value.length}} / 150</mat-hint>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Mensagem de Atualização</mat-label>
                <textarea 
                  matInput
                  formControlName="MensagemAtualizacao"
                  #MensagemAtualizacao
                  [(ngModel)]="politica.MensagemAtualizacao"
                  maxlength="150"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="20"
                  cdkAutosizeMaxRows="20"></textarea>
                <mat-hint align="end">{{MensagemAtualizacao.value.length}} / 150</mat-hint>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Mensagem de resposta Positiva</mat-label>
                <textarea 
                  matInput
                  formControlName="MensagemRespostaPositiva"
                  #MensagemRespostaPositiva
                  [(ngModel)]="politica.MensagemRespostaPositiva"
                  maxlength="150"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="20"
                  cdkAutosizeMaxRows="20"></textarea>
                <mat-hint align="end">{{MensagemRespostaPositiva.value.length}} / 150</mat-hint>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Mensagem de resposta Negativa</mat-label>
                <textarea 
                  matInput
                  formControlName="MensagemRespostaNegativa"
                  #MensagemRespostaNegativa
                  [(ngModel)]="politica.MensagemRespostaNegativa"
                  maxlength="150"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="20"
                  cdkAutosizeMaxRows="20"></textarea>
                <mat-hint align="end">{{MensagemRespostaNegativa.value.length}} / 150</mat-hint>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

        </form>
      </mat-card>
    </mat-tab>

  </mat-tab-group>

  <mat-grid-list cols="1" rowHeight="70px">
    <mat-grid-tile [colspan]="1" [rowspan]=1>
      <div class="add-politica__acoes">
        <button class="add-politica__acoes-cancel" type="button" mat-button style="margin-right: 20px;" routerLink="/lgpd/politicas">
          Cancelar
        </button>
        <button class="add-politica__acoes-save" type="button" mat-button (click)="salvar()">
          Salvar
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

</div>