import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { ClienteCrm } from 'src/app/shared/interfaces/cliente-crm.interface';
import { environment } from 'src/environments/environment';
import { AutorizacaoGerente } from 'src/app/shared/interfaces/autorizacao-gerente.interface';
import { SessionService } from 'src/app/shared/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class CadastroCrmService {
  private path = "clientebasico";
  private url = `${environment.apiCadastro.baselocal}${this.path}`;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { 
    
  }


  enviarCodigo(cpf: string,telefone:string,vendedor:string,email:string,atualizar:boolean) {
    const session =  this.sessionService.getSession();
    const loja = session.loja??"";
    const origem = environment.origem;
    const flg = atualizar?"true":"false";

    const headers= new HttpHeaders()
    .set('apikey', session.apikey!)
    return this.http.post<any>(`${this.url}/enviarcodigo?loja=${loja}&origem=${origem}&atualizacao=${flg}`,{documento:cpf,telefone,vendedor,email},{headers:headers});
  }

  confirmarCadastro(cliente:ClienteCrm,atualizar:boolean){
    const session =  this.sessionService.getSession();
    const loja = session.loja??"";
    const origem = environment.origem;
    const flg = atualizar?"true":"false";

    const headers= new HttpHeaders()
    .set('apikey',session.apikey!)       
    return this.http.post<ClienteCrm>(`${this.url}?loja=${loja}&origem=${origem}&atualizacao=${flg}`,cliente,{headers:headers});
  }

  autorizacaoGerente(autorizacao:AutorizacaoGerente){
    const session =  this.sessionService.getSession();
    const headers= new HttpHeaders()
    .set('apikey', session.apikey!) 
    
    return this.http.post<AutorizacaoGerente>(`${this.url}/VerificarGerente`,autorizacao,{headers:headers});      
  }

  obterApiKey(){
    return this.http.get<any>(`${this.url}/user-info`);      
  }
}
