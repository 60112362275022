import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Site } from 'src/app/shared/interfaces/site.interface';
import { ParamsDefault } from 'src/app/shared/requests/params-padrao.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private path = `${environment.apiCadastro.lgpd}/site`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  listarTodos() {
    const path = `${environment.apiCadastro.lgpd}/sites`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.get<RespostaPadrao<Site[]>>(url);
  }

  listarTodosPaginado(filter: ParamsDefault): Promise<RespostaPadrao<Site[]>> {
    let params = new HttpParams()
      .set('pageNumber', String(filter.pageNumber))
      .set('pageSize', String(filter.pageSize));

    if (filter.searchString != null && filter.searchString.length > 0) {
      params = params.set('searchString', String(filter.searchString))
    }

    if (filter.sistemasOrigemIds != null && filter.sistemasOrigemIds.length > 0) {
      params = params.set('sistemasOrigemIds', JSON.stringify(filter.sistemasOrigemIds));
    }

    // const session = this.sessionService.getSession();

    // const headers = new HttpHeaders().set(
    //   'Authorization',
    //   `${session.token_type} ${session.access_token}`
    // );

    return this.http.get<RespostaPadrao<Site[]>>(
      this.url,
      {
        params,
        // headers,
      }
    )
      .toPromise();
  }

  salvar(dados: Site) {
    if (dados.Id == null)
      return this.http.post<Site>(this.url, dados);
    else
      return this.http.put<Site>(this.url, dados);
  }

  dadosSite(id: string) {
    return this.http.get<RespostaPadrao<Site>>(`${this.url}/${id}`);
  }

  deletar(id: string) {
    return this.http.delete(`${this.url}/${id}`);
  }

}
