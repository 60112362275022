<div class="add-lead__wrapper">
  <div class="add-lead__card">

    <div class="add-lead__titulo col">
      <b>Inscreva-se em nossa newsletter</b> e fique por dentro das novidades<br>
      Se cadastre no <b>Happy Mais</b> e garanta muitos benefícios
    </div>
  
    <!-- <div class="add-lead__block-icons-top col-md-auto">
      <mat-icon class="add-lead__icon">add</mat-icon>
      <mat-icon class="add-lead__icon">add</mat-icon>
    </div> -->

    <!-- <div class="row align-items-end"> -->
      <!-- <div class="add-lead__block-icons-bottom col-md-auto">
        <mat-icon class="add-lead__icon">arrow_forward_ios</mat-icon>
        <mat-icon class="add-lead__icon">arrow_forward_ios</mat-icon>
        <mat-icon class="add-lead__icon">arrow_forward_ios</mat-icon>
        <mat-icon class="add-lead__icon">arrow_forward_ios</mat-icon>
        <mat-icon class="add-lead__icon">arrow_forward_ios</mat-icon>
      </div> -->
      <div class="add-lead__container">
        <div class="add-lead__form-container">
          <ng-container *ngIf="loadingComponentSubmitted; else loaded">
            <div class="add-lead__loading">
              <img src="assets/icons/loading.svg">
            </div>
          </ng-container>
          <ng-template #loaded>
            <div class="add-lead__form-content">
              <input placeholder="Nome" name="nome" type="text" (keyup.enter)="salvar()" [(ngModel)]="nome" autocomplete="off">
            </div>
            <div class="add-lead__form-content">
              <input placeholder="Sobrenome" name="sobrenome" type="text" (keyup.enter)="salvar()" [(ngModel)]="sobrenome" autocomplete="off">
            </div>
    
            <div class="add-lead__form-content">
              <input placeholder="Email" name="email" type="text" (keyup.enter)="salvar()" [(ngModel)]="email" autocomplete="off">
            </div>
    
            <div class="add-lead__form-content">
              <input placeholder="Celular" name="numero" type="text" (keyup.enter)="salvar()" [(ngModel)]="numero" mask="(00) 00000-0000" autocomplete="off">
            </div>
    
            <div class="add-lead__form-content">
              <input placeholder="CPF" name="cpf" type="text" (keyup.enter)="salvar()" [(ngModel)]="cpf" mask="000.000.000-00" autocomplete="off">
            </div>
    
            <div class="add-lead__checkbox">
              <form [formGroup]="dadosForm">
                <mat-checkbox formControlName="AceiteOptin">Aceito saber sobre novidades e receber informações via e-mail, SMS ou Whatsapp</mat-checkbox><br>
                <mat-checkbox formControlName="AceiteOptinProgram">Aceito fazer e receber benefícios exclusivos do Programa de Fidelidade Happy Mais</mat-checkbox><br>
                <small>*Para maiores informações consulte o regulamento do Programa 
                  <a href="https://crm.gruporihappy.com.br/cashback/happymais/assets/files/regulamento_fidelidade_20220403.pdf" target="_blank" rel="noopener">aqui</a></small>
              </form>
            </div>
    
            <div class="add-lead__acoes">
              <button class="add-lead__acoes-save" type="button" mat-button (click)="salvar()">
                Quero me cadastrar!
                <mat-icon style="font-weight: bolder;">check</mat-icon>
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    <!-- </div> -->
  </div>
</div>