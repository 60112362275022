import { Component, OnInit, Input } from "@angular/core";
import { IDadosOptout } from "src/app/shared/interfaces/dados-optout.interface";
import { DescadastreSeService } from "../descadastre-se/services/descadastre-se.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-rihappy-optout",
  templateUrl: "./rihappy-optout.component.html",
  styleUrls: ["./rihappy-optout.component.scss"],
})
export class RihappyOptoutComponent implements OnInit {
  @Input() dadosOptout: IDadosOptout = {
    Empresa: 0,
    Email: "",
    MotivoOptout: "",
    MotivoOutros: "",
  };

  motivoSelecionado: string = "";
  isLoading = false;
  isSuccess = false;
  outrosValue = "";
  isOther = false;

  constructor(
    private dadosOutputService: DescadastreSeService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {}

  onRadioChange(event: any) {
    const value: string = event.target.defaultValue;
    this.dadosOptout.MotivoOptout = value;
    this.outrosValue = "";
    this.isOther = value === "Outros";
  }

  onInputChange() {
    this.isOther = true;
    this.dadosOptout.MotivoOutros = this.outrosValue;
  }

  onSubmit() {
    this.isLoading = true;
    this.dadosOutputService.salvar(this.dadosOptout).subscribe(
      (response) => {
        this.isSuccess = true;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.toastService.error(
          "Desculpe, encontramos um problema ao tentar descadastrá-lo."
        );
      }
    );
  }
}
