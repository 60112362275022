<div class="container">

  <div class="page-title"> ADICIONAR COMENTÁRIO </div>

  <form [formGroup]="form">
    <mat-grid-list cols="1" rowHeight="380px">
      <mat-grid-tile [colspan]="1" [rowspan]=1>
        <mat-form-field appearance="fill" class="ancho">
          <mat-label>Comentário</mat-label>
          <textarea matInput formControlName="Comentario" #Comentario [(ngModel)]="comentario.Comentario" maxlength="500" cdkTextareaAutosize cdkAutosizeMinRows="20"
            cdkAutosizeMaxRows="20"></textarea>
          <mat-hint align="end">{{Comentario.value.length}} / 500</mat-hint>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </form>

  <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload multiple>

<!--   <button color="primary" class="add-comentario__selecionartudo" (click)="fileUpload.click()">
    Anexar arquivos
  </button> -->
  <br>

  <div *ngFor="let file of files">
    <p> {{ file.name }} </p>
  </div>

  <mat-grid-list cols="1" rowHeight="70px">
    <mat-grid-tile [colspan]="1" [rowspan]=1>
      <div class="add-comentario__acoes">
        <button class="add-comentario__acoes-cancel" type="button" mat-button mat-dialog-close style="margin-right: 20px;">
          Cancelar
        </button>
        <button class="add-comentario__acoes-save" type="button" mat-button (click)="salvar()">
          Salvar
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

</div>