<div class="barra-lateral">
  <div class="barra-lateral__container">
    <!-- usuario -->
    <div class="barra-lateral__usuario">
      <div class="barra-lateral__usuario-avatar">
        <img src="assets/images/dummy-user.png" alt="Avatar do Usuário">
      </div>
      <div class="barra-lateral__usuario-dados">
        <span class="barra-lateral__usuario-nome">
          {{ username }},
        </span>
        <span class="barra-lateral__usuario-welcome">
          Bem vindo!
        </span>
      </div>
    </div>
    <!-- fim usuario -->

    <!-- menu -->
    <div class="barra-lateral__menu">
      <ng-container *ngFor="let itemMenu of menuColletion">
        <div class="barra-lateral__menu-item">
          <a [routerLink]="[itemMenu.rota]" routerLinkActive="barra-lateral__menu-item__ativo">
            <img [src]="itemMenu.icone" alt="Icone Menu">
            {{ itemMenu.descricao }}
          </a>
        </div>
      </ng-container>
    </div>
    <!-- fim menu -->
  </div>
</div>