import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AceitePolitica } from 'src/app/shared/interfaces/aceite-politica.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AceitePoliticaService {
  private path = `${environment.apiCadastro.lgpd}/politica`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  dadosPolitica(atributoId: string) {
    return this.http.get<AceitePolitica>(`${this.url}/atributo/${atributoId}`);
  }

  aceitarPolitica(atributoId: string, clienteId: string) {
    return this.http.post(`${this.url}/${atributoId}/${clienteId}`, {});
  }

}
