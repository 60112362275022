import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";

import { TipoSolicitacaoService } from "src/app/lgpd/services/tipo-solicitacao.service";
import { SolicitacaoRequest as SolicitacaoRequest } from "src/app/shared/interfaces/solicitacao-request.interface";
import { TipoSolicitacao } from "src/app/shared/interfaces/tipo-solicitacao.interface";
import { LoadingService } from "src/app/shared/services/loading.service";
import { PortalPrivacidadeService } from "../../services/portal-privacidade.service";

@Component({
  selector: "app-portal-privacidade-minhas-informacoes",
  templateUrl: "./portal-privacidade-minhas-informacoes.component.html",
  styleUrls: ["./portal-privacidade-minhas-informacoes.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortalPrivacidadeMinhasInformacoesComponent implements OnInit {

  id?: string;
  loading = false;

  informacoesList: TipoSolicitacao[] = [];
  files: File[] = [];

  constructor(
    private portalPrivacidadeService: PortalPrivacidadeService,
    private tipoSolicitacaoService: TipoSolicitacaoService,
    private loadingService: LoadingService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.loadingService.loading$.subscribe((loadingStatus) => {
      this.loading = loadingStatus;
    });

    this.id = this.route.snapshot.paramMap.get('id') as string;

    this.loadTiposSolicitacao();
  }

  loadTiposSolicitacao() {
    this.loading = true;
    this.loadingService.setLoading(true);
    this.tipoSolicitacaoService.listarTodos().subscribe(
      (res) => {
        this.informacoesList = res.Data;

        this.informacoesList.forEach(f => f.Selected = false);

        this.loading = false;
        this.loadingService.setLoading(false);
        this.cdRef.detectChanges();
      },
      (err) => {
        console.error(err);
        this.loading = false;
        this.loadingService.setLoading(false);
      });
  }

  selecionarTodos() {
    const hasNoSelected = this.informacoesList.filter(f => !f.Selected);

    if (hasNoSelected.length > 0)
      this.informacoesList.forEach(f => f.Selected = true);
    else
      this.informacoesList.forEach(f => f.Selected = false);
  }

  cancelar() {
    this.router.navigateByUrl(`/portal-privacidade/${this.id}`);
  }

  pedirRelatorio() {
    const informacoesSelecionadasIds = this.informacoesList.filter(f => f.Selected).map(m => m.Id);

    if (informacoesSelecionadasIds.length == 0) {
      this.snackBar.open("Necessário selecionar ao menos uma informação.", "", { duration: 4000 });
      return;
    }

    const dadosPessoaisSelected = this.informacoesList.find(f => f.Chave == 'Dados pessoais')?.Selected;

    if (dadosPessoaisSelected == true && this.files.length == 0) {
      this.snackBar.open("Necessário anexar sua foto e seu documento.", "", { duration: 4000 });
      return;
    }

    this.loadingService.setLoading(true);

    const formData = new FormData();

    for (let file of this.files) {
      formData.append(file.name, file);
    }

    var solicitacao = {
      ClienteId: this.id,
      TiposSolicitacaoId: informacoesSelecionadasIds,
    } as SolicitacaoRequest;

    this.portalPrivacidadeService.pedirRelatorio(solicitacao).subscribe(
      (res: any) => {
        console.log(res);

        if (dadosPessoaisSelected)
          this.portalPrivacidadeService.upload(formData, res.Id).subscribe(
            (res: any) => {
              this.loadingService.setLoading(false);
            },
            (err: HttpErrorResponse) => {
              this.snackBar.open(err.error.Message, "", { duration: 4000 });
              this.loadingService.setLoading(false);
            });

        this.loadingService.setLoading(false);
        this.snackBar.open(res.Message, "", { duration: 6000 });
        this.router.navigateByUrl(`/portal-privacidade/${this.id}`);
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open(err.error.Message, "", { duration: 4000 });
        this.loadingService.setLoading(false);
      });
  }

  onFileSelected(event: any) {
    this.files = event.target.files as File[];
    this.cdRef.detectChanges();
  }
}
