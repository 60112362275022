import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { SistemaOrigemService } from 'src/app/adm/services/sistema-origem.service';
import { SiteService } from 'src/app/lgpd/services/site.service';
import { SistemaOrigem } from 'src/app/shared/interfaces/sistema-origem.interface';
import { Site } from 'src/app/shared/interfaces/site.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss']
})
export class AddSiteComponent implements OnInit {

  site!: Site;
  sistemas!: SistemaOrigem[];

  dadosForm!: FormGroup;

  loading = false;

  constructor(
    private siteService: SiteService,
    private sistemaOrigemService: SistemaOrigemService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    this.loading = true;
    this.site = {} as Site;
    const id = this.route.snapshot.paramMap.get('id');

    this.createForms();
    this.listarSistemasOrigem();

    if (id != null) {
      this.siteService.dadosSite(id).subscribe(
        (res) => {
          this.site = res.Data;

          this.dadosForm.controls['SistemaOrigem'].setValue(this.site.SistemaOrigem.Nome);

          this.loadingService.setLoading(false);
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loadingService.setLoading(false);
          this.loading = false;

          this.toastService.error("Erro ao listar as informações do site selecionado.");
        });
    }
    this.loading = false;
    this.loadingService.setLoading(false);
  }

  createForms() {
    this.dadosForm = new FormGroup({
      Url: new FormControl("", [Validators.required]),
      SistemaOrigem: new FormControl("", [Validators.required]),
    });
  }

  listarSistemasOrigem() {
    this.sistemaOrigemService.listarTodos().subscribe(
      (res) => {
        this.sistemas = res.Data;
      },
      (err) => console.error(err)
    );
  }

  salvar() {
    this.loadingService.setLoading(true);
    const dataForm = this.dadosForm.getRawValue();

    let site = this.dadosForm.getRawValue() as Site;

    site.Id = this.site.Id != null ? this.site.Id : undefined;
    site.SistemaOrigem = this.sistemas.find(f => f.Nome == dataForm.SistemaOrigem) as SistemaOrigem;
    site.SistemaOrigemId = site.SistemaOrigem.Id as string;

    this.siteService.salvar(site).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        this.router.navigateByUrl('/lgpd/sites');
        let message = site.Id != undefined ? "atualizado" : "cadastrado";
        this.toastService.success(`Site ${message} com sucesso!`);
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);

        let message = site.Id != undefined ? "atualizar" : "cadastrar";
        this.toastService.success(`Erro ao ${message} o site selecionado.`);
      });
  }
}
