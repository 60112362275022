import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { LogCookiesService } from "src/app/lgpd/services/log-cookies.service";
import { LogsCookie } from "src/app/shared/interfaces/cookies-logs.interface";
import { LoadingService } from "src/app/shared/services/loading.service";
@Component({
  selector: "app-logs-cookies-list",
  templateUrl: "./logs-cookies-list.component.html",
  styleUrls: ["./logs-cookies-list.component.scss"],
})
export class LogsCookiesListComponent implements OnInit {
  displayedColumns: string[] = ["data", "download"];
  loading = false;

  form = this.fb.group({
    dataInicial: [null, [Validators.required, Validators.minLength(6)]],
    dataFinal: [null, [Validators.required, Validators.minLength(6)]],
  });

  dataSource: MatTableDataSource<LogsCookie> =
    new MatTableDataSource<LogsCookie>([]);

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private logCookiesService: LogCookiesService
  ) {}

  ngOnInit() {}

  filterLogs(): void {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.loadingService.setLoading(true);

    this.logCookiesService
      .listarTodos(this.form.value.dataInicial, this.form.value.dataFinal)
      .subscribe(
        (res) => {
          this.loading = false;
          this.loadingService.setLoading(false);
          this.dataSource = new MatTableDataSource<LogsCookie>(res);
          this.form.reset();
        },
        () => {
          this.loading = false;
          this.loadingService.setLoading(false);
        }
      );
  }

  downloadCsv(date: string): void {
    this.loading = true;
    this.loadingService.setLoading(true);

    this.logCookiesService.downloadLog(date).subscribe(
      (res) => {
        this.loading = false;
        this.loadingService.setLoading(false);
        
        const linkSource = `data:application/octet-stream;base64,${res.Planilha}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = `log-cookies-${date}.csv`;
        downloadLink.click();
      },
      () => {
        this.loading = false;
        this.loadingService.setLoading(false);
      }
    );
  }
}
