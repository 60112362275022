<div class="container">

  <div class="page-title"> GRUPO DE COOKIES > MEUS COOKIES </div>

  <mat-tab-group animationDuration="0ms">

    <mat-tab label="Dados do Cookie">
      <mat-card style="margin-top: 10px" class="mat-elevation-z8" *ngIf="!loading">
        <form [formGroup]="dadosForm">

          <mat-grid-list cols="3" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Nome</mat-label>
                <input formControlName="Nome" #Nome matInput [(ngModel)]="cookie.Nome" maxlength="50">
                <mat-hint align="end">{{Nome.value.length}} / 50</mat-hint>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Site</mat-label>
                <mat-select formControlName="Site">
                  <mat-option *ngFor="let site of sites" [value]="site.Url">
                    {{ site.Url }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Grupo Cookie</mat-label>
                <mat-select formControlName="GrupoCookie">
                  <mat-option *ngFor="let grupoCookie of gruposCookie" [value]="grupoCookie.Descricao">
                    {{ grupoCookie.Descricao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <br>

          <mat-grid-list cols="3" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Finalidade</mat-label>
                <input formControlName="Finalidade" #Finalidade matInput [(ngModel)]="cookie.Finalidade" maxlength="50">
                <mat-hint align="end">{{Finalidade.value.length}} / 50</mat-hint>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <section>
                <mat-checkbox formControlName="Status" [(ngModel)]="cookie.Status">Ativo</mat-checkbox>
              </section>
            </mat-grid-tile>
          </mat-grid-list>

        </form>
      </mat-card>
    </mat-tab>
  </mat-tab-group>

  <mat-grid-list cols="1" rowHeight="70px">
    <mat-grid-tile [colspan]="1" [rowspan]=1>
      <div class="add-cookie__acoes">
        <button class="add-cookie__acoes-cancel" type="button" mat-button style="margin-right: 20px;" routerLink="/lgpd/cookies">
          Cancelar
        </button>
        <button class="add-cookie__acoes-save" type="button" mat-button (click)="salvar()">
          Salvar
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

</div>