<div class="container">
  <div class="page-title">
    Dados do Cliente
  </div>

  <mat-card style="margin-top: 10px" class="mat-elevation-z8" *ngIf="!loading">
    <form [formGroup]="addClienteForm">
      <h3 class="add-cliente__titulo-sessao">Dados Pessoais</h3>
      <mat-grid-list cols="4" rowHeight="70px">
        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Nome</mat-label>
            <input formControlName="Nome" matInput [(ngModel)]="cliente.Nome">
            <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Sobrenome</mat-label>
            <input formControlName="Sobrenome" matInput [(ngModel)]="cliente.SobreNome">
            <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Data de Nascimento</mat-label>
            <input formControlName="DataNascimento" matInput placeholder="dd/mm/aaaa" mask="d0/M0/0000" [dropSpecialCharacters]="false" [(ngModel)]="cliente.DataNascimentoFormatado">
            <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Tipo Cliente</mat-label>
            <mat-select formControlName="TipoCliente">
              <mat-option *ngFor="let tipoCliente of tiposClienteList" [value]="tipoCliente">
                {{ tipoCliente }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <br>

      <mat-grid-list cols="4" rowHeight="70px">
        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Documento</mat-label>
            <input formControlName="Documento" type="text" matInput mask="CPF_CNPJ" [(ngModel)]="cliente.Documento">
            <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Tipo Documento</mat-label>
            <mat-select #choosedValue (valueChange)="onChangeTipoCliente(choosedValue.value)" formControlName="TipoDocumento">
              <mat-option *ngFor="let tipoDocumento of tiposDocumentoList" [value]="tipoDocumento">
                {{ tipoDocumento }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Gênero</mat-label>
            <mat-select formControlName="Genero">
              <mat-option *ngFor="let genero of generosList" [value]="genero">
                {{ genero }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Sistema Origem</mat-label>
            <mat-select formControlName="SistemaOrigem" [disabled]="!!cliente?.Id">
              <mat-option *ngFor="let sistemaOrigem of sistemasOrigem" [value]="sistemaOrigem.Nome">
                {{ sistemaOrigem.Nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <br>

      <mat-grid-list cols="4" rowHeight="70px">
        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Bandeira(s)</mat-label>
            <mat-select formControlName="Bandeira" [disabled]="!!cliente?.Id">
              <mat-option *ngFor="let empresa of empresas" [value]="empresa.Nome">
                {{ empresa.Nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <br>

        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Número da loja</mat-label>
            <input formControlName="NumeroLoja" matInput [(ngModel)]="cliente.NumeroLoja">
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]=1 *ngIf="cliente?.Id">
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Data de Registro</mat-label>
            <input formControlName="DataRegistro" matInput readonly [(ngModel)]="cliente.DataCriacaoFormatado">
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]=1 *ngIf="cliente?.Id">
          <mat-form-field appearance="fill" class="ancho">
            <mat-label>Data de atualização</mat-label>
            <input formControlName="DataAtualizacao" matInput readonly [(ngModel)]="cliente.DataAtualizacaoFormatado">
            <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <br>

      <mat-grid-list cols="1" rowHeight="70px">
        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <div class="add-cliente__adicionar-elemento" (click)="addDocumento()">
            Adicionar documento(Internacional)
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <br>
      <ng-container *rhsupercUtilsIsAllowed="'Admin'">

      
  <mat-grid-list cols="3" rowHeight="70px" *ngIf="retornaAtributoLength() > 0">
    <h3 class="add-cliente__titulo-sessao">Atributos Comunicação</h3>
   <ng-container formArrayName="Atributos" *ngFor="let atributoForm of atributosForm.controls; let i = index">
        <ng-container [formGroupName]="i">
            <mat-grid-tile [colspan]="1" [rowspan]=1 layout-align="left"> 
              <mat-slide-toggle formControlName="Valor" [(checked)]="atributoForm.value.Valor"></mat-slide-toggle>
                <mat-label style="width: 100%; margin-left: 12px;">{{atributoForm.value.Nome}}</mat-label>
            </mat-grid-tile>
        </ng-container>
      </ng-container>
  </mat-grid-list> 
</ng-container>
      <br>

      <h3 class="add-cliente__titulo-sessao">Endereço</h3>

      <ng-container formArrayName="Enderecos" *ngFor="let enderecoForm of enderecosForm.controls; let i = index">
        <ng-container [formGroupName]="i">

          <ng-container>
            <mat-divider></mat-divider>
            <!-- <br> -->
            <div class="add-cliente__remover-elemento">
              <button type="button" mat-icon-button (click)="removeEnderecoForm(i)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </ng-container>

          <mat-grid-list cols="3" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>CEP</mat-label>
                <input matInput formControlName="Cep" placeholder="00000-000" type="text" mask="00000-000" (blur)="onCepBlur(i)">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Rua</mat-label>
                <input matInput formControlName="Rua"  [readonly]="true"
                [ngClass]="{'readonly': true}" >
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Número</mat-label>
                <input matInput formControlName="Numero">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <br>

          <mat-grid-list cols="3" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Bairro</mat-label>
                <input matInput formControlName="Bairro"  [readonly]="true"
                [ngClass]="{'readonly': true}" >
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho" >
                <mat-label>Cidade</mat-label>
                <input matInput formControlName="Cidade" [readonly]="true"
                [ngClass]="{'readonly': true}">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho"  >
                <mat-label>Estado</mat-label>
                <input matInput formControlName="Estado" [readonly]="true"
                [ngClass]="{'readonly': true}" >
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <mat-grid-list cols="3" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Complemento</mat-label>
                <input matInput formControlName="Complemento">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Tipo Endereço</mat-label>
                <mat-select formControlName="TipoEndereco">
                  <mat-option *ngFor="let tipoEndereco of tiposEndereco" [value]="tipoEndereco.Tipo">
                    {{ tipoEndereco.Tipo }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1 *ngIf="!!cliente?.Id">
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Sistema Origem</mat-label>
                <mat-select formControlName="SistemaOrigemId">
                  <mat-option *ngFor="let sistemaOrigem of sistemasOrigem" [value]="sistemaOrigem.Id">
                    {{ sistemaOrigem.Nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

          </mat-grid-list>

        </ng-container>
      </ng-container>
      <br>

      <mat-grid-list cols="1" rowHeight="70px">
        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <div class="add-cliente__adicionar-elemento" (click)="addEnderecoForm()">Outro(s) endereço(s)</div>
        </mat-grid-tile>
      </mat-grid-list>
      <br>

      <h3 class="add-cliente__titulo-sessao">Email</h3>

      <ng-container formArrayName="Emails" *ngFor="let emailForm of emailsForm.controls; let i = index">
        <ng-container [formGroupName]="i">

          <ng-container>
            <mat-divider></mat-divider>
            <!-- <br> -->
            <div class="add-cliente__remover-elemento">
              <button type="button" mat-icon-button (click)="removeEmailForm(i)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </ng-container>

          <mat-grid-list cols="3" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Email</mat-label>
                <input matInput formControlName="Endereco">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="Tipo">
                  <mat-option *ngFor="let tipoEmail of tiposEmail" [value]="tipoEmail.Tipo">
                    {{ tipoEmail.Tipo }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1 *ngIf="!!cliente?.Id">
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Sistema Origem</mat-label>
                <mat-select formControlName="SistemaOrigemId">
                  <mat-option *ngFor="let sistemaOrigem of sistemasOrigem" [value]="sistemaOrigem.Id">
                    {{ sistemaOrigem.Nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

        </ng-container>
      </ng-container>

      <br>

      <mat-grid-list cols="1" rowHeight="70px">
        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <div class="add-cliente__adicionar-elemento" (click)="addEmailForm()">Outro(s) email(s)</div>
        </mat-grid-tile>
      </mat-grid-list>

      <br>

      <h3 class="add-cliente__titulo-sessao">Telefone</h3>

      <ng-container formArrayName="Telefones" *ngFor="let telefoneForm of telefonesForm.controls; let i = index">
        <ng-container [formGroupName]="i">

          <ng-container>
            <mat-divider></mat-divider>
            <!-- <br> -->
            <div class="add-cliente__remover-elemento">
              <button type="button" mat-icon-button (click)="removeTelefoneForm(i)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </ng-container>

          <mat-grid-list cols="5" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>DDI</mat-label>
                <input matInput type="text" formControlName="DDI" mask="00">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>DDD</mat-label>
                <input matInput type="text" formControlName="DDD" mask="00">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Número</mat-label>
                <input matInput type="text" formControlName="Numero" mask="00000-0000||0000-0000">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="Tipo">
                  <mat-option *ngFor="let tipoTelefone of tiposTelefone" [value]="tipoTelefone.Tipo">
                    {{ tipoTelefone.Tipo }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1 *ngIf="!!cliente?.Id">
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Sistema Origem</mat-label>
                <mat-select formControlName="SistemaOrigemId">
                  <mat-option *ngFor="let sistemaOrigem of sistemasOrigem" [value]="sistemaOrigem.Id">
                    {{ sistemaOrigem.Nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

        </ng-container>
      </ng-container>

      <br>

      <mat-grid-list cols="1" rowHeight="70px">
        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <div class="add-cliente__adicionar-elemento" (click)="addTelefoneForm()">Outro(s) telefone(s)</div>
        </mat-grid-tile>
      </mat-grid-list>

      <br>

      <h3 class="add-cliente__titulo-sessao">Criança</h3>

      <ng-container formArrayName="Criancas" *ngFor="let criancaForm of criancasForm.controls; let i = index">
        <ng-container [formGroupName]="i">

          <ng-container>
            <mat-divider></mat-divider>
            <!-- <br> -->
            <div class="add-cliente__remover-elemento">
              <button type="button" mat-icon-button (click)="removeCriancaForm(i)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </ng-container>

          <mat-grid-list cols="4" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Nome</mat-label>
                <input matInput formControlName="Nome">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Mês Nascimento</mat-label>
                <input matInput formControlName="MesNascimento" maxlength="2" type="number">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Ano Nascimento</mat-label>
                <input matInput formControlName="AnoNascimento" maxlength="4" type="number">
                <!-- <mat-icon matPrefix style="color: green;">check</mat-icon> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1 *ngIf="!!cliente?.Id">
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Sistema Origem</mat-label>
                <mat-select formControlName="SistemaOrigemId">
                  <mat-option *ngFor="let sistemaOrigem of sistemasOrigem" [value]="sistemaOrigem.Id">
                    {{ sistemaOrigem.Nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

        </ng-container>
      </ng-container>

      <br>

      <mat-grid-list cols="1" rowHeight="70px">
        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <div class="add-cliente__adicionar-elemento" (click)="addCriancaForm()">Outra(s) criança(s)</div>
        </mat-grid-tile>
      </mat-grid-list>

      <br>

      <mat-grid-list cols="1" rowHeight="70px">
        <mat-grid-tile [colspan]="1" [rowspan]=1>
          <div class="add-cliente__acoes">
            <button class="add-cliente__acoes-cancel" type="button" mat-button style="margin-right: 20px;" routerLink="/adm/clientes">
              Cancelar
            </button>
            <button class="add-cliente__acoes-save" type="button" mat-button (click)="salvar()">
              Salvar
            </button>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

    </form>
  </mat-card>
</div>