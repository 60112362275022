import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { PortalPrivacidadeDetalhesComponent } from "./components/portal-privacidade-detalhes/portal-privacidade-detalhes.component";
import { PortalPrivacidadeMinhasInformacoesComponent } from "./components/portal-privacidade-minhas-informacoes/portal-privacidade-minhas-informacoes.component";
import { PortalPrivacidadeComponent } from "./portal-privacidade.component";

const routes: Routes = [
  {
    path: "",
    component: PortalPrivacidadeComponent,
    children: [
      // { path: "", redirectTo: "portal-privacidade", pathMatch: "full" },
      { path: ":id", component: PortalPrivacidadeDetalhesComponent },
      { path: ":id/relatorio", component: PortalPrivacidadeMinhasInformacoesComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PortalPrivacidadeRoutingModule { }
