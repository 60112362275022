import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-portal-privacidade-minhas-informacoes",
  templateUrl: "./portal-privacidade-minhas-informacoes.component.html",
  styleUrls: ["./portal-privacidade-minhas-informacoes.component.scss"],
})
export class PortalPrivacidadeMinhasInformacoesComponent implements OnInit {
  relatorioPrivacidade = new FormGroup({
    dadosPessoais: new FormControl(false),
    dadosConta: new FormControl(false),
    cookiesAceitos: new FormControl(false),
    listaConsentimentos: new FormControl(false),
  });

  constructor(private router: Router) {}

  ngOnInit() {}

  public cancelar() {
    this.router.navigateByUrl("/lgpd/portal");
  }

  public pedirRelatorio() {
    console.log("pedirRelatorio");
    console.log(this.relatorioPrivacidade.getRawValue());
  }
}
