<div class="container">

  <div class="page-title">LOGS DE EXCLUSÃO</div>

  <br>
    <mat-card>
      <div class="logs__header">
        <form class="logs__pesquisa" [formGroup]="form">
          <mat-form-field appearance="fill">
            <mat-label>Mês/Ano</mat-label>
            <input formControlName="data" matInput mask="M0/0000" [dropSpecialCharacters]="false" [showMaskTyped]="true" (keydown.enter)="loadLogs()">
          </mat-form-field>
          
          <mat-form-field appearance="fill">
            <mat-label>Documento</mat-label>
            <input formControlName="documento" matInput (keydown.enter)="loadLogs()">
          </mat-form-field>
        </form>
        <button mat-raised-button class="add-button" [disabled]="form.invalid" (click)="loadLogs()">
          <mat-icon>search</mat-icon>
          <span>Filtrar</span>
        </button>
      </div>
      
      <ng-container *ngIf="dataSource.data.length">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CLIENTE </th>
            <td mat-cell *matCellDef="let element"> {{ element.Nome }} {{ element.Sobrenome }} </td>
          </ng-container>
          
          <ng-container matColumnDef="documento">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DOCUMENTO </th>
            <td mat-cell *matCellDef="let element"> {{ element.Documento || '-' }} </td>
          </ng-container>

          <ng-container matColumnDef="sistemaOrigem">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ORIGEM EXCLUSÃO </th>
            <td mat-cell *matCellDef="let element"> {{ element.Origem || '-' }} </td>
          </ng-container>r>

          <ng-container matColumnDef="chamado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CHAMADO </th>
            <td mat-cell *matCellDef="let element"> {{ element.Chamado || '-' }} </td>
          </ng-container>

          <ng-container matColumnDef="dataAcao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DATA EXCLUSÃO </th>
            <td mat-cell *matCellDef="let element"> {{ element.DataExclusao | date:'dd/MM/yyy HH:mm' }} </td>
          </ng-container>
          
          <ng-container matColumnDef="detalhes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element" [style.width]="'40px'">
              <a [style.color]="'black'" (click)="showDetalhes(element)">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)"> </mat-paginator>

      </ng-container>

      <h2 *ngIf="!dataSource.data.length">Não há registro de logs</h2>

    </mat-card>
</div>
