import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GrupoCookie } from "src/app/shared/interfaces/grupo-cookie.interface";
import { RespostaPadrao } from "src/app/shared/requests/resposta-padrao.interface";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GrupoCookieService {
  private path = `${environment.apiCadastro.lgpd}/grupocookie`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  listarTodos() {
    const path = `${environment.apiCadastro.lgpd}/gruposcookie`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.get<RespostaPadrao<GrupoCookie[]>>(url);
  }

  salvar(dados: GrupoCookie) {
    if (dados.Id == null)
      return this.http.post<GrupoCookie>(this.url, dados);
    else
      return this.http.put<GrupoCookie>(this.url, dados);
  }

}