import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AceitePoliticaComponent } from "./aceite-politica.component";
import { AceitePoliticaConcluidoComponent } from "./components/aceite-politica-concluido/aceite-politica-concluido.component";
import { AceitePoliticaDetalhesComponent } from "./components/aceite-politica-detalhes/aceite-politica-detalhes.component";

const routes: Routes = [
  {
    path: "",
    component: AceitePoliticaComponent,
    children: [
      // { path: "", redirectTo: "portal-privacidade", pathMatch: "full" },
      { path: ":atributoId/:clienteId/:atualizacao", component: AceitePoliticaDetalhesComponent },
      { path: "concluido", component: AceitePoliticaConcluidoComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AceitePoliticaRoutingModule { }
