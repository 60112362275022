import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LogAcoesService } from "src/app/lgpd/services/log-acoes.service";
import { LogEdicao } from "src/app/shared/interfaces/log-acoes.interface";
import { LoadingService } from "src/app/shared/services/loading.service";
import { LogEdicaoDetalhesComponent } from "../components/log-edicao-detalhes/log-edicao-detalhes.component";

@Component({
  selector: "app-logs-edicao-list",
  templateUrl: "./logs-edicao-list.component.html",
  styleUrls: ["./logs-edicao-list.component.scss"],
})
export class LogsEdicaoListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "cliente",
    "documento",
    "status",
    "data",
    "detalhes",
  ];
  loading = false;

  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;

  form = this.fb.group({
    data: [null, [Validators.required, Validators.minLength(6)]],
    documento: [null],
  });

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private logExclusaoService: LogAcoesService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadLogs();
  }
  
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  loadLogs() {
    if (this.form.invalid) {
      return;
    }

    this.loadingService.setLoading(true);
    this.loading = true;

    const params = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      data: this.form.value.data,
      documento: this.form.value.documento || "",
    };

    this.logExclusaoService.listarTodos(params, "update").subscribe(
      (res) => {
        // console.log(res);
        this.dataSource = new MatTableDataSource<LogEdicao>(
          res.Data as LogEdicao[]
        );
        this.length = res.TotalRecords;
        this.dataSource.sort = this.sort;
        this.loadingService.setLoading(false);
        this.loading = false;
      },
      (err) => {
        this.loadingService.setLoading(false);
        this.loading = false;
      }
    );
  }

  showDetalhes(log: any) {
    this.dialog.open(LogEdicaoDetalhesComponent, {
      data: log,
      width: "65vw",
      maxHeight: "70vh",
      panelClass: 'custom-log-edicao-container'
    });
  }

  updatePagination(event: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadLogs();
  }
}
