import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { CadastroCrmService } from 'src/app/crm/services/cadastrocrm.service';
import { AuthTokenService } from "src/app/shared/services/auth-token.service";
import { SessionService } from "src/app/shared/services/session.service";
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-crm-login',
  templateUrl: './crm-login.component.html',
  styleUrls: ['./crm-login.component.scss']
})
export class CrmLoginComponent implements OnInit {
  code:string="";

  constructor(private route:ActivatedRoute, 
              private authService:AuthService,
              private cadastroCrmService:CadastroCrmService,
              private sessionService:SessionService,
              private authTokenService:AuthTokenService,
              private router: Router,
              private toastService:ToastrService,
              ) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params =>{
      this.code = params['code']
    });

    if(this.code == "" || !this.code){
      const url:string = `${environment.samlProviderUrl}?callback=${environment.samlCallback}`
      window.location.href = url;
    }else{
      this.authService.getToken(this.code)
      .subscribe(
        (res)=>{
          const decodedToken = this.authTokenService.decodeFromString(res.access_token);
          res.loja = decodedToken?.loja;
          if(res.loja ==="" || !res.loja){
            res.loja =  decodedToken?.local;
          }
          res.nome = decodedToken?.name;
          res.vendedor =decodedToken?.preferred_username.replace("lj.","");
          this.sessionService.setSession(res);
          this.cadastroCrmService.obterApiKey()
            .subscribe(
              (data)=>{
                let session = this.sessionService.getSession();
                session.apikey = data.ApiKey;
                session.empresa = data.Empresa;
                session.autGerente = data.AutGerente;
                session.franquia = data.Franquia;
                this.sessionService.setSession(session);
                this.router.navigateByUrl("/crm");
              },
              (err) => {
                alert("erro ao identicar empresa");
                this.sessionService.resetSession();
              }
            );
          
      },
      (err)=>{
        this.toastService.error(
          "Autenticação Invalida"
        );
        this.router.navigateByUrl("/crm-login").then(() => location.reload());
      });
    }

  }

}
