<header>
  <div class="logo">
    <img src="assets/icons/logo_pbkids.svg" alt="Logo PBkids" />
  </div>
</header>

<main>
  <div class="form">
    <div class="form__icon">
      <img src="assets/icons/pbkids_icon.png" alt="Solzinho PBkids" />
    </div>
    <div *ngIf="!isSuccess" class="form__content">
      <div class="form__text">
        <p class="form__title">Vai abandonar o nosso universo da imaginação?</p>
        <p class="form__subtitle">
          Por aqui temos novidades, lançamentos e muitas promoções para você
          aproveitar e embarcar em um mundo de sonhos junto com a criançada!
          <br />
        </p>
        <p class="form__info">
          Para que o Pebê e todos os monstrinhos possam saber o porque você quer
          parar de receber o nosso contato, nos conte, o que está acontecendo?
        </p>
      </div>

      <form>
        <div>
          <div class="form_group">
            <label for="opcao1">
              <input
                type="radio"
                id="opcao1"
                name="opcao"
                value="Recebo muitos e-mails por dia "
                (change)="onRadioChange($event)"
              />
              Recebo muitos e-mails por dia
            </label>
          </div>
          <div class="form_group">
            <label for="opcao2">
              <input
                type="radio"
                id="opcao2"
                name="opcao"
                value="Não tenho interesse no conteúdo "
                (change)="onRadioChange($event)"
              />
              Não tenho interesse no conteúdo
            </label>
          </div>
          <div class="form_group">
            <label for="opcao3">
              <input
                type="radio"
                id="opcao3"
                name="opcao"
                value="Não tenho tempo para ver as mensagens "
                (change)="onRadioChange($event)"
              />
              Não tenho tempo para ver as mensagens
            </label>
          </div>
          <div class="form_group">
            <label for="opcao4">
              <input
                type="radio"
                id="opcao4"
                name="opcao"
                value="Não lembro de ter me cadastrado"
                (change)="onRadioChange($event)"
              />
              Não lembro de ter me cadastrado
            </label>
          </div>
          <div class="form_group">
            <label for="opcao5">
              <input
                type="radio"
                id="opcao5"
                name="opcao"
                value="Outros"
                (change)="onRadioChange($event)"
              />
              Outros
            </label>
          </div>
          <div class="form_group">
            <label for="outros">
              <input
                *ngIf="isOther"
                class="input-text"
                type="text"
                id="outros"
                name="opcao"
                placeholder="Digite aqui o motivo"
                required
                [(ngModel)]="outrosValue"
                (input)="onInputChange()"
              />
            </label>
          </div>
          <button
            class="btn_submit"
            type="submit"
            (click)="onSubmit()"
            [disabled]="
              (!outrosValue && !dadosOptout.MotivoOptout) ||
              (dadosOptout.MotivoOptout == '' && isLoading) || isOther && dadosOptout.MotivoOutros == ''
            "
          >
            <span *ngIf="!isLoading">Confirmar</span>
            <img *ngIf="isLoading" src="assets/icons/loading.svg" />
          </button>
        </div>
      </form>
    </div>

    <div class="form__success">
      <p *ngIf="isSuccess" class="message-success">
        Obrigado! <br />
        a sua inscrição foi cancelada
      </p>
    </div>
  </div>
</main>
