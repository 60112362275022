<div class="portal-detalhes">

  <div class="portal-detalhes__item">
    <div class="portal-detalhes__item-wrapper">
      <div class="portal-detalhes__item-corpo">
        <div class="portal-detalhes__item-titulo">
          Permitir o uso das suas informações para receber recomendações personalizadas
        </div>
        <div class="portal-detalhes__item-descricao">
          Pode ser criado um perfil sobre si e os seus interesses para lhe mostrar anúncios personalizados que são
          relevantes para si.
        </div>
      </div>
      <div class="portal-detalhes__item-opcao">
        <mat-slide-toggle>
        </mat-slide-toggle>
      </div>
    </div>

    <!-- <mat-divider></mat-divider> -->

    <div class="portal-detalhes__rodape">

    </div>
  </div>

  <!--  -->

  <div class="portal-detalhes__item">
    <div class="portal-detalhes__item-wrapper">
      <div class="portal-detalhes__item-corpo">
        <div class="portal-detalhes__item-titulo">
          Administrar preferências de publicidade
        </div>
        <div class="portal-detalhes__item-descricao">
          Pode ser criado um perfil sobre si e os seus interesses para lhe mostrar anúncios personalizados que são
          relevantes para si.
        </div>
      </div>
      <div class="portal-detalhes__item-opcao">

      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="portal-detalhes__rodape" (click)="administrarPreferencias()">
      Administrar Preferências
    </div>
  </div>

  <!--  -->

  <div class="portal-detalhes__item">
    <div class="portal-detalhes__item-wrapper">
      <div class="portal-detalhes__item-corpo">
        <div class="portal-detalhes__item-titulo">
          Baixar suas informações
        </div>
        <div class="portal-detalhes__item-descricao">
          Pode ser criado um perfil sobre si e os seus interesses para lhe mostrar anúncios personalizados que são
          relevantes para si.
        </div>
      </div>
      <div class="portal-detalhes__item-opcao">

      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="portal-detalhes__rodape" (click)="pedirRelatorio()">
      Pedir relatório
    </div>
  </div>

  <!--  -->

  <div class="portal-detalhes__item">
    <div class="portal-detalhes__item-wrapper">
      <div class="portal-detalhes__item-corpo">
        <div class="portal-detalhes__item-titulo">
          Excluir seus dados
        </div>
        <div class="portal-detalhes__item-descricao">
          Pode ser criado um perfil sobre si e os seus interesses para lhe mostrar anúncios personalizados que são
          relevantes para si.
        </div>
      </div>
      <div class="portal-detalhes__item-opcao">

      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="portal-detalhes__rodape" (click)="cancelarConta()">
      Cancelar conta
    </div>
  </div>

</div>