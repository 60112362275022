export function isAllowed(rolesToCheck: string | string[], roles: string[]): boolean {
  return typeof rolesToCheck === 'string' ? roles.some( r => r === rolesToCheck) : roles.some( r => rolesToCheck.includes(r))
}

export function isNotAllowed(rolesToCheck: string | string[], roles: string[]): boolean {
  return !isAllowed(rolesToCheck, roles);
}

export class NotAllowedError extends Error {
  constructor(message: string) {
    super(message);
  }
}
