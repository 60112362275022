import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CepCorreios } from "src/app/shared/interfaces/endereco.interface";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CepService {
  private path = `${environment.apiCadastro.register}`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  
  buscarEnderecoPorCep(cep: string, apiKey: string) {

    return this.http.get<CepCorreios>(`${this.url}/BuscarEnderecoPorCep/${cep}`);
  }


}