import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClienteInformacoesExclusao } from './../../shared/interfaces/cliente.interface';

import { EClienteStatus } from 'src/app/shared/enums/cliente-status.enum';
import { ClienteList } from 'src/app/shared/interfaces/cliente-list';
import { Cliente } from 'src/app/shared/interfaces/cliente.interface';
import { Crianca } from 'src/app/shared/interfaces/crianca.interface';
import { Atributo } from 'src/app/shared/interfaces/atributo.interface';
import { Email } from 'src/app/shared/interfaces/email.interface';
import { CepCorreios, Endereco } from 'src/app/shared/interfaces/endereco.interface';
import { Telefone } from 'src/app/shared/interfaces/telefone.interface';
import { ParamsDefault } from 'src/app/shared/requests/params-padrao.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  private path = `${environment.apiCadastro.register}/Cliente`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  private pathConsulta = `${environment.apiCadastro.base}${environment.apiCadastro.consulta}`;

  constructor(
    private http: HttpClient,
    // private sessionService: SessionService
  ) { }

  listarTodos(filter: ParamsDefault): Promise<RespostaPadrao<ClienteList[]>> {
    let params = new HttpParams()
      .set('pageNumber', String(filter.pageNumber))
      .set('pageSize', String(filter.pageSize));

    if (filter.searchString != null && filter.searchString.length > 0) {
      params = params.set('searchString', String(filter.searchString));
    }

    if (filter.sistemasOrigemIds != null && filter.sistemasOrigemIds.length > 0) {
      params = params.set('sistemasOrigemIds', JSON.stringify(filter.sistemasOrigemIds));
    }

    if (filter.ativo != null) {
      params = params.set('ativo', String(filter.ativo));
    }

    // const session = this.sessionService.getSession();

    // const headers = new HttpHeaders().set(
    //   'Authorization',
    //   `${session.token_type} ${session.access_token}`
    // );

    return this.http.get<RespostaPadrao<ClienteList[]>>(
      `${this.pathConsulta}/clientes`,
      // this.url,
      {
        params,
        // headers,
      }
    )
      .toPromise();
  }

  salvar(dados: Cliente, apiKey: string) {
    const headers = new HttpHeaders().set(
      'apiKey',
      apiKey
    );

    if (dados.Id == null)
      return this.http.post<Cliente>(this.url, dados, { headers: headers });
    else
      return this.http.put<Cliente>(this.url, dados, { headers: headers });
  }

  alterarStatus(id: string, status: EClienteStatus) {
    return this.http.put<any>(`${this.url}/${id}`, { status });
  }

  dadosCliente(id: string) {
    return this.http.get<RespostaPadrao<Cliente>>(`${this.pathConsulta}/buscar/cliente/${id}`);
    // return this.http.get<RespostaPadrao<Cliente>>(`${this.url}/${id}`);
  }

  alterarAtivo(id: string, status: boolean) {
    const path = `${environment.apiCadastro.register}`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.put<any>(`${url}/Ativar/${id}/${status}`, {});
  }

  remover(id: string, data: any) {
    return this.http.request('delete', `${this.url}/${id}`, { body: data });
  }

  alterarDadosPessoais(dados: Cliente, apiKey: string) {
    dados.Atributos = [];
    const headers = new HttpHeaders().set(
      'apiKey',
      apiKey
    );

    return this.http.put<Cliente>(`${this.url}/${dados.Id}/DadosPessoais`, dados, { headers: headers });
  }

  alterarEnderecos(dados: Endereco[], apiKey: string, clienteId: string) {
    const headers = new HttpHeaders().set(
      'apiKey',
      apiKey
    );

    return this.http.put<Endereco[]>(`${this.url}/${clienteId}/Enderecos`, dados, { headers: headers });
  }

  alterarEmails(dados: Email[], apiKey: string, clienteId: string) {
    const headers = new HttpHeaders().set(
      'apiKey',
      apiKey
    );

    return this.http.put<Email[]>(`${this.url}/${clienteId}/Emails`, dados, { headers: headers });
  }

  alterarTelefones(dados: Telefone[], apiKey: string, clienteId: string) {
    const headers = new HttpHeaders().set(
      'apiKey',
      apiKey
    );

    return this.http.put<Telefone[]>(`${this.url}/${clienteId}/Telefones`, dados, { headers: headers });
  }

  alterarCriancas(dados: Crianca[], apiKey: string, clienteId: string) {
    const headers = new HttpHeaders().set(
      'apiKey',
      apiKey
    );

    return this.http.put<Crianca[]>(`${this.url}/${clienteId}/Criancas`, dados, { headers: headers });
  }
  alterarAtributos(dados: Atributo[], apiKey: string, clienteId: string) {
    dados.forEach(a => a.Valor = a.Valor.toString().charAt(0).toUpperCase() + a.Valor.toString().slice(1));
    const headers = new HttpHeaders().set(
      'apiKey',
      apiKey
    );

    return this.http.put<Atributo[]>(`${this.url}/${clienteId}/Atributos`, dados, { headers: headers });
  }
}
