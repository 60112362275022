import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Atributo } from 'src/app/shared/interfaces/atributo.interface';

import { TipoSolicitacao } from 'src/app/shared/interfaces/tipo-solicitacao.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AtributoCampanhasService {
  private path = `${environment.apiCadastro.admin}/atributo-campanha`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }


  criar(dados: Atributo, empresa: string) {
      return this.http.post<boolean>(`${this.url}/${empresa}`, dados);
  }

}
