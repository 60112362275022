import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { GrupoCookieService } from 'src/app/lgpd/services/grupo-cookie.service';
import { GrupoCookie } from 'src/app/shared/interfaces/grupo-cookie.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-add-grupo-cookie',
  templateUrl: './add-grupo-cookie.component.html',
  styleUrls: ['./add-grupo-cookie.component.scss']
})
export class AddGrupoCookieComponent implements OnInit {

  grupoCookie!: GrupoCookie;

  dadosForm!: FormGroup;

  loading = false;

  constructor(
    private grupoCookieService: GrupoCookieService,
    private loadingService: LoadingService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    this.loading = true;
    this.grupoCookie = {} as GrupoCookie;

    this.createForms();

    this.loading = false;
    this.loadingService.setLoading(false);
  }

  createForms() {
    this.dadosForm = new FormGroup({
      Descricao: new FormControl("", [Validators.required]),
    });
  }

  salvar() {
    this.loadingService.setLoading(true);

    let cookie = this.dadosForm.getRawValue() as GrupoCookie;

    this.grupoCookieService.salvar(cookie).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        this.router.navigateByUrl('/lgpd/cookies');
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
      });
  }
}
