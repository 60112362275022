import { SessionService } from "src/app/shared/services/session.service";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const session = this.sessionService.getSession();

    if (session) {
      request = request.clone({
        setHeaders: {
          Authorization: `${session.token_type} ${session.access_token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
