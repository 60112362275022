<div class="container">

  <div class="page-title">LOGS DE CONSENTIMENTO</div>

  <br>

  <div>
    <mat-card>
      <div class="logs__header" fxLayout="row" fxLayoutAlign="space-between center">

        <div class="logs__pesquisa">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>
              filter_alt
            </mat-icon>
            Filtro
          </button>
          <mat-menu #menu="matMenu">
            <p class="logs__menu-titulo">
              Buscar por Sites:
            </p>
            <div *ngFor="let site of sites">
              <div class="logs__pesquisa-filtro">
                <mat-checkbox (change)=filtrarSite(site)>{{site.Url}}</mat-checkbox>
              </div>
            </div>
          </mat-menu>

          <div class="logs__pesquisa-container">
            <mat-icon>search</mat-icon>
            <input [ngModel]="search$ | async" (ngModelChange)="search$.next($event)" placeholder="Pesquisar por: Ação" type="text">
          </div>
        </div>

      </div>

      <ng-container *ngIf="logsList.length > 0">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="cookie">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> COOKIE </th>
            <td mat-cell *matCellDef="let element"> {{element.Cookie.Nome}} </td>
          </ng-container>

          <ng-container matColumnDef="site">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SITE </th>
            <td mat-cell *matCellDef="let element"> {{element.Site.Url}} </td>
          </ng-container>

          <ng-container matColumnDef="acao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AÇÃO </th>
            <td mat-cell *matCellDef="let element"> {{element.Acao}} </td>
          </ng-container>

          <ng-container matColumnDef="fingerPrint">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> IDENTIFICAÇÃO </th>
            <td mat-cell *matCellDef="let element"> {{element.FingerPrint }} </td>
          </ng-container>

          <ng-container matColumnDef="dataAtualizacao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DATA ATUALIZAÇÃO </th>
            <td mat-cell *matCellDef="let element"> {{element.DataAtualizacao | date:'dd/MM/yyy HH:mm'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)"> </mat-paginator>

      </ng-container>

      <h2 *ngIf="logsList.length == 0">Não há registro de logs</h2>

    </mat-card>
  </div>
</div>