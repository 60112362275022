import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SolicitacaoRequest as SolicitacaoRequest } from 'src/app/shared/interfaces/solicitacao-request.interface';
import { SolicitacaoDetalhes } from 'src/app/shared/interfaces/solicitacao-detalhes.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PortalPrivacidadeService {
  private path = `${environment.apiCadastro.lgpd}/solicitacao`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  cancelarConta(id: string) {
    const path = `${environment.apiCadastro.lgpd}/cliente`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.put(`${url}/${id}`, {});
  }

  pedirRelatorio(dados: SolicitacaoRequest) {
    return this.http.post(this.url, { ClienteId: dados.ClienteId, TiposSolicitacaoId: dados.TiposSolicitacaoId });
  }

  upload(files: any, solicitacaoId: string) {
    const path = `${environment.apiCadastro.lgpd}/solicitacao/${solicitacaoId}/upload`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.post(url, files);
  }

  permitirUso(id: string, valor: boolean) {
    const path = `${environment.apiCadastro.lgpd}/permitirUso/${id}`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.put(url, { Valor: valor });
  }

  loadSolicitacoes(clienteId: string) {
    const path = `${environment.apiCadastro.lgpd}/cliente/${clienteId}/solicitacao`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.get<RespostaPadrao<SolicitacaoDetalhes[]>>(url);
  }

}
