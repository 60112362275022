import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { validateBasis } from '@angular/flex-layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { finalize, ignoreElements } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Atributo } from '../shared/interfaces/atributo.interface';
import { AutorizacaoGerente } from '../shared/interfaces/autorizacao-gerente.interface';
import { ClienteCrm } from '../shared/interfaces/cliente-crm.interface';
import { Cliente } from '../shared/interfaces/cliente.interface';
import { SessionService } from '../shared/services/session.service';
import { ModalComponent } from './modal/modal.component';
import { CadastroCrmService } from './services/cadastrocrm.service';
import { ConsultaService } from './services/consulta.service';

@Component({
    selector: 'app-crm',
    templateUrl: './crm.component.html',
    styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit {
    public formGroup: FormGroup = new FormGroup({});
    public tokenForm: FormGroup = new FormGroup({});
    public autorizacaoForm: FormGroup = new FormGroup({});
    public loading: boolean = false;
    public tokenEnviado: boolean = false;    
    public clientePrograma:boolean = false;
    public autorizacao:boolean = false;
    public cadastroConfirmado:boolean=false;
    public show:boolean=false;
    public request:boolean=false;
    public vendedor?:string="";
    public loja?:string="";
    public empresa:string="";
    public nome?:string;
    public autGerente:boolean = false;
    public franquia:boolean = false;
    public textoTermo:string="";
    public mask:string ="000000";
    public novoCliente: boolean = true;
    public temVendedor: boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private consultaService: ConsultaService,
        private cadastroCrmService:CadastroCrmService,
        private sessionService:SessionService,
        public dialog:MatDialog,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.formGroup = this.getFormGroup();
        this.tokenForm = this.getTokenForm();
        this.autorizacaoForm = this.getautorizacaoForm();

        const session = this.sessionService.getSession();

        if(session.apikey=="" || !session.apikey){
            this.sessionService.resetSession();
            this.router.navigateByUrl("/crm-login").then(() => location.reload());
        }

        this.vendedor = session.vendedor;
        this.loja  = session.loja;
        this.empresa = session.empresa??"";
        this.nome = session.nome;
        this.autGerente = session.autGerente??false;
        this.franquia = session.franquia??false;

        if(this.franquia){
            this.mask="";
        }

        this.textoTermo="Aceito os Termos de Uso, Politica de Privacidade e Programa de Fidelidade";
        if(this.empresa.toLowerCase().indexOf("pbkids")>=0){
            this.textoTermo = this.textoTermo+"  e regulamento da promoção Fomfuras."
        }
    }
    private getautorizacaoForm(){
        const autorizacaoForm = this.formBuilder.group({
            gerente:[
                {value:null,disabled:false},
                Validators.compose([Validators.required])
            ],
            senha:[
                {value:null,disabled:false},
                Validators.compose([Validators.required])
            ],
            motivo:[
                {value:null,disabled:false},
                Validators.compose([Validators.required])
            ],
            politica:[
                {value:null,disabled:false},
                Validators.compose([Validators.requiredTrue])
            ],
            comunicacao:[
                {value:null,disabled:false}
                
            ]

        });

        return autorizacaoForm;
    }

    private getTokenForm(){
        const tokenForm = this.formBuilder.group({
            token:[
                {value:null,disabled:false},
                Validators.compose([Validators.required,Validators.minLength(6),Validators.maxLength(6)])
            ]
        });
        return tokenForm;
    }
    private getFormGroup() {
        const formGroup = this.formBuilder.group({
            nome: [
                { value: null, disabled: false },
                Validators.compose([Validators.required])
            ],
            sobrenome: [
                { value: null, disabled: false },
                Validators.compose([Validators.required])
            ],
            cpf: [
                { value: null, disabled: false },
                Validators.compose([Validators.required])
            ],
            telefone: [
                { value: null, disabled: false },
                Validators.compose([Validators.required])
            ],
            email: [
                { value: null, disabled: false },
                Validators.compose([Validators.email])
            ],
            vendedor:[
                { value: null, disabled: false },
                Validators.compose([Validators.required])                
            ]
        });

        return formGroup;
    }

    private verificaPrograma(atributos:Atributo[]):boolean{        
        const attrPrograma = atributos.filter((e)=> e.Atributo.Nome =="Programa Fidelidade");
        return attrPrograma.length > 0;
    }
    private verificaVendedor(atributos:Atributo[],empresa:string):boolean{        
        let atributoId = "";
        if(empresa == "pbkids"){
            atributoId = environment.atributos.pbkids;
        }
        else if (empresa == "rihappy"){
            atributoId = environment.atributos.rihappy;
        }
        
        const attrVendedor = atributos.filter((e)=> e.Atributo.Nome =="pf-vendedor" && e.AtributoId == atributoId);
        if(attrVendedor.length > 0 ){
            this.formGroup.controls.vendedor.setValue(attrVendedor[0].Valor);
        }
        return attrVendedor.length > 0;
    }


    private showModal(description:string,message:string){
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {description, message};

        const dialogRef = this.dialog.open(ModalComponent,
            dialogConfig);        
    }

    private clienteInfo(cliente:Cliente){
        let email:string ="";
        let telefone:string="";
        if(cliente.Emails && cliente.Emails.length>0){
            email = cliente.Emails[0].Endereco;
        }
        if(cliente.Telefones && cliente.Telefones.length>0){
            telefone = `${cliente.Telefones[0].DDD}${cliente.Telefones[0].Numero}`;
        }

        this.formGroup.controls.nome.setValue(cliente.Nome.toUpperCase());
        this.formGroup.controls.sobrenome.setValue(cliente.SobreNome.toUpperCase());
        this.formGroup.controls.email.setValue(email);
        this.formGroup.controls.telefone.setValue(telefone);
        this.novoCliente = false;
        this.clientePrograma= this.verificaPrograma(cliente.Atributos);

        if(this.clientePrograma){
            if(this.empresa.toLowerCase().indexOf("pbkids")>=0){
                this.showModal("Programa Fidelidade",`o cliente ${cliente.Documento} ja faz parte do programa PBClub.`);
                this.temVendedor = this.verificaVendedor(cliente.Atributos,"pbkids");
            }else{
                this.showModal("Programa Fidelidade",`o cliente ${cliente.Documento} ja faz parte do programa HappyMais.`);   
                this.temVendedor = this.verificaVendedor(cliente.Atributos,"rihappy");             
            }
        }
        
    }
    private exibirError(err:any){
        if(err.error && err.error.Message){
            let message = err.error.Message;
            if(err.error.Data && Array.isArray(err.error.Data)){
                err.error.Data.forEach((e: any) => {
                    message+=`\n ${e}`;
                });
            }
            this.showModal("Error!!",message);
        }
    }

    public pesquisaCliente(){
        const cpf = this.formGroup.controls.cpf.value;
        this.formGroup.disable();
        this.request=true;
        this.limpaCampos();
        this.consultaService.dadosCliente(cpf)      
        .pipe(
            finalize(()=> this.request = false )
        )
        .subscribe((data)=>{
            if(data.Data){
                this.clienteInfo(data.Data);
                this.novoCliente = false;
            }else{    
                this.limpaCampos();      
                this.novoCliente = true;     
            }
            this.formGroup.enable();
            this.show=true;
        },(err)=>{
            this.formGroup.enable();
            this.exibirError(err);
        });
    }

    public limpaCampos(){
        const cpf = this.formGroup.controls.cpf.value;
        this.tokenEnviado=false;
        this.clientePrograma=false;
        this.autorizacao=false;
        this.formGroup.reset();
        if(cpf){
            this.formGroup.controls.cpf.setValue(cpf);
        }


    }
    public enviarToken(atualizar:boolean) {
        const cpf = this.formGroup.controls.cpf.value;
        const telefone = `55${this.formGroup.controls.telefone.value}`;
        const vendedor = this.formGroup.controls.vendedor.value;
        const email = this.formGroup.controls.email.value;

        this.request=true;
        this.cadastroCrmService.enviarCodigo(cpf,telefone,vendedor,email,atualizar)
        .pipe(
            finalize(()=> this.request = false )
        )
        .subscribe((data)=>{
            this.showModal("Confirmacao",`um codigo foi enviado ao numero ${telefone}`)
            this.tokenEnviado=true;
            this.tokenForm.reset();
        },(err)=>{
            this.exibirError(err);
        });
    }
    
    public solicitarAutorizacao(flg:boolean){
        this.autorizacao=flg;
        if(flg){
            this.autorizacaoForm.reset();
        }
    }

    public confirmarCadastro(){
        const codigo =this.tokenForm.controls.token.value;
        this.confirmarCadastroCliente(codigo,true);
    }
    private confirmarCadastroCliente(codigo:string,optInComunicacao:boolean){
        let cliente:ClienteCrm  = {
            Documento : this.formGroup.controls.cpf.value,
            DDD : this.formGroup.controls.telefone.value.substring(0,2),
            Telefone :this.formGroup.controls.telefone.value.substring(2,11),
            Nome:this.formGroup.controls.nome.value,
            Sobrenome:this.formGroup.controls.sobrenome.value,
            Email:this.formGroup.controls.email.value,
            Codigo:codigo,
            OptInComunicacao:optInComunicacao == null ? false:optInComunicacao,
            Vendedor: this.formGroup.controls.vendedor.value
        };
        this.request=true;
        this.cadastroCrmService.confirmarCadastro(cliente,this.clientePrograma)
        .pipe(
            finalize(()=> this.request = false )
        )
        .subscribe((data)=>{
            this.limpaCampos();
            this.formGroup.controls.cpf.setValue("");
            if(!this.clientePrograma || !this.novoCliente){
                this.showModal("Cadastro","Atualização do cliente realizada");
            }
            else{
                this.showModal("Cadastro","Cadastro do cliente atualizado");
            }
        },(err)=>{
            this.exibirError(err);
        });
        

    }

    public obterCodigoGerente(){
        let autorizacao:AutorizacaoGerente = {
            Documento: this.formGroup.controls.cpf.value,
            Login : this.autorizacaoForm.controls.gerente.value,
            Senha : this.autorizacaoForm.controls.senha.value,
            Motivo : this.autorizacaoForm.controls.motivo.value,
            Codigo:""
        }
        this.request=true;
        this.cadastroCrmService.autorizacaoGerente(autorizacao)
        .pipe(
            finalize(()=> this.request = false )
        )
        .subscribe((data)=>{
           let codigoGerente = data.Codigo;           
           this.confirmarCadastroCliente(codigoGerente,this.autorizacaoForm.controls.comunicacao.value);
        },(err)=>{
            this.exibirError(err);
        })
    }
   
}
