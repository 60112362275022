<mat-toolbar class="barra-superior">
  <div class="barra-superior__container">
    <div class="barra-superior__titulo">
      RiHappy Super C
    </div>
    <div class="barra-superior__logo">
      <img src="assets/logo.svg" alt="Logo RiHappy">
    </div>
    <div class="barra-superior__logout" (click)="logout()">
      <img src="assets/icons/menu-icone-logout.svg" alt="Icone de Logout">
    </div>
  </div>
</mat-toolbar>