import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CookieList } from 'src/app/shared/interfaces/cookie-list.interface';
import { Cookie } from 'src/app/shared/interfaces/cookie.interface';
import { ParamsDefault } from 'src/app/shared/requests/params-padrao.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private path = `${environment.apiCadastro.lgpd}/cookie`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
    // private sessionService: SessionService
  ) { }

  listarTodos(filter: ParamsDefault): Promise<RespostaPadrao<CookieList[]>> {
    let params = new HttpParams()
      .set('pageNumber', String(filter.pageNumber))
      .set('pageSize', String(filter.pageSize));

    if (filter.searchString != null && filter.searchString.length > 0) {
      params = params.set('searchString', String(filter.searchString))
    }

    if (filter.gruposCookiesIds != null && filter.gruposCookiesIds.length > 0) {
      params = params.set('gruposCookiesIds', JSON.stringify(filter.gruposCookiesIds));
    }

    if (filter.sitesIds != null && filter.sitesIds.length > 0) {
      params = params.set('sitesIds', JSON.stringify(filter.sitesIds));
    }

    return this.http.get<RespostaPadrao<CookieList[]>>(
      this.url,
      {
        params,
        // headers,
      }
    )
      .toPromise();
  }

  salvar(dados: Cookie) {
    if (dados.Id == null)
      return this.http.post<Cookie>(this.url, dados);
    else
      return this.http.put<Cookie>(this.url, dados);
  }

  dadosCookie(id: string) {
    return this.http.get<RespostaPadrao<Cookie>>(`${this.url}/${id}`);
  }

  deletar(id: string) {
    return this.http.delete(`${this.url}/${id}`);
  }

}
