import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { LogExclusao } from "./../../../../../shared/interfaces/log-acoes.interface";

@Component({
  selector: "app-logs-exclusao",
  templateUrl: "./logs-exclusao.component.html",
  styleUrls: ["./logs-exclusao.component.scss"],
})
export class LogsExclusaoComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: LogExclusao) {}
}
