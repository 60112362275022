<div class="container">

  <div class="page-title">Sistemas Origem</div>

  <br>

  <div>

    <mat-card>
      <div class="sistemas__header" fxLayout="row" fxLayoutAlign="space-between center">

        <div class="sistemas__pesquisa">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>
              filter_alt
            </mat-icon>
            Filtro
          </button>
          <mat-menu #menu="matMenu">
            <p class="sistemas__menu-titulo">
              Sistemas
            </p>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="sistemasModel" (change)="loadSistemas()">
              <button mat-menu-item>
                <mat-radio-button color="primary" labelPosition="before" [value]="'Todos'">
                  Todos
                </mat-radio-button>
              </button>
              <button mat-menu-item>
                <mat-radio-button color="primary" labelPosition="before" [value]="'Ativo'">
                  Ativo
                </mat-radio-button>
              </button>
              <button mat-menu-item>
                <mat-radio-button color="primary" labelPosition="before" [value]="'Inativo'">
                  Inativo
                </mat-radio-button>
              </button>
            </mat-radio-group>

          </mat-menu>

          <div class="sistemas__pesquisa-container">
            <mat-icon>search</mat-icon>
            <input [ngModel]="search$ | async" (ngModelChange)="search$.next($event)" placeholder="Pesquisar por: Nome" type="text">
          </div>
        </div>

        <button mat-raised-button class="add-button" routerLink="/adm/sistemas-origem/add" routerLinkActive="active">ADICIONAR</button>

      </div>

      <ng-container *ngIf="sistemasList.length > 0">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NOME </th>
            <td mat-cell *matCellDef="let element"> {{element.Nome}} </td>
          </ng-container>

          <ng-container matColumnDef="ativo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ATIVO </th>
            <td mat-cell *matCellDef="let element"> {{ element.Ativo == true ? "Ativo" :"Inativo" }} </td>
          </ng-container>

          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right;">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/adm/sistemas-origem/edit/{{element.Id}}">
                  <span>Editar</span>
                </button>
                <mat-divider></mat-divider>
                <!-- <button mat-menu-item class="text-danger" (click)="remover(element.Id)">
                  Remover
                </button> -->
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)">
        </mat-paginator>

      </ng-container>
      <h2 *ngIf="sistemasList.length == 0">Não há registro de sistemas origem</h2>

    </mat-card>
  </div>

</div>