import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { SistemaOrigemService } from 'src/app/adm/services/sistema-origem.service';

import { SiteService } from 'src/app/lgpd/services/site.service';
import { SistemaOrigem } from 'src/app/shared/interfaces/sistema-origem.interface';
import { Site } from 'src/app/shared/interfaces/site.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent implements OnInit {

  name = "sites";
  loading = true;
  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  filtro: string = "";
  search$ = new BehaviorSubject('');
  searchString: string = "";
  sistemasOrigem: SistemaOrigem[] = [];
  sistemasIdsSelected?: string[] = [];

  buscaPorSistemaModel = "Padrão";

  sitesList: Site[] = [];
  displayedColumns: string[] = [
    "url",
    "sistemaOrigem",
    "ativo",
    "menu",
  ];

  dataSource: MatTableDataSource<Site> = new MatTableDataSource<Site>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private siteService: SiteService,
    private loadingService: LoadingService,
    private sistemaOrigemService: SistemaOrigemService,
    private toastService: ToastrService,
  ) {
    this.listenSearch();
  }

  ngOnInit(): void {
    this.loadSites();
    this.listarSistemasOrigem();
  }

  loadSites() {
    this.loadingService.setLoading(true);
    this.loading = true;

    const params = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      searchString: this.searchString,
      sistemasOrigemIds: this.sistemasIdsSelected
    }

    this.siteService
      .listarTodosPaginado(params)
      .then((listas) => {
        this.length = listas.TotalRecords;
        this.sitesList = listas.Data;

        this.dataSource = new MatTableDataSource(this.sitesList);
        this.loading = false;
        this.loadingService.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        this.loadingService.setLoading(false);
      });
  }

  updatePagination(event: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadSites();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  changePage(page: PageEvent) {
    this.pageIndex = page.pageIndex + 1;
    this.loadSites();
  }

  deletar(id: string) {
    this.loadingService.setLoading(true);
    this.siteService.deletar(id).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        this.toastService.success("Site deletado com sucesso!");
        this.loadSites();
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        this.toastService.error("Erro ao deletar o site selecionado.");
      });
  }

  listarSistemasOrigem() {
    this.loadingService.setLoading(true);
    this.sistemaOrigemService.listarTodos().subscribe(
      (res) => {
        this.sistemasOrigem = res.Data;
        this.loadingService.setLoading(false);
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
      });
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.searchString = res;
      } else {
        this.searchString = "";
      }
      this.loadSites();
    });
  }

  filtrarSistema(sistema: SistemaOrigem) {
    if (!this.sistemasIdsSelected?.includes(sistema.Id)) {
      this.sistemasIdsSelected?.push(sistema.Id);
    } else {
      const index = this.sistemasIdsSelected?.indexOf(sistema.Id, 0);

      this.sistemasIdsSelected?.splice(index, 1);
    }

    this.loadSites();
  }

}
