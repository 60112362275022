<div class="body">
  <div class="login">
    <mat-spinner class="spinner" *ngIf="loading"></mat-spinner>

    <form *ngIf="!loading" [formGroup]="form" (ngSubmit)="ingresar()">
      <img src="assets/logo-login.svg" />
      <h1 class="h3 mb-3 fw-normal">Bem-vindo ao Super C!</h1>
      <span class="sub-title">Efetue seu login abaixo para continuar</span>
      <mat-form-field class="ancho" appearance="outline">
        <input
          type="text"
          matInput
          autocomplete="off"
          formControlName="usuario"
          placeholder="Digite o seu email"
        />
      </mat-form-field>
      <mat-form-field class="ancho" appearance="outline">
        <input
          type="password"
          matInput
          autocomplete="off"
          formControlName="password"
          placeholder="Digite a sua senha"
        />
      </mat-form-field>
      <br />
      <button
        type="submit"
        [disabled]="form.invalid"
        class="ancho"
        mat-raised-button
        mat-button
        color="primary"
      >
        Entrar
      </button>
    </form>
  </div>
</div>
