import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aceite-politica',
  templateUrl: './aceite-politica.component.html',
  styleUrls: ['./aceite-politica.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AceitePoliticaComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

}
