import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";

import { NgxMaskModule } from "ngx-mask";

import { CrmRoutingModule }  from './crm-routing.module'
import { CrmComponent } from "./crm.component"; 
import { CoreModule } from "src/app/core/core.module";
import { SharedModule } from "src/app/shared/shared.module";
import { InterceptorCrmModule } from "../shared/interceptors/interceptor-crm.module";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ModalComponent } from './modal/modal.component';
import { A11yModule } from '@angular/cdk/a11y'

@NgModule({
  imports: [
    CommonModule,
    CrmRoutingModule,
    SharedModule,
    CoreModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    FormsModule,
    InterceptorCrmModule,
    NgxMaskModule.forChild(),
    ClipboardModule,
    A11yModule
    
  ],
  exports: [],
  declarations: [
    CrmComponent,
    ModalComponent
    
    
  ],
  providers: [
    
  ],
})

export class CrmModule {}