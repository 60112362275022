import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { PoliticaService } from 'src/app/lgpd/services/politica.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { TipoPoliticaService } from 'src/app/lgpd/services/tipo-politica.service';
import { SiteService } from 'src/app/lgpd/services/site.service';

import { PoliticaList } from 'src/app/shared/interfaces/politica-list.interface';
import { TipoPolitica } from 'src/app/shared/interfaces/tipo-politica.interface';
import { Site } from 'src/app/shared/interfaces/site.interface';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gerenciar-politicas',
  templateUrl: './gerenciar-politicas.component.html',
  styleUrls: ['./gerenciar-politicas.component.scss']
})
export class GerenciarPoliticasComponent implements OnInit {
  name = "politicas";
  loading = true;
  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  filtro: string = "";

  buscaPorSistemaModel = "Padrão";
  politicasModel = "Todos";

  tiposPolitica: TipoPolitica[] = [];
  sites: Site[] = [];
  tiposIdsSelected?: string[] = [];
  sitesIdsSelected?: string[] = [];
  search$ = new BehaviorSubject('');
  searchString: string = "";

  listPoliticas: PoliticaList[] = [];
  displayedColumns: string[] = [
    "texto",
    "site",
    "versao",
    "acao",
    "tipo",
    "visibilidade",
    "menu",
  ];

  dataSource: MatTableDataSource<PoliticaList> = new MatTableDataSource<PoliticaList>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private politicaService: PoliticaService,
    private tipoPoliticaService: TipoPoliticaService,
    private siteService: SiteService,
    private loadingService: LoadingService,
    private toastService: ToastrService,
  ) {
    this.listenSearch();
  }

  ngOnInit(): void {
    this.loadPoliticas();
    this.listarTiposPolitica();
    this.listarSites();
  }

  loadPoliticas() {
    this.loadingService.setLoading(true);
    this.loading = true;

    const params = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      searchString: this.searchString,
      tiposPoliticaIds: this.tiposIdsSelected,
      sitesIds: this.sitesIdsSelected
    }

    this.politicaService
      .listarTodosPaginado(params)
      .then((listas) => {
        this.length = listas.TotalRecords;
        this.listPoliticas = listas.Data;
        this.dataSource = new MatTableDataSource(this.listPoliticas);
        this.loading = false;
        this.loadingService.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        this.loadingService.setLoading(false);
      });
  }

  updatePagination(event:PageEvent){
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadPoliticas();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  changePage(page: PageEvent) {
    this.pageIndex = page.pageIndex + 1;
    this.loadPoliticas();
  }

  deletar(id: string) {
    this.loadingService.setLoading(true);
    this.politicaService.deletar(id).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        this.toastService.success("Política deletada com sucesso!");
        this.loadPoliticas();
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        this.toastService.error("Erro ao deletar a política selecionada.");
      });
  }

  listarTiposPolitica() {
    this.tipoPoliticaService.listarTodos().subscribe(
      (res) => {
        this.tiposPolitica = res.Data;
        this.loadingService.setLoading(false);
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
      });
  }

  listarSites() {
    this.siteService.listarTodos().subscribe(
      (res) => {
        this.sites = res.Data;
        this.loadingService.setLoading(false);
      },
      (err) => {
        console.error(err);
        this.loadingService.setLoading(false);
      });
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.searchString = res;
      } else {
        this.searchString = "";
      }
      this.loadPoliticas();
    });
  }

  filtrarTiposPolitica(tipo: TipoPolitica) {
    if (!this.tiposIdsSelected?.includes(tipo.Id || "")) {
      this.tiposIdsSelected?.push(tipo.Id || "");
    } else {
      const index = this.tiposIdsSelected?.indexOf(tipo.Id || "", 0);

      this.tiposIdsSelected?.splice(index, 1);
    }

    this.loadPoliticas();
  }

  filtrarSite(site: Site) {
    if (!this.sitesIdsSelected?.includes(site.Id || "")) {
      this.sitesIdsSelected?.push(site.Id || "");
    } else {
      const index = this.sitesIdsSelected?.indexOf(site.Id || "", 0);

      this.sitesIdsSelected?.splice(index, 1);
    }

    this.loadPoliticas();
  }
}
