<form [formGroup]="form">
  <h3 class="title">Informações de exclusão</h3>

  <mat-form-field appearance="fill" class="ancho">
    <mat-label>Chamado</mat-label>
    <input formControlName="chamado" matInput />
  </mat-form-field>

  <mat-form-field appearance="fill" class="ancho">
    <mat-label>Descrição</mat-label>
    <textarea matInput formControlName="descricao" placeholder="Informe o motivo da exclusão." rows="3"></textarea>
  </mat-form-field>
  
  <div class="actions">
    <button type="button" mat-raised-button class="add-button" (click)="salvar()" [disabled]="form.invalid">Remover</button>
    <button type="button" mat-button class="botao__acoes-cancel" matDialogClose>Cancelar</button>    
  </div>
</form>
