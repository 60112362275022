import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AtributoCampanhasService } from 'src/app/adm/services/atributo-campanhas.service';
import { Atributo } from 'src/app/shared/interfaces/atributo.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-add-atributo-campanhas',
  templateUrl: './add-atributo-campanhas.component.html',
  styleUrls: ['./add-atributo-campanhas.scss']
})
export class AtributoCampanhasComponent implements OnInit {
  atributo!: Atributo;
  dadoAtributoForm!: FormGroup;
  loading = false;

  empresaList = [
    "RiHappy",
    "PBKids",
  ];

  constructor(
    private atributoCampanhasService: AtributoCampanhasService,
    private loadingService: LoadingService,
    private router: Router,
    private toastService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadingService.setLoading(true);

    this.createForms();

    this.loadingService.setLoading(false);
  }

  createForms() {
    this.dadoAtributoForm = new FormGroup({
      Nome: new FormControl("", [Validators.required]),
      Empresa: new FormControl("", [Validators.required]),
    });

  }


  salvar() {
    this.loadingService.setLoading(true);

    if (!this.dadoAtributoForm.valid) {
      this.loadingService.setLoading(false);
      this.dadoAtributoForm.markAllAsTouched();
      this.toastService.error(
        "Para prosseguir, preencha os campos obrigatórios!"
      );
      return;
    }
    
    const dataForm = this.dadoAtributoForm.getRawValue();

    let atributo = this.dadoAtributoForm.getRawValue() as Atributo;

    let empresa = dataForm.Empresa;
    this.atributoCampanhasService.criar(atributo, empresa).subscribe(
      (res) => {
        this.loadingService.setLoading(false);

        if (res) {
          this.toastService.success("Atributo cadastrado com sucesso!");
        } 

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
          this.toastService.error(err.error.Message, "Erro ao cadastrar atributo!");

      });
  }
}
