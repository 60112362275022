import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import * as fileSaver from 'file-saver';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { SolicitacaoService } from 'src/app/lgpd/services/solicitacao.service';
import { AddComentarioComponent } from 'src/app/shared/components/add-comentario/add-comentario.component';
import { formatBytes } from 'src/app/shared/helpers/format-bytes.decorator';
import { ArquivoSolicitacao } from 'src/app/shared/interfaces/arquivo-solicitacao.interface';
import { Comentario } from 'src/app/shared/interfaces/comentario.interface';
import { SolicitacaoDetalhes } from 'src/app/shared/interfaces/solicitacao-detalhes.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { RolesService } from 'src/app/shared/services/roles.service';

@Component({
  selector: 'app-solicitacao-detalhes',
  templateUrl: './solicitacao-detalhes.component.html',
  styleUrls: ['./solicitacao-detalhes.component.scss'],
})
export class SolicitacaoDetalhesComponent implements OnInit {

  solicitacao!: SolicitacaoDetalhes;
  comentarios: Comentario[] = [];
  arquivos: ArquivoSolicitacao[] = [];

  dadosForm!: FormGroup;

  id: string = '';
  loadingAnexo = false;
  message: string = '';

  constructor(
    private solicitacaoService: SolicitacaoService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private toastService: ToastrService,
    private rolesService: RolesService,
  ) { }

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    this.loadingAnexo = true;
    this.solicitacao = {} as SolicitacaoDetalhes;
    this.id = this.route.snapshot.paramMap.get('id') || '';

    if (this.id != null) {
      this.solicitacaoService.dadosSolicitacao(this.id).subscribe(
        (res) => {
          this.solicitacao = res.Data;

          this.solicitacao.DataCriacaoFormatado = (moment(this.solicitacao.DataCriacao)).format('DD/MM/YYYY HH:mm:ss');
          this.dadosForm.controls['NomeCliente'].setValue(this.solicitacao.NomeCliente);
          this.dadosForm.controls['EmailCliente'].setValue(this.solicitacao.EmailCliente);

          this.loadingService.setLoading(false);
        },
        (err) => {
          this.toastService.error("Erro ao carregar os detalhes do pedido.");
          this.loadingService.setLoading(false);
        });
    }

    this.createForms();
    this.loadComentarios();
    this.loadArquivos();
  }

  createForms() {
    this.dadosForm = new FormGroup({
      Protocolo: new FormControl({ value: "", disabled: true }),
      NomeCliente: new FormControl({ value: "", disabled: true }),
      EmailCliente: new FormControl({ value: "", disabled: true }),
      Status: new FormControl({ value: "", disabled: true }),
      DataCriacao: new FormControl({ value: "", disabled: true }),
    });
  }

  loadArquivos() {
    this.loadingAnexo = true;

    this.solicitacaoService.loadArquivos(this.id).subscribe(
      (res) => {
        this.arquivos = res.Data;
        this.arquivos.forEach(f => {
          f.TamanhoFormatted = formatBytes(f.Tamanho);
        })

        this.loadingAnexo = false;
      },
      (err) => {
        this.toastService.error("Erro ao carregar os anexos.");
        this.loadingAnexo = false;
      });
  }

  loadComentarios() {
    this.loadingService.setLoading(true);

    this.solicitacaoService.loadComentarios(this.id).subscribe(
      (res) => {
        this.comentarios = res.Data;

        // TODO: melhorar essa rotina
        this.comentarios?.forEach(g => g.Files?.forEach(h => {
          h.TamanhoFormatted = formatBytes(h.Tamanho);
        }));

        this.loadingService.setLoading(false);
      },
      (err) => {
        this.toastService.error("Erro ao carregar os comentários");
        this.loadingService.setLoading(false);
      });
  }

  get username(): string {
    return this.rolesService.getUsername();
  }

  openAddComentarioDialog(comentarioId?: string) {
    const dialogRef = this.dialog.open(AddComentarioComponent, {
      data: {
        solicitacaoId: this.solicitacao.Id,
        comentarioId: comentarioId,
        nomeUsuario: this.username
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadComentarios();
    });
  }

  deletarComentario(comentarioId?: string) {
    this.loadingService.setLoading(true);

    this.solicitacaoService.deletarComentario(comentarioId as string).subscribe(
      (res) => {
        this.toastService.success("Comentário deletado com sucesso!");
        this.loadComentarios();
        this.loadingService.setLoading(false);
      },
      (err) => {
        this.toastService.error("Erro ao deletar o comentário.");
        this.loadingService.setLoading(false);
      });
  }

  download(arquivo: ArquivoSolicitacao) {
    this.loadingService.setLoading(true);
    this.solicitacaoService.download(this.id, arquivo.Id).subscribe((response: any) => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, arquivo.Nome);
      this.loadingService.setLoading(false);
      this.toastService.success("Download realizado com sucesso!");
    },
      (error: any) => {
        this.loadingService.setLoading(false);
        this.toastService.error("Erro ao realizar o download do anexo.");
      });
  }

  downloadFileComentario(arquivo: ArquivoSolicitacao, comentarioId: any) {
    this.loadingService.setLoading(true);
    this.solicitacaoService.downloadFileComenario(comentarioId, arquivo.Id).subscribe((response: any) => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, arquivo.Nome);
      this.loadingService.setLoading(false);
      this.toastService.success("Download realizado com sucesso!");
    },
      (error: any) => {
        this.loadingService.setLoading(false);
        this.toastService.error("Erro ao realizar o download do anexo.");
      });
  }

}
