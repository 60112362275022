import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ClientesComponent } from "src/app/adm/clientes/pages/list/list.component";
import { AtributoCampanhasComponent } from "src/app/adm/atributo-campanhas/pages/add-atributo/add-atributo-campanhas.component";
import { AdmComponent } from "./adm/adm.component";
import { AddClienteComponent } from "./clientes/components/add-cliente/add-cliente.component";
import { SistemasOrigemListComponent } from "./sistemas-origem/pages/sistemas-origem-list/sistemas-origem-list.component";
import { OnlyAdminGuard } from "../shared/guards/only-admin.guard";

const routes: Routes = [
  {
    path: "",
    component: AdmComponent,
    children: [
      { path: "", redirectTo: "clientes", pathMatch: "full" },
      { path: "clientes", component: ClientesComponent },
      { path: "atributo-campanhas", component: AtributoCampanhasComponent, canActivate: [OnlyAdminGuard] },
      { path: "clientes/add", component: AddClienteComponent, canActivate: [OnlyAdminGuard]  },
      { path: "clientes/edit/:id", component: AddClienteComponent },
      { path: "sistemas-origem", component: SistemasOrigemListComponent },
      // { path: "dados", component: AddClienteComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdmRoutingModule { }