import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TipoPolitica } from 'src/app/shared/interfaces/tipo-politica.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoPoliticaService {
  private path = `${environment.apiCadastro.lgpd}/tipoPolitica`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  listarTodos() {
    const path = `${environment.apiCadastro.lgpd}/tiposPolitica`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.get<RespostaPadrao<TipoPolitica[]>>(url);
  }

  listarTodosPaginado(pageNumber: number = 1, pageSize: number = 10, searchString: string = ""): Promise<RespostaPadrao<TipoPolitica[]>> {
    let params = new HttpParams()
      .set('pageNumber', String(pageNumber))
      .set('pageSize', String(pageSize));

    if (searchString != null && searchString.length > 0) {
      params = params.set('searchString', String(searchString))
    }

    return this.http.get<RespostaPadrao<TipoPolitica[]>>(
      this.url,
      {
        params,
        // headers,
      }
    )
      .toPromise();
  }

  salvar(dados: TipoPolitica) {
    if (dados.Id == null)
      return this.http.post<TipoPolitica>(this.url, dados);
    else
      return this.http.put<TipoPolitica>(this.url, dados);
  }

  dadosPolitica(id: string) {
    return this.http.get<RespostaPadrao<TipoPolitica>>(`${this.url}/${id}`);
  }

  deletar(id: string) {
    return this.http.delete(`${this.url}/${id}`);
  }

}
