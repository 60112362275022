import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-privacidade-comunicacoes',
  templateUrl: './portal-privacidade-comunicacoes.component.html',
  styleUrls: ['./portal-privacidade-comunicacoes.component.scss']
})
export class PortalPrivacidadeComunicacoesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
