import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-cliente-dialog',
  templateUrl: './remove-cliente-dialog.component.html',
  styleUrls: ['./remove-cliente-dialog.component.scss'],
})
export class RemoveClienteDialogComponent {
  form = this.fb.group({
    chamado: ["", Validators.required],
    descricao: [""],
    origem: ["RiHappy Super C"],
  });

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RemoveClienteDialogComponent>
  ) {}

  salvar(): void {
    this.dialogRef.close(this.form.value);
  }
}
