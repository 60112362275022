import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogOptOut } from 'src/app/shared/interfaces/log-optout.interface';

import { ParamsDefault } from 'src/app/shared/requests/params-padrao.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogOptOutService {
  private path = `${environment.apiCadastro.lgpd}/optout-comunicacao`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  listarTodos(filter: ParamsDefault): Promise<RespostaPadrao<LogOptOut[]>> {
    let params = new HttpParams()
      .set('pageNumber', String(filter.pageNumber))
      .set('pageSize', String(filter.pageSize));

    if (filter.searchString != null && filter.searchString.length > 0) {
      params = params.set('searchString', String(filter.searchString))
    }


    return this.http.get<RespostaPadrao<LogOptOut[]>>(
      this.url,
      {
        params,
      }
    )
      .toPromise();
  }

}