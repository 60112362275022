import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { LogOptinService } from 'src/app/lgpd/services/log-optin.service';
import { SistemaOrigemService } from 'src/app/adm/services/sistema-origem.service';
import { SiteService } from 'src/app/lgpd/services/site.service';
import { LoadingService } from 'src/app/shared/services/loading.service';

import { LogOptin } from 'src/app/shared/interfaces/log-optin.interface';
import { SistemaOrigem } from 'src/app/shared/interfaces/sistema-origem.interface';
import { Site } from 'src/app/shared/interfaces/site.interface';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';

@Component({
  selector: 'app-logs-optin-list',
  templateUrl: './logs-optin-list.component.html',
  styleUrls: ['./logs-optin-list.component.scss'],
})
export class LogsOptinListComponent implements OnInit {

  name = "logs";
  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  filtro: string = "";
  loading = true;
  loadingEmpresa = true;

  logsList: LogOptin[] = [];
  sistemasOrigem: SistemaOrigem[] = [];
  sites: Site[] = [];
  sistemasIdsSelected?: string[] = [];
  sitesIdsSelected?: string[] = [];
  search$ = new BehaviorSubject('');
  searchString: string = "";

  displayedColumns: string[] = [
    "cliente",
    "sistemaOrigem",
    "site",
    "optin",
    "acao",
    "dataAcao",
  ];
  valores = [
    {
      label: 'Não aceita',
      value: 1
    },
    {
      label: 'Aceita',
      value: 2
    },
    {
      label: 'Todos',
      value: 3
    },
  ];
  valorSelecionado = { label: 'Todos', value: 3 };

  dataSource: MatTableDataSource<LogOptin> = new MatTableDataSource<LogOptin>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private logOptin: LogOptinService,
    private sistemaOrigemService: SistemaOrigemService,
    private siteService: SiteService,
    private loadingService: LoadingService,
  ) {
    this.listenSearch()
  }

  ngOnInit(): void {
    this.loadLogs();
    this.listarSistemasOrigem();
    this.listarSites();
  }

  loadLogs() {
    this.loadingService.setLoading(true);
    this.loading = true;

    const params = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      searchString: this.searchString,
      sistemasOrigemIds: this.sistemasIdsSelected,
      sitesIds: this.sitesIdsSelected,
      aceiteGlobal: this.valorSelecionado.value
    }

    this.logOptin
      .listarTodos(params)
      .then((listas) => {
        this.length = listas.TotalRecords;
        this.logsList = listas.Data;

        this.dataSource = new MatTableDataSource(this.logsList);
        this.loading = false;
        this.loadingService.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        this.loadingService.setLoading(false);
      });
  }

  updatePagination(event: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadLogs();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  changePage(page: PageEvent) {
    this.pageIndex = page.pageIndex + 1;
    this.loadLogs();
  }

  listarSistemasOrigem() {
    this.loadingEmpresa = true;
    this.sistemaOrigemService.listarTodos().subscribe(
      (res) => {
        this.sistemasOrigem = res.Data;
        this.loadingEmpresa = false;
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        this.loadingEmpresa = false;
      });
  }

  listarSites() {
    this.siteService.listarTodos().subscribe(
      (res) => {
        this.sites = res.Data;
      },
      (err) => console.error(err)
    );
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.searchString = res;
      } else {
        this.searchString = "";
      }
      this.loadLogs();
    });
  }

  filtrarSistema(sistema: SistemaOrigem) {
    if (!this.sistemasIdsSelected?.includes(sistema.Id)) {
      this.sistemasIdsSelected?.push(sistema.Id);
    } else {
      const index = this.sistemasIdsSelected?.indexOf(sistema.Id, 0);

      this.sistemasIdsSelected?.splice(index, 1);
    }

    this.loadLogs();
  }

  filtrarSite(site: Site) {
    if (!this.sitesIdsSelected?.includes(site.Id || "")) {
      this.sitesIdsSelected?.push(site.Id || "");
    } else {
      const index = this.sitesIdsSelected?.indexOf(site.Id || "", 0);

      this.sitesIdsSelected?.splice(index, 1);
    }

    this.loadLogs();
  }

  filtrarValores(valor: { label: string, value: number}) {
    this.valorSelecionado = valor;
    this.loadLogs();
  }

}
