import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { RemoveClienteDialogComponent } from '../../components/remove-cliente-dialog/remove-cliente-dialog.component';
import { ClienteInformacoesExclusao } from './../../../../shared/interfaces/cliente.interface';

import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { debounceTime, skip } from "rxjs/operators";

import { ClienteService } from "src/app/adm/services/cliente.service";
import { SistemaOrigemService } from "src/app/adm/services/sistema-origem.service";
import { EClienteStatus } from "src/app/shared/enums/cliente-status.enum";
import { ClienteList } from "src/app/shared/interfaces/cliente-list";
import { SistemaOrigem } from "src/app/shared/interfaces/sistema-origem.interface";
import { LoadingService } from "../../../../shared/services/loading.service";

@Component({
  selector: "app-clientes",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ClientesComponent implements OnInit {
  name = "clientes";
  loading = true;
  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  search$ = new BehaviorSubject('');
  searchString: string = "";

  pageEvent: PageEvent = new PageEvent;

  buscaPorSistemaModel = "Padrão";
  clientesModel = "Todos";

  listClientes: ClienteList[] = [];
  sistemasOrigem: SistemaOrigem[] = [];
  sistemasIdsSelected?: string[] = [];
  statusSelected?: boolean;

  displayedColumns: string[] = [
    "nome",
    "email",
    "status",
    "bandeira",
    "sistemas",
    "atualizacao",
    "ativo",
    "menu",
  ];

  dataSource: MatTableDataSource<ClienteList> = new MatTableDataSource<ClienteList>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private clienteService: ClienteService,
    private sistemaOrigemService: SistemaOrigemService,
    private toastService: ToastrService,
    private loadingService: LoadingService,
    private dialog: MatDialog
  ) {
    this.listenSearch();
  }

  ngOnInit(): void {
    this.loadClientes();
    this.listarSistemasOrigem();
  }

  updatePagination(event: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadClientes();
  }

  loadClientes() {
    this.loadingService.setLoading(true);
    this.loading = true;

    if (this.clientesModel != "Todos") {
      this.statusSelected = this.clientesModel == "Ativo";
    } else {
      this.statusSelected = undefined;
    }

    const params = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      searchString: this.searchString,
      sistemasOrigemIds: this.sistemasIdsSelected,
      ativo: this.statusSelected
    }

    this.clienteService.listarTodos(params)
      .then((listas) => {
        this.length = listas.TotalRecords;
        this.listClientes = listas.Data;

        this.listClientes.forEach(f => {
          const sistemasConcat = f.ClienteOrigem != null ? f.ClienteOrigem.map(fi => fi.SistemaOrigem.Nome) : null;
          f.Sistemas = sistemasConcat?.join(', ') ?? "";
        });

        this.dataSource = new MatTableDataSource(this.listClientes);
        this.loading = false;
        this.loadingService.setLoading(false);
      })
      .catch((err) => {
        this.loadingService.setLoading(false);
      });
  }

  listarSistemasOrigem() {
    this.loading = true;
    this.sistemaOrigemService.listarTodos().subscribe(
      (res) => {
        this.sistemasOrigem = res.Data;
        this.loading = false;
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        this.loading = false;
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  changePage(page: PageEvent) {
    this.pageIndex = page.pageIndex + 1;
    this.pageSize = page.pageSize;
    this.loadClientes();
  }

  alterarStatus(id: string, value: EClienteStatus) {
    this.loading = true;
    this.loadingService.setLoading(true);

    this.clienteService.alterarStatus(id, value).subscribe(
      (res) => {
        this.loading = false;
        this.loadingService.setLoading(false);
        let message = value > 1 ? "bloqueado" : "ativado";
        this.toastService.success(`Cliente ${message} com sucesso!`);
        this.loadClientes();
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        let message = value > 1 ? "bloquear" : "ativar";
        this.toastService.error(`Falha ao ${message} o cliente selecionado.`);
      });
  }

  alterarAtivo(id: string, valor: boolean) {
    this.loading = true;
    this.loadingService.setLoading(true);

    this.clienteService.alterarAtivo(id, valor).subscribe(
      (res) => {
        this.loading = false;
        this.loadingService.setLoading(false);
        let message = valor ? "ativado" : "inativado";
        this.toastService.success(`Cliente ${message} com sucesso!`);
        this.loadClientes();
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        let message = valor ? "ativar" : "inativar";
        this.toastService.error(`Falha ao ${message} o cliente selecionado.`);
      });
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.searchString = res;
      } else {
        this.searchString = "";
      }
      this.loadClientes();
    });
  }

  filtrarSistema(sistema: SistemaOrigem) {
    if (!this.sistemasIdsSelected?.includes(sistema.Id)) {
      this.sistemasIdsSelected?.push(sistema.Id);
    } else {
      const index = this.sistemasIdsSelected?.indexOf(sistema.Id, 0);

      this.sistemasIdsSelected?.splice(index, 1);
    }

    this.loadClientes();
  }

  remover(id: string) {
    const dialogRef = this.dialog.open(RemoveClienteDialogComponent);

    dialogRef.afterClosed().subscribe((data: ClienteInformacoesExclusao) => {
      if (data) {
        console.log(data)
        this.loading = true;
        this.loadingService.setLoading(true);

        this.clienteService.remover(id, data).subscribe(
          (res) => {
            this.loading = false;
            this.loadingService.setLoading(false);
            this.toastService.success(`Cliente removido com sucesso!`);
            this.searchString = "";
            this.search$.next("");
            this.loadClientes();
          },
          (err) => {
            this.loadingService.setLoading(false);
            this.toastService.error(`Falha ao remover o cliente selecionado.`);
          });
      }
    });
  }
}
