import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';

import { SistemaOrigemService } from 'src/app/adm/services/sistema-origem.service';
import { SistemaOrigem } from 'src/app/shared/interfaces/sistema-origem.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-sistemas-origem-list',
  templateUrl: './sistemas-origem-list.component.html',
  styleUrls: ['./sistemas-origem-list.component.scss']
})
export class SistemasOrigemListComponent implements OnInit {

  name = "sistemas";
  loading = true;
  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  search$ = new BehaviorSubject('');
  searchString: string = "";

  pageEvent: PageEvent = new PageEvent;
  datasource: MatTableDataSource<SistemaOrigem> = new MatTableDataSource<SistemaOrigem>([]);

  buscaPorSistemaModel = "Padrão";
  sistemasModel = "Todos";

  sistemasList: SistemaOrigem[] = [];
  statusSelected?: boolean;

  displayedColumns: string[] = [
    "nome",
    "ativo",
    "menu",
  ];

  dataSource: MatTableDataSource<SistemaOrigem> = new MatTableDataSource<SistemaOrigem>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private sistemaService: SistemaOrigemService,
    private toastService: ToastrService,
    private loadingService: LoadingService
  ) {
    this.listenSearch();
  }

  ngOnInit(): void {
    this.loadSistemas();
  }

  updatePagination(event: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadSistemas();
  }

  loadSistemas() {
    this.loadingService.setLoading(true);
    this.loading = true;

    if (this.sistemasModel != "Todos") {
      this.statusSelected = this.sistemasModel == "Ativo";
    } else {
      this.statusSelected = undefined;
    }

    const params = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      searchString: this.searchString,
      ativo: this.statusSelected
    }

    this.sistemaService.listarTodosPaginado(params)
      .then((listas) => {
        this.length = listas.TotalRecords;
        this.sistemasList = listas.Data;
        this.dataSource = new MatTableDataSource(this.sistemasList);
        this.loading = false;
        this.loadingService.setLoading(false);
      })
      .catch((err) => {
        this.loadingService.setLoading(false);
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  changePage(page: PageEvent) {
    this.pageIndex = page.pageIndex + 1;
    this.pageSize = page.pageSize;
    this.loadSistemas();
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.searchString = res;
      } else {
        this.searchString = "";
      }
      this.loadSistemas();
    });
  }

  // remover(id: string) {
  //   this.clienteService.remover(id).subscribe(
  //     (res) => {
  //       this.loading = false;
  //       this.loadingService.setLoading(false);
  //       this.toastService.success(`Cliente removido com sucesso!`);
  //       this.loadClientes();
  //     },
  //     (err) => {
  //       this.loadingService.setLoading(false);
  //       this.toastService.error(`Falha ao remover o cliente selecionado.`);
  //     });
  // }
}
