import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { DireitosTitularComponent } from "src/app/lgpd/direitos-titular/pages/list/direitos-titular.component";
import { GerenciarPoliticasComponent } from "src/app/lgpd/gerenciar-politicas/pages/list/gerenciar-politicas.component";
import { LgpdComponent } from "src/app/lgpd/lgpd.component";
import { PortalPrivacidadeComponent } from "src/app/lgpd/portal-privacidade/pages/list/portal-privacidade.component";
import { AddCookieComponent } from "./cookies/components/add-cookie/add-cookie.component";
import { CookiesListComponent } from "./cookies/pages/cookies-list/cookies-list.component";
import { SolicitacaoDetalhesComponent } from "./direitos-titular/components/solicitacao-detalhes/solicitacao-detalhes.component";
import { AddPoliticaComponent } from "./gerenciar-politicas/components/add-politica/add-politica.component";
import { AddGrupoCookieComponent } from "./group-cookies/components/add-grupo-cookie/add-grupo-cookie.component";
import { LogsConsentimentoListComponent } from "./logs-consentimento/pages/list/logs-consentimento-list/logs-consentimento-list.component";
import { LogsCookiesListComponent } from "./logs-cookies/pages/logs-cookies-list/logs-cookies-list.component";
import { LogsEdicaoListComponent } from "./logs-edicao/pages/logs-edicao-list/logs-edicao-list.component";
import { LogsExclusaoListComponent } from "./logs-exclusao/pages/logs-exclusao-list/logs-exclusao-list.component";
import { LogsOptinListComponent } from "./logs-optin/pages/list/logs-optin-list/logs-optin-list.component";
import { PortalPrivacidadeMinhasInformacoesComponent } from "./portal-privacidade/pages/portal-privacidade-minhas-informacoes/portal-privacidade-minhas-informacoes.component";
import { AddSiteComponent } from "./sites/components/add-site/add-site.component";
import { SiteListComponent } from "./sites/pages/list/site-list/site-list.component";
import { AddTipoPoliticaComponent } from "./tipos-politica/components/add-tipo-politica/add-tipo-politica.component";
import { TipoPoliticaListComponent } from "./tipos-politica/pages/list/tipo-politica-list/tipo-politica-list.component";
import { LogsOptoutListComponent } from "./logs-optout/pages/logs-optout-list.component"

const routes: Routes = [
  {
    path: "",
    component: LgpdComponent,
    children: [
      { path: "", redirectTo: "cookies", pathMatch: "full" },
      { path: "sites", component: SiteListComponent },
      { path: "sites/add", component: AddSiteComponent },
      { path: "sites/edit/:id", component: AddSiteComponent },
      { path: "cookies", component: CookiesListComponent },
      { path: "cookies/add", component: AddCookieComponent },
      { path: "cookies/edit/:id", component: AddCookieComponent },
      { path: "grupos-cookie/add", component: AddGrupoCookieComponent },
      { path: "logs-consentimento", component: LogsConsentimentoListComponent },
      { path: "tipos-politica", component: TipoPoliticaListComponent },
      { path: "tipos-politica/add", component: AddTipoPoliticaComponent },
      { path: "tipos-politica/edit/:id", component: AddTipoPoliticaComponent },
      { path: "politicas", component: GerenciarPoliticasComponent },
      { path: "politicas/add", component: AddPoliticaComponent },
      { path: "politicas/edit/:id", component: AddPoliticaComponent },
      { path: "logs-optin", component: LogsOptinListComponent },
      { path: "logs-optout", component: LogsOptoutListComponent },
      { path: "direitos-titular", component: DireitosTitularComponent },
      { path: "direitos-titular/:id", component: SolicitacaoDetalhesComponent },
      { path: "portal", component: PortalPrivacidadeComponent },
      { path: "suas-informacoes", component: PortalPrivacidadeMinhasInformacoesComponent },
      { path: "logs-exclusao", component: LogsExclusaoListComponent },
      { path: "logs-edicao", component: LogsEdicaoListComponent },
      { path: "logs-cookies", component: LogsCookiesListComponent }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LgpdRoutingModule { }
