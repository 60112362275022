import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";

import { SharedModule } from "src/app/shared/shared.module";
import { InterceptorModule } from "../shared/interceptors/interceptor.module";
import { BarraLateralComponent } from "./components/barra-lateral/barra-lateral.component";
import { BarraSuperiorComponent } from "./components/barra-superior/barra-superior.component";
import { MainLayoutComponent } from "./components/main-layout/main-layout.component";

@NgModule({
  declarations: [
    BarraLateralComponent,
    BarraSuperiorComponent,
    MainLayoutComponent,
  ],
  exports: [BarraLateralComponent, BarraSuperiorComponent, MainLayoutComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    RouterModule,
    SharedModule,
    InterceptorModule,
  ],
  providers: [],
})
export class CoreModule {}
