import { environment } from "src/environments/environment";
import { AuthTokenService } from "./auth-token.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  tokenDecoded;

  constructor(private authTokenService: AuthTokenService) {
    this.tokenDecoded = this.authTokenService.decodePayloadJWT();
  }

  public checkPermission(permission: string): boolean {
    const roles = this.tokenDecoded?.resource_access?.superc?.roles;

    if (roles) {
      return roles.includes(permission);
    }

    return false;
  }

  public getUsername(): string {
    return this.tokenDecoded!.name!;
  }
}
