import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import { ToastrService } from "ngx-toastr";
import * as fileSaver from "file-saver";

import { LoadingService } from "src/app/shared/services/loading.service";
import { SolicitacaoDetalhes } from "src/app/shared/interfaces/solicitacao-detalhes.interface";
import { ArquivoSolicitacao } from "src/app/shared/interfaces/arquivo-solicitacao.interface";
import { SolicitacaoService } from "src/app/lgpd/services/solicitacao.service";
import { formatBytes } from "src/app/shared/helpers/format-bytes.decorator";
import { AddComentarioComponent } from "src/app/shared/components/add-comentario/add-comentario.component";
import { PortalPrivacidadeService } from "../../services/portal-privacidade.service";

@Component({
  selector: "app-portal-privacidade-detalhes-shared",
  templateUrl: "./portal-privacidade-detalhes.component.html",
  styleUrls: ["./portal-privacidade-detalhes.component.scss"],
})
export class PortalPrivacidadeDetalhesComponent implements OnInit {

  id?: string;
  permiteUso = false;

  solicitacoes: SolicitacaoDetalhes[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private portalService: PortalPrivacidadeService,
    private solicitacaoService: SolicitacaoService,
    private loadingService: LoadingService,
    private toastService: ToastrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    this.loadSolicitacoes();
  }

  // administrarPreferencias() {
  //   console.log("administrarPreferencias");
  // }

  permitirUso() {
    this.permiteUso = !this.permiteUso;

    this.loadingService.setLoading(true);

    this.portalService.permitirUso(this.id as string, this.permiteUso).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        this.toastService.success("Permissão de uso das informações atualizado com sucesso!");
      },
      (err) => {
        this.toastService.error("Erro ao atualizar permissão.");
        this.loadingService.setLoading(false);
      });
  }

  pedirRelatorio() {
    this.router.navigateByUrl(`/portal-privacidade/${this.id}/relatorio`);
  }

  cancelarConta() {
    this.loadingService.setLoading(true);

    this.portalService.cancelarConta(this.id as string).subscribe(
      (res) => {
        this.toastService.success("Conta cancelada com sucesso.");
        this.loadingService.setLoading(false);
      },
      (err) => {
        this.toastService.error("Erro ao cancelar a conta.");
        this.loadingService.setLoading(false);
      });
  }

  loadSolicitacoes() {
    this.loadingService.setLoading(true);

    this.portalService.loadSolicitacoes(this.id as string).subscribe(
      (res) => {
        this.solicitacoes = res.Data;

        // TODO: melhorar essa rotina
        this.solicitacoes.forEach(f => f.Comentarios?.forEach(g => g.Files?.forEach(h => {
          h.TamanhoFormatted = formatBytes(h.Tamanho);
        })));

        this.loadingService.setLoading(false);
      },
      (err) => {
        this.toastService.error("Erro ao carregar as solicitações.");
        this.loadingService.setLoading(false);
      });
  }

  openAddComentarioDialog(solicitacao: SolicitacaoDetalhes, comentarioId?: string) {
    const dialogRef = this.dialog.open(AddComentarioComponent, {
      data: {
        solicitacaoId: solicitacao.Id,
        comentarioId: comentarioId,
        nomeUsuario: solicitacao.NomeCliente,
        enviarEmail: false,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadSolicitacoes();
    });
  }

  download(arquivo: ArquivoSolicitacao, comentarioId: any) {
    this.loadingService.setLoading(true);
    this.solicitacaoService.downloadFileComenario(comentarioId, arquivo.Id).subscribe((response: any) => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, arquivo.Nome);
      this.loadingService.setLoading(false);
      this.toastService.success("Download realizado com sucesso!");
    },
      (error: any) => {
        this.loadingService.setLoading(false);
        this.toastService.error("Erro ao realizar o download do anexo.");
      });
  }
}
