import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DescadastreSeComponent } from './descadastre-se.component';
import { RihappyOptoutComponent } from '../rihappy-optout/rihappy-optout.component';
import { PbkidsOptoutComponent } from '../pbkids-optout/pbkids-optout.component';

@NgModule({
  declarations: [
    DescadastreSeComponent,
    RihappyOptoutComponent,
    PbkidsOptoutComponent
  ],
  exports: [],
  imports: [
    CommonModule,
    FormsModule,
  ],
})

export class DescadastreSeModule {}