import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';

import { TipoPoliticaService } from 'src/app/lgpd/services/tipo-politica.service';
import { TipoPolitica } from 'src/app/shared/interfaces/tipo-politica.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-tipo-politica-list',
  templateUrl: './tipo-politica-list.component.html',
  styleUrls: ['./tipo-politica-list.component.scss']
})
export class TipoPoliticaListComponent implements OnInit {

  name = "tipos-politica";
  loading = true;
  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  search$ = new BehaviorSubject('');
  searchString: string = "";

  buscaPorSistemaModel = "Padrão";

  tiposPoliticaList: TipoPolitica[] = [];
  displayedColumns: string[] = [
    "descricao",
    "ativo",
    "menu",
  ];

  dataSource: MatTableDataSource<TipoPolitica> = new MatTableDataSource<TipoPolitica>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private tipoPoliticaService: TipoPoliticaService,
    private loadingService: LoadingService,
    private toastService: ToastrService,
  ) {
    this.listenSearch();
  }

  ngOnInit(): void {
    this.loadTiposPolitica();
  }

  loadTiposPolitica() {
    this.loadingService.setLoading(true);
    this.loading = true;
    this.tipoPoliticaService
      .listarTodosPaginado(this.pageIndex, this.pageSize, this.searchString)
      .then((listas) => {
        this.length = listas.TotalRecords;
        this.tiposPoliticaList = listas.Data;

        this.dataSource = new MatTableDataSource(this.tiposPoliticaList);
        this.loading = false;
        this.loadingService.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        this.loadingService.setLoading(false);
      });
  }

  updatePagination(event: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadTiposPolitica();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  changePage(page: PageEvent) {
    this.pageIndex = page.pageIndex + 1;
    this.loadTiposPolitica();
  }

  deletar(id: string) {
    this.loadingService.setLoading(true);
    this.tipoPoliticaService.deletar(id).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        this.loadTiposPolitica();
        this.toastService.success("Tipo de politica deletado com sucesso!");
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        this.toastService.error("Erro ao deletar o tipo de politica selecionado.");
      });
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.searchString = res;
      } else {
        this.searchString = "";
      }
      this.loadTiposPolitica();
    });
  }
}
