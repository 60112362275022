<div class="container">
  <p class="page-title">
    Portal da Privacidade
  </p>

  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Privacidade">
      <div class="portal-detalhes">

        <div class="portal-detalhes__item">
          <div class="portal-detalhes__item-wrapper">
            <div class="portal-detalhes__item-corpo">
              <div class="portal-detalhes__item-titulo">
                Permitir o uso das suas informações para receber recomendações personalizadas
              </div>
              <div class="portal-detalhes__item-descricao">
                Pode ser criado um perfil sobre si e os seus interesses para lhe mostrar anúncios personalizados que são
                relevantes para si.
              </div>
            </div>
            <div class="portal-detalhes__item-opcao">
              <mat-slide-toggle (click)="permitirUso()" (checked)="permiteUso"> </mat-slide-toggle>
            </div>
          </div>

          <!-- <mat-divider></mat-divider> -->

          <div class="portal-detalhes__rodape">

          </div>
        </div>

        <!--  -->

        <!-- <div class="portal-detalhes__item">
          <div class="portal-detalhes__item-wrapper">
            <div class="portal-detalhes__item-corpo">
              <div class="portal-detalhes__item-titulo">
                Administrar preferências de publicidade
              </div>
              <div class="portal-detalhes__item-descricao">
                Pode ser criado um perfil sobre si e os seus interesses para lhe mostrar anúncios personalizados que são
                relevantes para si.
              </div>
            </div>
            <div class="portal-detalhes__item-opcao">

            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="portal-detalhes__rodape" (click)="administrarPreferencias()">
            Administrar Preferências
          </div>
        </div> -->

        <!--  -->

        <div class="portal-detalhes__item">
          <div class="portal-detalhes__item-wrapper">
            <div class="portal-detalhes__item-corpo">
              <div class="portal-detalhes__item-titulo">
                Baixar suas informações
              </div>
              <div class="portal-detalhes__item-descricao">
                Pode ser criado um perfil sobre si e os seus interesses para lhe mostrar anúncios personalizados que são
                relevantes para si.
              </div>
            </div>
            <div class="portal-detalhes__item-opcao">

            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="portal-detalhes__rodape" (click)="pedirRelatorio()">
            Pedir relatório
          </div>
        </div>

        <!--  -->

        <div class="portal-detalhes__item">
          <div class="portal-detalhes__item-wrapper">
            <div class="portal-detalhes__item-corpo">
              <div class="portal-detalhes__item-titulo">
                Excluir seus dados
              </div>
              <div class="portal-detalhes__item-descricao">
                Pode ser criado um perfil sobre si e os seus interesses para lhe mostrar anúncios personalizados que são
                relevantes para si.
              </div>
            </div>
            <div class="portal-detalhes__item-opcao">

            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="portal-detalhes__rodape" (click)="cancelarConta()">
            Cancelar conta
          </div>
        </div>

      </div>
    </mat-tab>

    <mat-tab label="Solicitações">

      <div *ngFor="let solicitacao of solicitacoes">

        <div class="portal-detalhes__item">
          <div class="portal-detalhes__item-corpo">
            <div class="portal-detalhes__item-titulo" style="text-align: center;">
              Protocolo: {{ solicitacao.Protocolo }}
              <br>
              Status: {{ solicitacao.Status }}
            </div>

            <br>

            <div class="portal-detalhes__item-descricao">
              <div class="portal-detalhes__comentario">
                <b>Comentários</b>
                <button class="add-comentario" (click)="openAddComentarioDialog(solicitacao)">ADICIONAR COMENTÁRIO</button>
              </div>
            </div>

            <br>

            <div *ngFor="let comentario of solicitacao.Comentarios">

              <mat-divider></mat-divider>

              <div style="padding-top: 15px">
                <div fxFlex>
                  <p>{{ comentario.Comentario }}</p>
                </div>

                <div fxFlex *ngFor="let arquivo of comentario.Files" (click)="download(arquivo, comentario.Id)">
                  <div fxFlex="78" style="flex: 1 1 78%; box-sizing: border-box; max-width: 78%;" class="file">
                    <p>{{ arquivo.Nome }} - {{ arquivo.TamanhoFormatted }}</p>
                  </div>
                </div>

                <div fxFlex="15" style="flex: 1 1 15%; box-sizing: border-box; max-width: 15%;" style="font-size: 12px;">
                  <p>{{ comentario.NomeUsuario }} | {{ comentario.DataAtualizacao | date:'dd/MM/yyy HH:mm' }}</p>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </mat-tab>
  </mat-tab-group>

</div>