import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouterModule } from "@angular/router";
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgxMaskModule } from "ngx-mask";

import { SharedModule } from "src/app/shared/shared.module";
import { CoreModule } from "../../core/core.module";
import { LeadRoutingModule } from "./lead-routing.module";
import { LeadComponent } from "./lead.component";
import { AddLeadComponent } from './components/add-lead/add-lead.component';

@NgModule({
  declarations: [
    LeadComponent,
    AddLeadComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    LeadRoutingModule,
    SharedModule,
    CoreModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDividerModule,
    RouterModule,
    MatTooltipModule,
    NgxMaskModule.forChild(),
  ],
})

export class LeadModule { }
