import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { CookieService } from 'src/app/lgpd/services/cookie.service';
import { GrupoCookieService } from 'src/app/lgpd/services/grupo-cookie.service';
import { SiteService } from 'src/app/lgpd/services/site.service';
import { Cookie } from 'src/app/shared/interfaces/cookie.interface';
import { GrupoCookie } from 'src/app/shared/interfaces/grupo-cookie.interface';
import { Site } from 'src/app/shared/interfaces/site.interface';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-add-cookie',
  templateUrl: './add-cookie.component.html',
  styleUrls: ['./add-cookie.component.scss']
})
export class AddCookieComponent implements OnInit {

  cookie!: Cookie;
  sites!: Site[];
  gruposCookie!: GrupoCookie[];

  dadosForm!: FormGroup;

  loading = false;

  constructor(
    private cookieService: CookieService,
    private siteService: SiteService,
    private grupoCookieService: GrupoCookieService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    this.loading = true;
    this.cookie = {} as Cookie;
    const id = this.route.snapshot.paramMap.get('id');

    this.createForms();
    this.listarSites();
    this.listarGruposCookie();

    if (id != null) {
      this.cookieService.dadosCookie(id).subscribe(
        (res) => {
          this.cookie = res.Data;

          this.dadosForm.controls['Site'].setValue(this.cookie.Site.Url);
          this.dadosForm.controls['GrupoCookie'].setValue(this.cookie.GrupoCookie.Descricao);

          this.loadingService.setLoading(false);
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loadingService.setLoading(false);
          this.loading = false;
        });
    }
    this.loading = false;
    this.loadingService.setLoading(false);
  }

  createForms() {
    this.dadosForm = new FormGroup({
      Nome: new FormControl("", [Validators.required]),
      Site: new FormControl("", [Validators.required]),
      Status: new FormControl("", [Validators.required]),
      Finalidade: new FormControl("", [Validators.required]),
      GrupoCookie: new FormControl("", [Validators.required]),
    });
  }

  listarSites() {
    this.siteService.listarTodos().subscribe(
      (res) => {
        this.sites = res.Data;
      },
      (err) => console.error(err)
    );
  }

  listarGruposCookie() {
    this.grupoCookieService.listarTodos().subscribe(
      (res) => {
        this.gruposCookie = res.Data;
      },
      (err) => console.error(err)
    );
  }

  salvar() {
    this.loadingService.setLoading(true);
    const dataForm = this.dadosForm.getRawValue();

    let cookie = this.dadosForm.getRawValue() as Cookie;

    cookie.Id = this.cookie.Id != null ? this.cookie.Id : undefined;
    cookie.Site = this.sites.find(f => f.Url == dataForm.Site) as Site;
    cookie.SiteId = cookie.Site.Id != null ? cookie.Site.Id : undefined;
    cookie.GrupoCookie = this.gruposCookie.find(f => f.Descricao == dataForm.GrupoCookie) as GrupoCookie;
    cookie.GrupoCookieId = cookie.GrupoCookie.Id;
    cookie.Status = cookie.Status ? 1 : 0;

    this.cookieService.salvar(cookie).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        this.toastService.success("Cookie cadastrado com sucesso!");
        this.router.navigateByUrl('/lgpd/cookies');
      },
      (err) => {
        console.log(err);
        this.loadingService.setLoading(false);
        this.toastService.error("Erro ao cadastrar o cookie.");
      });
  }
}
