import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouterModule } from "@angular/router";
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedModule } from "src/app/shared/shared.module";
import { CoreModule } from "../../core/core.module";
import { PortalPrivacidadeComunicacoesComponent } from "./components/portal-privacidade-comunicacoes/portal-privacidade-comunicacoes.component";
import { PortalPrivacidadeDetalhesComponent } from "./components/portal-privacidade-detalhes/portal-privacidade-detalhes.component";
import { PortalPrivacidadeMinhasInformacoesComponent } from "./components/portal-privacidade-minhas-informacoes/portal-privacidade-minhas-informacoes.component";
import { PortalPrivacidadeSegurancaComponent } from "./components/portal-privacidade-seguranca/portal-privacidade-seguranca.component";
import { PortalPrivacidadeRoutingModule } from "./portal-privacidade-routing.module";
import { PortalPrivacidadeComponent } from "./portal-privacidade.component";

@NgModule({
  declarations: [
    PortalPrivacidadeComponent,
    PortalPrivacidadeMinhasInformacoesComponent,
    PortalPrivacidadeComunicacoesComponent,
    PortalPrivacidadeDetalhesComponent,
    PortalPrivacidadeSegurancaComponent,
  ],
  imports: [
    CommonModule,
    PortalPrivacidadeRoutingModule,
    SharedModule,
    CoreModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDividerModule,
    RouterModule,
    MatTooltipModule,
  ],
})

export class PortalPrivacidadeModule { }
