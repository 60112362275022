<div class="container">

  <div class="page-title">GERENCIAR POLÍTICAS</div>

  <br>

  <div>
    <mat-card>
      <div class="politicas__header" fxLayout="row" fxLayoutAlign="space-between center">

        <div class="politicas__pesquisa">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>
              filter_alt
            </mat-icon>
            Filtro
          </button>
          <mat-menu #menu="matMenu">
            <p class="politicas__menu-titulo">
              Buscar por Sites:
            </p>
            <div *ngFor="let site of sites">
              <div class="politicas__pesquisa-filtro">
                <mat-checkbox (change)=filtrarSite(site)>{{site.Url}}</mat-checkbox>
              </div>
            </div>

            <mat-divider></mat-divider>
            <p class="politicas__menu-titulo">
              Políticas:
            </p>
            <div *ngFor="let tipos of tiposPolitica">
              <div class="politicas__pesquisa-filtro">
                <mat-checkbox (change)=filtrarTiposPolitica(tipos)>{{tipos.Descricao}}</mat-checkbox>
              </div>
            </div>

          </mat-menu>

          <div class="politicas__pesquisa-container">
            <mat-icon>search</mat-icon>
            <input [ngModel]="search$ | async" (ngModelChange)="search$.next($event)" placeholder="Pesquisar por: Origem, tipo ou visibilidade" type="text">
          </div>
        </div>

        <button *rhsupercUtilsIsAllowed="'Admin'" mat-raised-button class="add-button" routerLink="/lgpd/politicas/add" routerLinkActive="active">ADICIONAR</button>

      </div>

      <ng-container *ngIf="listPoliticas.length > 0">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="texto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> POLÍTICA </th>
            <td mat-cell *matCellDef="let element"> {{ element.TipoPoliticaDescricao }} </td>
          </ng-container>

          <ng-container matColumnDef="site">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SITE </th>
            <td mat-cell *matCellDef="let element"> {{ element.Site.Url }} </td>
          </ng-container>

          <ng-container matColumnDef="versao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> VERSÃO </th>
            <td mat-cell *matCellDef="let element"> {{ element.Versao }} </td>
          </ng-container>

          <ng-container matColumnDef="acao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CHAVE/AÇÃO </th>
            <td mat-cell *matCellDef="let element"> {{ element.Acao }} </td>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> TIPO </th>
            <td mat-cell *matCellDef="let element"> {{ element.TipoPolitica.Descricao }} </td>
          </ng-container>

          <ng-container matColumnDef="visibilidade">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> VISIBILIDADE </th>
            <td mat-cell *matCellDef="let element"> {{ element.Visibilidade }} </td>
          </ng-container>

          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right;">
              <ng-container *rhsupercUtilsIsAllowed="'Admin'">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/lgpd/politicas/edit/{{element.Id}}">
                  <span>Editar</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item class="text-danger" (click)="deletar(element.Id)">
                  Deletar
                </button>
              </mat-menu>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)"> </mat-paginator>

      </ng-container>
      <h2 *ngIf="listPoliticas.length == 0">Não há registro de políticas</h2>

    </mat-card>
  </div>
</div>