import { RolesService } from "./../services/roles.service";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class OnlyAdminGuard implements CanActivate {
  constructor(
    private roles: RolesService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAdmin = this.roles.checkPermission("Admin");
    console.log(this.roles.checkPermission("Admin"))

    if (!isAdmin) {
      this.toastr.error("Você não tem permissão para acessar essa página.");
      return this.router.navigate(["/login"])
    }

    return isAdmin ? true : this.router.navigate(["/login"]);
  }
}
