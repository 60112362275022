import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ISession } from "../data/interfaces/session/session.interface";
import { SessionStorage } from "../helpers/local-storage.decorator";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  @SessionStorage("SESSION") session!: ISession;
  protected _session$: BehaviorSubject<ISession>;
  session$: Observable<ISession>;

  constructor() {
    this._session$ = new BehaviorSubject(this.session);
    this.session$ = this._session$.asObservable();
  }

  public getSession(): ISession {
    return this.session;
  }

  public setSession(sessionDetails: ISession): void {
    this.session = sessionDetails;
    this._session$.next(this.session);
  }

  public async resetSession() {
    this._session$.next({
      access_token: "",
      expires_in: 0,
      refresh_expires_in: 0,
      refresh_token: "",
      scope: "",
      session_state: "",
      token_type: "",
      "not-before-policy": 0,
    });
    return await sessionStorage.clear();
  }
}
