import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { IDadosOptout } from "src/app/shared/interfaces/dados-optout.interface";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-descadastre-se",
  templateUrl: "./descadastre-se.component.html",
  styleUrls: ["./descadastre-se.component.scss"],
})
export class DescadastreSeComponent implements OnInit {
  dadosOptout: IDadosOptout = {
    Empresa: 1,
    Email: "",
    MotivoOptout: "",
    MotivoOutros: "",
  };

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const valorRota = params["valorRota"];
      this.route.queryParamMap.subscribe((params) => {
        const email: string = params.get("email") || '';
        if (email) {
          this.dadosOptout = {
            Empresa: parseInt(valorRota),
            Email: email,
            MotivoOptout: this.dadosOptout.MotivoOptout,
            MotivoOutros: "",
          };
        }
      });
    });
  }
}
