<div class="container">
    <div>
        <span class="title_loja"> Loja: <strong>{{loja}}</strong></span>
    
        <span class="title_empresa"> {{empresa}}</span>        
    </div>
    <br>
    <div class="page-title">
        Cadastro do Cliente
    </div>

    <mat-card style="margin-top: 10px" class="mat-elevation-z8" *ngIf="!loading">
        <form [formGroup]="formGroup">
            
            <mat-grid-list cols="12" rowHeight="70px">

                <mat-grid-tile [colspan]="6" [rowspan]=1>
                    <mat-form-field appearance="fill" class="ancho">
                        <mat-label>*CPF</mat-label>
                        <input formControlName="cpf" matInput mask="000.000.000-00"  (change)="pesquisaCliente()">
                        <mat-icon matSuffix [cdkCopyToClipboard]="formGroup.controls.cpf.value"
                            style="cursor: default;">content_copy</mat-icon>
                        <mat-error *ngIf="formGroup.controls.cpf.hasError('required')">
                            CPF é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="6" [rowspan]=1>
                    <mat-form-field appearance="fill" class="ancho" *ngIf="!temVendedor">
                        <mat-label>*Codigo funcionário</mat-label>
                        <input formControlName="vendedor" matInput [mask]="mask"  >
                        <mat-error *ngIf="formGroup.controls.vendedor.hasError('required')">
                            Codigo funcionário é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </mat-grid-tile>
          

                <mat-grid-tile [colspan]="6" [rowspan]=1>
                    <mat-form-field appearance="fill" class="ancho">
                        <mat-label>*Primeiro nome</mat-label>
                        <input formControlName="nome"  [cdkTrapFocusAutoCapture]="show" [cdkTrapFocus]="show" (focus)="show=false" matInput [readonly]="tokenEnviado">
                        <mat-error *ngIf="formGroup.controls.nome.hasError('required')">
                            Primeiro nome é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="6" [rowspan]=1>
                    <mat-form-field appearance="fill" class="ancho">
                        <mat-label>*Último nome</mat-label>
                        <input formControlName="sobrenome" matInput [readonly]="tokenEnviado">
                        <mat-error *ngIf="formGroup.controls.sobrenome.hasError('required')">
                            Último nome é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </mat-grid-tile>

            
            
                <mat-grid-tile [colspan]="6" [rowspan]=1>
                    <mat-form-field appearance="fill" class="ancho">
                        <mat-label>*Telefone</mat-label>
                        <input formControlName="telefone" matInput mask="(00) 0 0000-0000" [readonly]="tokenEnviado">
                        <mat-error *ngIf="formGroup.controls.telefone.hasError('required')">
                            Telefone é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="6" [rowspan]=1>
                    <mat-form-field appearance="fill" class="ancho">
                        <mat-label>E-mail</mat-label>
                        <input formControlName="email" type="email" matInput [readonly]="tokenEnviado">
                        <mat-error *ngIf="formGroup.controls.email.hasError('email')">
                            Por favor, digite um e-mail valido
                        </mat-error>
                    </mat-form-field>
                </mat-grid-tile>

            </mat-grid-list>    

            <mat-grid-list cols="1" rowHeight="70px" *ngIf="!tokenEnviado ">
                <mat-grid-tile [colspan]="1" [rowspan]=1>
                    <span *ngIf="clientePrograma">* Para atualizar os dados do cliente continuar com a confirmação por SMS
                    </span>
                    <div class="add-cliente__acoes">
                        <button  class="add-cliente__acoes-save" type="button" [disabled]="!formGroup.valid || request" mat-button (click)="enviarToken(true)">
                            Enviar Token SMS
                        </button>                        
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
        <form [formGroup]="tokenForm">
            <mat-grid-list cols="1" rowHeight="70px" *ngIf="tokenEnviado">                
                <mat-grid-tile [colspan]="1" [rowspan]=1>
                    <mat-form-field appearance="fill" class="ancho_100">
                        <mat-label>*Token</mat-label>
                        <input formControlName="token" type="text" matInput mask="000000">
                        <mat-error *ngIf="tokenForm.controls.token.hasError('required')">
                            Por favor, digite o token enviado
                        </mat-error>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="1" rowHeight="70px" *ngIf="tokenEnviado">
                <mat-grid-tile [colspan]="1" [rowspan]=1>
                    <div class="add-cliente__acoes">
                   
                        <button class="add-cliente__acoes-confirmar" type="button" mat-button  [disabled]="!tokenForm.valid || request" (click)="confirmarCadastro();" style="margin-right: 20px;">
                            Confirmar Token SMS
                        </button>
                   
                        <button class="add-cliente__acoes-confirmar" type="button" (click)="solicitarAutorizacao(true)" *ngIf="!autorizacao && this.autGerente" mat-button>
                            SMS Não Recebido
                        </button>
                        
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </form>

            <mat-grid-list cols="1" rowHeight="70px" *ngIf="cadastroConfirmado">
                <mat-grid-tile [colspan]="1" [rowspan]=1>
                    <h3>
                        Cadastro do cliente confirmado , um SMS com o link para as politicas sera enviado.
                    </h3>
                </mat-grid-tile>
            </mat-grid-list>
        
    <form [formGroup]="autorizacaoForm" *ngIf="autorizacao">
        <mat-grid-list cols="12" rowHeight="70px"  >

            <mat-grid-tile [colspan]="12" [rowspan]=1>
                <h3>
                    Para Continuar por favor solicitar autorização do gerente da loja
                </h3>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="6" [rowspan]=1>
                <mat-form-field appearance="fill" class="ancho">
                    <mat-label>*Usuario Gerente</mat-label>
                    <input formControlName="gerente" type="text" matInput>
                    <mat-error *ngIf="autorizacaoForm.controls.gerente.hasError('required')">
                        Por favor, digite o usuario do gerente
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="6" [rowspan]=1>
                <mat-form-field appearance="fill" class="ancho">
                    <mat-label>*Senha</mat-label>
                    <input formControlName="senha" type="password" matInput>
                    <mat-error *ngIf="autorizacaoForm.controls.senha.hasError('required')">
                        Por favor, digite a senha do gerente
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="12" [rowspan]=1>
                <mat-form-field appearance="fill" class="ancho_100">
                    <mat-label>*Motivo</mat-label>
                    <input formControlName="motivo" type="text" matInput>
                    <mat-error *ngIf="autorizacaoForm.controls.motivo.hasError('required')">
                        Por favor, digite o motivo da autorização manual
                    </mat-error>                    
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="12" [rowspan]=1>                
                    <mat-checkbox formControlName="politica" class="ancho_100">{{textoTermo}}</mat-checkbox>                            
            </mat-grid-tile>
            <mat-grid-tile [colspan]="12" [rowspan]=1>                
                <mat-checkbox formControlName="comunicacao"  class="ancho_100">Aceito receber comunicações via SMS, e-mail e Whatsapp.</mat-checkbox>                            
        </mat-grid-tile>

        </mat-grid-list>

        <mat-grid-list cols="1" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
                <div class="add-cliente__acoes">
                    <button class="add-cliente__acoes-cancel" type="button" mat-button (click)="solicitarAutorizacao(false)" style="margin-right: 20px;">
                       Cancelar
                    </button>
               
                    <button class="add-cliente__acoes-save" type="button" [disabled]="!autorizacaoForm.valid || request" mat-button (click)="obterCodigoGerente()">
                       Autorizar
                    </button>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </form>  


        
    </mat-card>
</div>
