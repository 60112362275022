<c-loading *ngIf="loading">
</c-loading>

<div class="container minhas-informacoes" *ngIf="!loading">
  <div class="minhas-informacoes__header">
    <div class="page-title"> SUAS INFORMAÇÕES</div>
    <p>
      Peça um relatório com seus dados salvos. Depois, você poderá baixá-lo ou
      exportá-lo para o site que quiser.
    </p>
  </div>

  <div class="minhas-informacoes__conteudo">
    <mat-card>

      <div class="minhas-informacoes__card-header">
        <p><b>Selecione as informações para o relatório</b></p>
        <button class="minhas-informacoes__selecionartudo" (click)="selecionarTodos()">
          Selecione Todos
        </button>
      </div>

      <div *ngFor="let informacao of informacoesList">

        <mat-divider></mat-divider>

        <div class="minhas-informacoes__card-item">

          <div class="minhas-informacoes__card-descricao">
            <p><b>{{ informacao.Chave }}</b></p>
            <p> {{ informacao.Valor }} </p>

            <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload multiple>

            <div class="file-upload" *ngIf="informacao.Chave == 'Dados pessoais' && informacao.Selected">
              <button color="primary" class="minhas-informacoes__selecionartudo" matTooltip="Seleciona sua foto e seu documento" (click)="fileUpload.click()">
                Anexar arquivos
              </button>
              <br>
            </div>

            <div *ngIf="files.length > 0 && informacao.Chave == 'Dados pessoais' && informacao.Selected">
              <div *ngFor="let file of files">
                <p> {{ file.name }} </p>
              </div>
            </div>
          </div>

          <div class="minhas-informacoes__card-select">
            <mat-checkbox [(ngModel)]="informacao.Selected"></mat-checkbox>
          </div>

        </div>
      </div>

    </mat-card>

    <div class="minhas-informacoes__rodape">
      <p>O relatório ficará pronto em até 30 dias.</p>
      <div class="minhas-informacoes__acoes">
        <button class="minhas-informacoes__cancelar" type="button" (click)="cancelar()">
          Cancelar
        </button>
        <button class="minhas-informacoes__enviar" type="button" (click)="pedirRelatorio()">
          Pedir relatório
        </button>
      </div>
    </div>
  </div>
</div>