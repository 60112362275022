import {
  HttpClient,
  HttpErrorResponse, HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { AuthTokenService } from "src/app/shared/services/auth-token.service";
import { SessionService } from "src/app/shared/services/session.service";
import { environment } from "src/environments/environment";
import { ISession } from "./../../shared/data/interfaces/session/session.interface";
import { LoadingService } from "./../../shared/services/loading.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  isAuthenticated$ = this.sessionService.session$.pipe(
    map((session) => this.isAuthenticated(session))
  );

  roles$ = new BehaviorSubject(this.authTokenService.decodeFromString(this.sessionService.getSession()?.access_token)?.resource_access?.superc.roles ?? null as string[] | null)

  constructor(
    private httpClientService: HttpClient,
    private sessionService: SessionService,
    private router: Router,
    private toastService: ToastrService,
    private loadingService: LoadingService,
    private authTokenService: AuthTokenService
  ) { }

  isAuthenticated(session = this.sessionService.getSession()) {
    return !!session;
  }

  auth(login: string, senha: string) {
    const options = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      ),
    };

    const params = new HttpParams({
      fromObject: {
        username: login,
        password: senha,
      },
    });

    return this.httpClientService
      .post<ISession>(
        `${environment.keycloak.url}protocol/openid-connect/token`,
        params.toString(),
        options
      )
      .subscribe(
        (response) => {
          const decodedToken = this.authTokenService.decodeFromString(response.access_token);
          this.sessionService.setSession(response);
          this.roles$.next(decodedToken?.resource_access?.superc.roles ?? [])
          this.router.navigate(["/adm"]);
          this.loadingService.setLoading(false);
        },
        (error: HttpErrorResponse) => {
          console.error(error);
          if (error.error.error_description === "Invalid user credentials") {
            this.toastService.error(
              "Senha e/ou nome de usuário incorretos. Tente novamente."
            );
          } else {
            this.toastService.error(
              "Houve um erro ao fazer o login. Tente novamente."
            );
          }
          this.loadingService.setLoading(false);
        }
      );
  }

  logout(): void {
    this.sessionService.resetSession().then(() => {
      this.router.navigateByUrl("/").then(() => location.reload());
    });
  }
}
