<div class="container">

  <div class="page-title"> TIPOS DE POLÍTICA </div>

  <mat-tab-group animationDuration="0ms">

    <mat-tab label="Dados do Tipo da Política">
      <mat-card style="margin-top: 10px" class="mat-elevation-z8" *ngIf="!loading">
        <form [formGroup]="dadosForm">

          <mat-grid-list cols="1" rowHeight="120px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Descrição</mat-label>
                <textarea matInput formControlName="Descricao" #Descricao [(ngModel)]="tipoPolitica.Descricao"></textarea>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

        </form>
      </mat-card>
    </mat-tab>
  </mat-tab-group>

  <mat-grid-list cols="1" rowHeight="70px">
    <mat-grid-tile [colspan]="1" [rowspan]=1>
      <div class="add-tipo-politica__acoes">
        <button class="add-tipo-politica__acoes-cancel" type="button" mat-button style="margin-right: 20px;" routerLink="/lgpd/tipos-politica">
          Cancelar
        </button>
        <button class="add-tipo-politica__acoes-save" type="button" mat-button (click)="salvar()">
          Salvar
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

</div>