import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CrmLoginComponent } from "./crm-login/crm-login.component";

const routes: Routes = [
  {
    path: "",
    component: CrmLoginComponent,
    children: [ 
    
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrmRoutingModule { }