import { SessionService } from "src/app/shared/services/session.service";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-barra-superior",
  templateUrl: "./barra-superior.component.html",
  styleUrls: ["./barra-superior.component.scss"],
})
export class BarraSuperiorComponent implements OnInit {
  constructor(private router: Router, private sessionService: SessionService) {}

  ngOnInit() {}

  logout() {
    this.sessionService.resetSession();
    this.router.navigateByUrl("/");
  }
}
