import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { RespostaPadrao } from "src/app/shared/requests/resposta-padrao.interface";
import { SistemaOrigem, SistemaOrigemKey } from "src/app/shared/interfaces/sistema-origem.interface";
import { ParamsDefault } from "src/app/shared/requests/params-padrao.interface";

@Injectable({
  providedIn: 'root'
})
export class SistemaOrigemService {
  private path = `${environment.apiCadastro.admin}/sistemaorigem`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  listarTodos() {
    return this.http.get<RespostaPadrao<SistemaOrigem[]>>(this.url);
  }

  listarTodosPaginado(filter: ParamsDefault): Promise<RespostaPadrao<SistemaOrigem[]>> {
    const path = `${environment.apiCadastro.admin}/sistemasorigem`;
    const url = `${environment.apiCadastro.base}${path}`;

    let params = new HttpParams()
      .set('pageNumber', String(filter.pageNumber))
      .set('pageSize', String(filter.pageSize));

    if (filter.searchString != null && filter.searchString.length > 0) {
      params = params.set('searchString', String(filter.searchString));
    }

    if (filter.ativo != null) {
      params = params.set('ativo', String(filter.ativo));
    }

    return this.http.get<RespostaPadrao<SistemaOrigem[]>>(
      url,
      {
        params,
      }
    )
      .toPromise();
  }

  listarApiKey() {
    return this.http.get<RespostaPadrao<SistemaOrigemKey[]>>(`${this.url}/key`);
  }

}