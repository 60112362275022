import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InterceptorCrmModule } from "./shared/interceptors/interceptor-crm.module";
import { InterceptorModule } from "./shared/interceptors/interceptor.module";

import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "src/app/app-routing.module";
import { AppComponent } from "src/app/app.component";
import { LoginComponent } from "src/app/auth/pages/login/login.component";
import { SharedModule } from "src/app/shared/shared.module";
import { ErrorComponent } from './error/error.component';
import { IsAllowedConfig, IsAllowedConfigToken } from "./shared/helpers/is-allowed.directive";
import { AuthService } from "./auth/services/auth.service";

@NgModule({
  declarations: [AppComponent, LoginComponent, ErrorComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MatIconModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    HttpClientModule,
    InterceptorModule,
    InterceptorCrmModule,
  ],
  providers: [{
    provide: IsAllowedConfigToken,
    useFactory: (authService: AuthService): IsAllowedConfig => ({ roles$: authService.roles$ }),
    deps: [AuthService]
  },
],
  bootstrap: [AppComponent],
})
export class AppModule {}
