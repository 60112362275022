import { Component, Inject, OnInit } from "@angular/core";
import { take } from 'rxjs/operators';
import { IsAllowedConfig, IsAllowedConfigToken } from "src/app/shared/helpers/is-allowed.directive";
import { RolesService } from "./../../../shared/services/roles.service";

@Component({
  selector: "app-barra-lateral",
  templateUrl: "./barra-lateral.component.html",
  styleUrls: ["./barra-lateral.component.scss"],
})
export class BarraLateralComponent implements OnInit {
  menuColletion = [
    {
      descricao: "Clientes",
      rota: "/adm/clientes",
      icone: "assets/icons/menu-icone-usuarios.svg",
    },
    {
      descricao: "Atributo Campanhas",
      rota: "/adm/atributo-campanhas",
      icone: "assets/icons/menu-icone-usuarios.svg",
    },
    // {
    //   descricao: "Gerenciar Sistemas Origem",
    //   rota: "/adm/sistemas-origem",
    //   icone: "assets/icons/menu-icone-usuarios.svg",
    // },
    {
      descricao: "Gerenciar Sites",
      rota: "/lgpd/sites",
      icone: "assets/icons/menu-icone-privacidade.svg",
    },
    {
      descricao: "Gerenciar Cookies",
      rota: "/lgpd/cookies",
      icone: "assets/icons/menu-icone-privacidade.svg",
    },
    {
      descricao: "Gerenciar Tipos de Política",
      rota: "/lgpd/tipos-politica",
      icone: "assets/icons/menu-icone-privacidade.svg",
    },
    {
      descricao: "Gerenciar Políticas",
      rota: "/lgpd/politicas",
      icone: "assets/icons/menu-icone-privacidade.svg",
    },
    // {
    //   descricao: "Logs de Cookies",
    //   rota: "/lgpd/logs-consentimento",
    //   icone: "assets/icons/menu-icone-privacidade.svg",
    // },
    {
      descricao: "Pedidos do Titular",
      rota: "/lgpd/direitos-titular",
      icone: "assets/icons/menu-icone-titular.svg",
    },
    {
      descricao: "Logs de Opt-in",
      rota: "/lgpd/logs-optin",
      icone: "assets/icons/menu-icone-privacidade.svg",
    },
    {
      descricao: "Logs de Opt-out",
      rota: "/lgpd/logs-optout",
      icone: "assets/icons/menu-icone-privacidade.svg",
    },
    // {
    //   descricao: "Portal da Privacidade",
    //   rota: "/lgpd/portal",
    //   icone: "assets/icons/menu-icone-privacidade.svg",
    // },
    {
      descricao: "Logs de Exclusão",
      rota: "/lgpd/logs-exclusao",
      icone: "assets/icons/menu-icone-privacidade.svg",
    },
    {
      descricao: "Logs de Edição",
      rota: "/lgpd/logs-edicao",
      icone: "assets/icons/menu-icone-privacidade.svg",
    },
    // {
    //   descricao: "Logs Cookies",
    //   rota: "/lgpd/logs-cookies",
    //   icone: "assets/icons/menu-icone-privacidade.svg",
    // },
  ];

  constructor(
    @Inject(IsAllowedConfigToken) public readonly isAllowedConfig: IsAllowedConfig,
    private rolesService: RolesService
    ) {
      isAllowedConfig.roles$?.pipe(take(1))?.subscribe(c => {
        if(c?.find(item => item == 'Consulta' || item == "SAC")){
          this.menuColletion = [
            {
              descricao: "Clientes",
              rota: "/adm/clientes",
              icone: "assets/icons/menu-icone-usuarios.svg",
            }
          ]
        }
      }
      )
     }

  ngOnInit() { }

  get username(): string {
    return this.rolesService.getUsername();
  }
}
