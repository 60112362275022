import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogEdicao, LogExclusao } from 'src/app/shared/interfaces/log-acoes.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

interface Params {
  pageNumber: number;
  pageSize: number;
  documento?: string;
  data: string;
  searchString?: any;
}

@Injectable({
  providedIn: "root",
})
export class LogAcoesService {
  private url = `${environment.apiCadastro.base}${environment.apiCadastro.consulta}`;

  constructor(private http: HttpClient) {}

  listarTodos(filter: Params, type: 'update' | 'delete'): Observable<RespostaPadrao<LogExclusao[] | LogEdicao[]>> {
    let params = new HttpParams()
      .set("pageNumber", String(filter.pageNumber))
      .set("pageSize", String(filter.pageSize))
      
    const resource = type === 'update' ? 'log-update' : 'log-delete';
    
    return this.http.get<RespostaPadrao<LogExclusao[] | LogEdicao[]>>(`${this.url}/${resource}/${filter.data}/${filter.documento}`, { params });
  }
}

