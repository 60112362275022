import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LogAcoesService } from "src/app/lgpd/services/log-acoes.service";
import { LoadingService } from "src/app/shared/services/loading.service";
import { LogsExclusaoComponent } from "../components/logs-exclusao/logs-exclusao.component";
import { LogExclusao } from './../../../../shared/interfaces/log-acoes.interface';

@Component({
  selector: "app-logs-exclusao-list",
  templateUrl: "./logs-exclusao-list.component.html",
  styleUrls: ["./logs-exclusao-list.component.scss"],
})
export class LogsExclusaoListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "cliente",
    "documento",
    "sistemaOrigem",
    "chamado",
    "dataAcao",
    "detalhes",
  ];
  loading = false;

  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  
  form = this.fb.group({
    data: [null, [Validators.required, Validators.minLength(6)]],
    documento: [null],
  });

  dataSource: MatTableDataSource<LogExclusao> = new MatTableDataSource<LogExclusao>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private logExclusaoService: LogAcoesService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadLogs();
  }
  
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  
  loadLogs() {
    
    if (this.form.invalid) {
      return;
    }
    
    this.loadingService.setLoading(true);
    this.loading = true;
    

    const params = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      data: this.form.value.data,
      documento: this.form.value.documento || '',
    };

    this.logExclusaoService.listarTodos(params, 'delete').subscribe(
      (res) => {
        // console.log(res);
        this.dataSource = new MatTableDataSource<LogExclusao>(res.Data as LogExclusao[]);
        this.length = res.TotalRecords;
        this.dataSource.sort = this.sort;
        this.loadingService.setLoading(false);
        this.loading = false;
      },
      (err) => {
        this.loadingService.setLoading(false);
        this.loading = false;
      }
    );
  }

  showDetalhes(log: any) {
    this.dialog.open(LogsExclusaoComponent, {
      data: log,
      width: "65vw",
      maxHeight: "70vh",
      panelClass: 'custom-log-eclusao-container'
    });
  }
  
  updatePagination(event: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadLogs();
  }
}
