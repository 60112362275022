import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TipoSolicitacao } from 'src/app/shared/interfaces/tipo-solicitacao.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoSolicitacaoService {
  private path = `${environment.apiCadastro.lgpd}/tipoSolicitacao`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  listarTodos() {
    return this.http.get<RespostaPadrao<TipoSolicitacao[]>>(this.url);
  }

  salvar(dados: TipoSolicitacao) {
    if (dados.Id == null)
      return this.http.post<TipoSolicitacao>(this.url, dados);
    else
      return this.http.put<TipoSolicitacao>(this.url, dados);
  }

  dadosTipoSolicitacao(id: string) {
    return this.http.get<RespostaPadrao<TipoSolicitacao>>(`${this.url}/${id}`);
  }

  deletar(id: string) {
    return this.http.delete(`${this.url}/${id}`);
  }

}
