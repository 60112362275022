import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-portal-privacidade-detalhes",
  templateUrl: "./portal-privacidade-detalhes.component.html",
  styleUrls: ["./portal-privacidade-detalhes.component.scss"],
})
export class PortalPrivacidadeDetalhesComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  administrarPreferencias() {
    console.log("administrarPreferencias");
  }

  pedirRelatorio() {
    this.router.navigateByUrl("/lgpd/suas-informacoes");
  }

  cancelarConta() {
    console.log("cancelarConta");
  }
}
