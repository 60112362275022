import { LoadingService } from "./../../../shared/services/loading.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainLayoutComponent implements OnInit {
  public configs = {
    opened: true,
    sidebarMode: "side",
    hasBackdrop: false,
  };

  opened: any;
  loading = false;

  constructor(
    private loadingService: LoadingService,
    private cdRef: ChangeDetectorRef,) { }

  ngOnInit() {
    this.loadingService.loading$.subscribe((loadingStatus) => {
      this.loading = loadingStatus;
      this.cdRef.detectChanges();
    });
  }
}
