import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Lead } from 'src/app/shared/interfaces/lead.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  private pathClient = `${environment.apiCadastro.base}cadastro/Register/Cliente/Lead`;
  private path = `${environment.apiCadastro.register}/Lead`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  salvar(dados: Lead) {

    if (dados.Id == null)
      return this.http.post<Lead>(this.pathClient, dados);
    else
      return this.http.put<Lead>(this.pathClient, dados);
  }

}
