import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AddLeadComponent } from "./components/add-lead/add-lead.component";
import { LeadComponent } from "./lead.component";

const routes: Routes = [
  {
    path: "",
    component: LeadComponent,
    children: [
      { path: "", redirectTo: "lead", pathMatch: "full" },
      { path: "add", component: AddLeadComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LeadRoutingModule { }
