<div class="container">

  <div class="page-title">TIPOS DE POLÍTICA</div>

  <br>

  <div>
    <mat-card>
      <div class="tipos-politica__header" fxLayout="row" fxLayoutAlign="space-between center">

        <div class="tipos-politica__pesquisa">
          <div class="tipos-politica__pesquisa-container">
            <mat-icon>search</mat-icon>
            <input [ngModel]="search$ | async" (ngModelChange)="search$.next($event)" placeholder="Pesquisar por: Descrição" type="text">
          </div>
        </div>

        <button *rhsupercUtilsIsAllowed="'Admin'" mat-raised-button class="add-button" routerLink="/lgpd/tipos-politica/add" routerLinkActive="active">ADICIONAR</button>

      </div>

      <ng-container *ngIf="tiposPoliticaList.length > 0">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="descricao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DESCRIÇÃO </th>
            <td mat-cell *matCellDef="let element"> {{element.Descricao}} </td>
          </ng-container>

          <ng-container matColumnDef="ativo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ATIVO </th>
            <td mat-cell *matCellDef="let element"> {{ element.Ativo == true ? "Ativo" :"Inativo" }} </td>
          </ng-container>

          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right;">
              <ng-container *rhsupercUtilsIsAllowed="'Admin'">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/lgpd/tipos-politica/edit/{{element.Id}}">
                  <span>Editar</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item class="text-danger" (click)="deletar(element.Id)">
                  Deletar
                </button>
              </mat-menu>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="length" (page)="updatePagination($event)"> </mat-paginator>

      </ng-container>

      <h2 *ngIf="tiposPoliticaList.length == 0">Não há registro de tipos de política</h2>

    </mat-card>
  </div>
</div>