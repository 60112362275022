<div class="container">

  <div class="page-title"> DIREITOS DO TITULAR > PEDIDOS DO TITULAR </div>

  <mat-tab-group animationDuration="0ms">

    <mat-tab label="Dados da Solicitação">
      <mat-card style="margin-top: 10px" class="mat-elevation-z8">
        <form [formGroup]="dadosForm">

          <mat-grid-list cols="3" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Protocolo</mat-label>
                <input formControlName="Protocolo" #Protocolo matInput [(ngModel)]="solicitacao.Protocolo">
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Nome do Cliente</mat-label>
                <input formControlName="NomeCliente" #NomeCliente matInput>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Email do Cliente</mat-label>
                <input formControlName="EmailCliente" #EmailCliente matInput>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <br>

          <mat-grid-list cols="3" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Status</mat-label>
                <input formControlName="Status" #Status matInput [(ngModel)]="solicitacao.Status">
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Data do Pedido</mat-label>
                <input formControlName="DataCriacao" #DataCriacao matInput [(ngModel)]="solicitacao.DataCriacaoFormatado">
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

        </form>

      </mat-card>
    </mat-tab>
  </mat-tab-group>

  <br>
  <br>

  <div class="detalhes__conteudo">
    <mat-card>
      <div class="detalhes__card-header">
        <p><b>Informações selecionadas para o relatório</b></p>
      </div>

      <div *ngFor="let informacao of solicitacao.TiposSolicitacao">

        <mat-divider></mat-divider>

        <div class="detalhes__card-item">
          <div class="detalhes__card-descricao">
            <p>{{ informacao.TipoSolicitacao.Chave }}</p>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <br>
  <br>

  <div class="detalhes__conteudo">
    <mat-card>
      <div class="detalhes__comentario-header">
        <div class="detalhes__card-header" *ngIf="loadingAnexo">
          <p><b>Carregando Arquivos Anexados</b></p>
        </div>
        <div class="detalhes__card-header" *ngIf="!loadingAnexo && arquivos.length <= 0">
          <p><b>Não há Arquivos Anexados</b></p>
        </div>
        <div class="detalhes__card-header" *ngIf="!loadingAnexo && arquivos.length > 0">
          <p><b>Arquivos Anexados</b></p>
        </div>
      </div>

      <c-loading class="loading-anexo" *ngIf="loadingAnexo">
      </c-loading>

      <div *ngIf="!loadingAnexo">
        <div *ngFor="let arquivo of arquivos" (click)="download(arquivo)" style="cursor: pointer;">
          <mat-divider></mat-divider>
          <div class="detalhes__card-item" style="padding-top: 15px">
            <div fxFlex="78" style="flex: 1 1 78%; box-sizing: border-box; max-width: 78%;">
              <p>{{ arquivo.Nome }}</p>
            </div>
            <div fxFlex="8" style="flex: 1 1 8%; box-sizing: border-box; max-width: 8%;">
              <p>{{ arquivo.TamanhoFormatted }}</p>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <br>
  <br>

  <div class="detalhes__conteudo">
    <mat-card>

      <div class="detalhes__comentario-header">
        <div class="detalhes__card-header" *ngIf="comentarios.length <= 0">
          <p><b>Não há registro de comentários</b></p>
        </div>
        <div class="detalhes__card-header" *ngIf="comentarios.length > 0">
          <p><b>Comentários</b></p>
        </div>

        <button class="detalhes__selecionartudo" (click)="openAddComentarioDialog()">ADICIONAR COMENTÁRIO</button>
      </div>

      <div *ngFor="let comentario of comentarios">

        <mat-divider></mat-divider>

        <div style="padding-top: 15px">
          <div fxFlex>
            <p>{{ comentario.Comentario }}</p>
          </div>

          <div fxFlex *ngFor="let arquivo of comentario.Files" (click)="downloadFileComentario(arquivo, comentario.Id)">
            <div fxFlex class="file">
              <p>{{ arquivo.Nome }} - {{ arquivo.TamanhoFormatted }}</p>
            </div>
          </div>

          <div fxFlex style="font-size: 12px;">
            <p>{{ comentario.NomeUsuario }} | {{ comentario.DataAtualizacao | date:'dd/MM/yyy HH:mm' }}</p>
          </div>

          <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações" fxFlex>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openAddComentarioDialog(comentario.Id)">
              <span>Editar</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item class="text-danger" (click)="deletarComentario(comentario.Id)">
              Deletar
            </button>
          </mat-menu> -->
        </div>
      </div>
    </mat-card>
  </div>

  <mat-grid-list cols="1" rowHeight="70px">
    <mat-grid-tile [colspan]="1" [rowspan]=1>
      <div class="detalhes__acoes">
        <button class="detalhes__acoes-save" type="button" mat-button style="margin-right: 20px;" routerLink="/lgpd/direitos-titular">
          Voltar
        </button>
        <!-- <button class="detalhes__acoes-save" type="button" mat-button (click)="salvar()">
          Salvar
        </button> -->
      </div>
    </mat-grid-tile>
  </mat-grid-list>

</div>