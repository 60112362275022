import { SessionService } from "src/app/shared/services/session.service";
import { Observable, throwError,NEVER } from "rxjs";
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
  } from "@angular/router";
import { catchError,tap } from "rxjs/operators";
import { ERROR_COMPONENT_TYPE } from "@angular/compiler";


@Injectable()
export class InterceptorCrm implements HttpInterceptor {
  constructor(private sessionService: SessionService,private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const session = this.sessionService.getSession();

    if (session) {
      request = request.clone({
        setHeaders: {
          Authorization: `${session.token_type} ${session.access_token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {          
          if(!!error.status && error.status === 401){
            if(this.router.url!="/login"){
              this.router.navigate(['crm-login']);
              return NEVER;
            }
          }
        return throwError(error);
      })
    );


  }
}
