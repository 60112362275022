import { LoadingService } from "./../../../shared/services/loading.service";
import { AuthService } from "./../../services/auth.service";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  logo = "https://assets.stickpng.com/images/5847ea22cef1014c0b5e4833.png";

  form: FormGroup;
  loading: false | undefined | true;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private loadingService: LoadingService
  ) {
    this.form = this.fb.group({
      usuario: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  ngOnInit(): void {}

  ingresar() {
    const { usuario, password } = this.form.getRawValue();
    this.loadingService.setLoading(true);
    this.authService.auth(usuario, password);

    // if (usuario === "admin" && password === "admin123") {
    //   this.fakeLoading();
    // } else {
    //   this.error();
    //   this.form.reset();
    // }
  }

  // error() {
  //   this._snackBar.open("Usuário ou senha inválida", "", {
  //     duration: 5000,
  //     horizontalPosition: "center",
  //     verticalPosition: "bottom",
  //   });
  // }

  // fakeLoading() {
  //   this.loading = true;
  //   setTimeout(() => {
  //     this.router.navigateByUrl("/adm");
  //   }, 1500);
  // }
}
