<div class="container minhas-informacoes">
  <div class="minhas-informacoes__header">
    <div class="page-title">Portal da privacidade > suas informações</div>
    <p><b>Suas informações</b></p>
    <p>
      Peça um relatório com seus dados salvos. Depois, você poderá baixá-lo ou
      exportá-lo para o site que quiser.
    </p>
  </div>
  <form [formGroup]="relatorioPrivacidade" (ngSubmit)="pedirRelatorio()">
  <div class="minhas-informacoes__conteudo">
    <mat-card>
      <div class="minhas-informacoes__card-header">
        <p><b>Selecione as informações para o relatório</b></p>
        <button class="minhas-informacoes__selecionartudo">
          Selecione Tudo
        </button>
      </div>

      <mat-divider></mat-divider>
      <div class="minhas-informacoes__card-item">
        <div class="minhas-informacoes__card-descricao">
          <p><b>Dados pessoais</b></p>
          <p>
            Peça um relatório com seus dados salvos. Depois, você poderá
            baixá-lo ou exportá-lo para o site que quiser.
          </p>
        </div>
        <div class="minhas-informacoes__card-select">
          <mat-checkbox formControlName="dadosPessoais"></mat-checkbox>
        </div>
      </div>

      <mat-divider></mat-divider>
      <div class="minhas-informacoes__card-item">
        <div class="minhas-informacoes__card-descricao">
          <p><b>Dados da conta</b></p>
          <p>
            Peça um relatório com seus dados salvos. Depois, você poderá
            baixá-lo ou exportá-lo para o site que quiser.
          </p>
        </div>
        <div class="minhas-informacoes__card-select">
          <mat-checkbox formControlName="dadosConta"></mat-checkbox>
        </div>
      </div>

      <mat-divider></mat-divider>
      <div class="minhas-informacoes__card-item">
        <div class="minhas-informacoes__card-descricao">
          <p><b> Cookies aceitos </b></p>
          <p>
            Peça um relatório com seus dados salvos. Depois, você poderá
            baixá-lo ou exportá-lo para o site que quiser.
          </p>
        </div>
        <div class="minhas-informacoes__card-select">
          <mat-checkbox formControlName="cookiesAceitos"></mat-checkbox>
        </div>
      </div>

      <mat-divider></mat-divider>
      <div class="minhas-informacoes__card-item">
        <div class="minhas-informacoes__card-descricao">
          <p><b>Lista de consentimentos</b></p>
          <p>
            Peça um relatório com seus dados salvos. Depois, você poderá
            baixá-lo ou exportá-lo para o site que quiser.
          </p>
        </div>
        <div class="minhas-informacoes__card-select">
          <mat-checkbox formControlName="listaConsentimentos"></mat-checkbox>
        </div>
      </div>
    </mat-card>

    <div class="minhas-informacoes__rodape">
      <p>O relatório ficará pronto em até 30 dias.</p>
      <div class="minhas-informacoes__acoes">
        <button class="minhas-informacoes__cancelar" type="button" (click)="cancelar()">
          Cancelar
        </button>
        <button class="minhas-informacoes__enviar" type="submit">
          Pedir relatório
        </button>
      </div>
    </div>
  </div>
</form>
</div>
