import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogsCookie } from 'src/app/shared/interfaces/cookies-logs.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class LogCookiesService {
  private path = `${environment.apiCadastro.consulta}/cookies`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(private http: HttpClient) {
    moment.locale("pt-br");
  }

  listarTodos(dataInicial: string, dataFinal: string): Observable<LogsCookie[]> {
    console.log(dataInicial, dataFinal)

    return this.http
      .get<string[]>(`${this.url}/listagem/${dataInicial}/${dataFinal}`)
      .pipe(
        map((res) => {
          return res.map((log) => ({
            date: log,
            formattedDate: this.parseDate(log),
          }));
        })
      );
    
  }

  private parseDate(date: string): string {
    return moment(date, "M/YYYY").format("MMM/YYYY").toUpperCase();
  }
  
  downloadLog(data: string) {
    return this.http.get<{ Planilha: string }>(`${this.url}/download-csv/${data}`);
  }
}
