<div class="header">
  <div class="header__title">Log Detalhes</div>
  <mat-icon matDialogClose>close</mat-icon>
</div>

<div class="body">
  
  <h3 class="title">Cliente</h3>
  
  <div class="grid">
    <app-log-item title="Data Atualização" value="{{ data.DataAtualizacao | date:'dd/MM/yyyy HH:mm' }}"></app-log-item>
    <app-log-item title="Nome" value="{{ data.Nome }} {{ data.SobreNome }}"></app-log-item>
    <app-log-item title="Data de Nascimento" value="{{ data.DataNascimento | date:'dd/MM/yyyy' }}"></app-log-item>
    <app-log-item title="Tipo Cliente" [value]="data.TipoCliente"></app-log-item>
    <app-log-item title="Documento" [value]="data.Documento"></app-log-item>
    <app-log-item title="Tipo Documento" [value]="data.TipoDocumento"></app-log-item>
    <app-log-item title="Ativo" [value]="data.Ativo"></app-log-item>
    <app-log-item title="Status" [value]="data.Status"></app-log-item>
    <app-log-item title="Categoria" [value]="data.Categoria"></app-log-item>
    <app-log-item title="Empresa" [value]="data.Empresa.Nome"></app-log-item>
  </div>
  
  <ng-container *ngIf="data.Atributos?.length">
    <h3 class="title">Termos</h3>
    
    <div class="grid" *ngFor="let atributo of data.Atributos">
      <app-log-item title="Nome" [value]="atributo.Atributo.Nome"></app-log-item>
      <app-log-item title="Valor" [value]="atributo.Valor"></app-log-item>
    </div>
  </ng-container>
    
  <!-- Endereços -->
  
  <ng-container *ngIf="data?.Enderecos?.length">
    <h3 class="title">Endereço</h3>
    
    <div class="grid" *ngFor="let endereco of data.Enderecos">
      <app-log-item title="Rua" [value]="endereco.Rua"></app-log-item>
      <app-log-item title="Número" [value]="endereco.Numero"></app-log-item>
      <app-log-item title="Bairro" [value]="endereco.Bairro"></app-log-item>
      <app-log-item title="CEP" [value]="endereco.CEP"></app-log-item>
      <app-log-item title="Cidade" [value]="endereco.Cidade"></app-log-item>
      <app-log-item title="Estado" [value]="endereco.Estado"></app-log-item>
      <app-log-item title="Complemento" [value]="endereco.Complemento"></app-log-item>
    </div>
  </ng-container>
  
  <!-- Emails -->
  
  <ng-container *ngIf="data.Emails?.length">
    <h3 class="title">E-mails</h3>
    
    <div class="grid" *ngFor="let email of data.Emails">
      <app-log-item title="E-mail" [value]="email.Endereco"></app-log-item>
      <app-log-item title="Tipo de Email" [value]="email.Tipo"></app-log-item>
    </div>
  </ng-container>
  
  <!-- Telefones -->
  <ng-container *ngIf="data.Telefones?.length">
    <h3 class="title">Telefones</h3>
    
    <div class="grid"*ngFor="let telefone of data.Telefones">
      <app-log-item title="DDI" [value]="telefone.DDI"></app-log-item>
      <app-log-item title="DDD" [value]="telefone.DDD"></app-log-item>
      <app-log-item title="Número" [value]="telefone.Numero"></app-log-item>
      <!-- <app-log-item title="Tipo" [value]="telefone.Tipo"></app-log-item> -->
    </div>
  </ng-container>
  
  <!-- Crianças -->
  <ng-container *ngIf="data.Criancas?.length">
    <h3 class="title">Crianças</h3>
    
    <div class="grid" *ngFor="let crianca of data.Criancas">
      <app-log-item title="Nome da Criança" [value]="crianca.Nome"></app-log-item>
      <app-log-item title="Data Nascimento" value="{{ crianca.MesNascimento }}/{{ crianca.AnoNascimento }}"></app-log-item>
      <app-log-item title="Gênero" [value]="crianca.Genero"></app-log-item>
      <app-log-item title="Parentesco" [value]="crianca.Parentesco"></app-log-item>
      <app-log-item title="Data Criação" [value]="crianca.DataCriacao"></app-log-item>
    </div>
  </ng-container>
</div>

<div class="footer">
  <button mat-button class="add-button" mat-dialog-close="">Fechar</button>
</div>
