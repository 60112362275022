import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorCrm } from "./interceptor-crm.service";

@NgModule({
  providers: [
    InterceptorCrm,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorCrm,
      multi: true,
    },
  ],
})
export class InterceptorCrmModule {}
