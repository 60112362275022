import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Cliente } from 'src/app/shared/interfaces/cliente.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/shared/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultaService {
  private path = `${environment.apiCadastro.consulta}`;
  private url = `${environment.apiCadastro.base}${this.path}`;
  private apiKey:string ="";
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { 

  }


  dadosCliente(cpf: string) {
    const session =  this.sessionService.getSession();
    const empresa = session.empresa??"";

    const headers= new HttpHeaders()    
    .set('apikey', session.apikey!)
    return this.http.get<RespostaPadrao<Cliente>>(`${this.url}/cliente/loja/${cpf}?empresa=${empresa}`,{headers:headers});
  }

 
}
