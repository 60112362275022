import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LoadingService } from "../../shared/services/loading.service";

@Component({
  selector: "app-portal-privacidade",
  templateUrl: "./portal-privacidade.component.html",
  styleUrls: ["./portal-privacidade.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortalPrivacidadeComponent implements OnInit {

  loading = false;

  constructor(
    private loadingService: LoadingService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.loadingService.loading$.subscribe((loadingStatus) => {
      this.loading = loadingStatus;
      this.cdRef.detectChanges();
    });
  }
}