import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ArquivoSolicitacao } from 'src/app/shared/interfaces/arquivo-solicitacao.interface';
import { Comentario } from 'src/app/shared/interfaces/comentario.interface';
import { SolicitacaoDetalhes } from 'src/app/shared/interfaces/solicitacao-detalhes.interface';
import { Solicitacao } from 'src/app/shared/interfaces/solicitacao.interface';
import { StatusSolicitacao } from 'src/app/shared/interfaces/status-solicitacao.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoService {
  private path = `${environment.apiCadastro.lgpd}/solicitacao`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
  ) { }

  listarTodos(pageNumber: number = 1, pageSize: number = 10, searchString = null): Promise<RespostaPadrao<Solicitacao[]>> {
    const params = new HttpParams()
      .set('pageNumber', String(pageNumber))
      .set('pageSize', String(pageSize));

    if (searchString != null) {
      params.append('searchString', String(searchString))
    }

    return this.http.get<RespostaPadrao<Solicitacao[]>>(
      this.url,
      {
        params,
      }
    )
      .toPromise();
  }

  alterarStatus(dados: StatusSolicitacao) {
    return this.http.post<StatusSolicitacao>(this.url + "/alterarStatus", dados);
  }

  dadosSolicitacao(id: string) {
    return this.http.get<RespostaPadrao<SolicitacaoDetalhes>>(`${this.url}/${id}`);
  }

  loadArquivos(id: string) {
    return this.http.get<RespostaPadrao<ArquivoSolicitacao[]>>(`${this.url}/${id}/arquivos`);
  }

  loadComentarios(id: string) {
    const path = `${environment.apiCadastro.lgpd}/comentario/solicitacao`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.get<RespostaPadrao<Comentario[]>>(`${url}/${id}`);
  }

  dadosComentario(comentarioId: string) {
    const path = `${environment.apiCadastro.lgpd}/comentario`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.get<RespostaPadrao<Comentario>>(`${url}/${comentarioId}`);
  }

  salvarComentario(dados: Comentario, files: any) {
    const path = `${environment.apiCadastro.lgpd}/comentario`;
    const url = `${environment.apiCadastro.base}${path}`;

    if (dados.Id == null)
      return this.http.post<Comentario>(url, dados);
    else
      return this.http.put<Comentario>(url, dados);
  }

  deletarComentario(comentarioId: string) {
    const path = `${environment.apiCadastro.lgpd}/comentario`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.delete(`${url}/${comentarioId}`);
  }

  download(id: string, arquivoId: string) {
    return this.http.get(`${this.url}/${id}/download/${arquivoId}`, { responseType: 'blob' });
  }

  uploadFileComentario(files: any, comentarioId: string) {
    const path = `${environment.apiCadastro.lgpd}/comentario/${comentarioId}/upload`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.post(url, files);
  }

  downloadFileComenario(id: string, arquivoId: string) {
    const path = `${environment.apiCadastro.lgpd}/comentario/${id}/download/${arquivoId}`;
    const url = `${environment.apiCadastro.base}${path}`;

    return this.http.get(url, { responseType: 'blob' });
  }
}
