<mat-drawer-container autosize [hasBackdrop]="configs.hasBackdrop" class="main-app ">
  <mat-drawer [mode]="'side'" [opened]="true">
    <!-- <mat-drawer [mode]="configs.sidebarMode" [(opened)]="configs.opened"
    [class]="configs.opened ? 'painel__aberto' : 'painel__fechado'" class=""> -->
    <app-barra-lateral>
    </app-barra-lateral>
    <!-- <app-sidebar (toggleSidebarEvent)="configs.opened = !configs.opened" [itensMenu]="itensMenu">
    </app-sidebar> -->
  </mat-drawer>
  <mat-drawer-content>
    <!-- <mat-drawer-content [class]="configs.opened ? 'painel__aberto' : 'painel__fechado'" class=""> -->
    <!-- <app-barra-superior (toggleSidebarEvent)="configs.opened = !configs.opened"
        [exibeBotaoToggle]="(!configs.isMobile && !configs.opened) || configs.isMobile">
      </app-barra-superior> -->
    <app-barra-superior>
    </app-barra-superior>

    <ng-content select="[body]">
    </ng-content>

  </mat-drawer-content>
</mat-drawer-container>

<c-loading *ngIf="loading">
</c-loading>