import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { LogEdicaoDetalhesComponent } from './logs-edicao/pages/components/log-edicao-detalhes/log-edicao-detalhes.component';
import { LogsEdicaoListComponent } from './logs-edicao/pages/logs-edicao-list/logs-edicao-list.component';
import { LogItemComponent } from "./logs-exclusao/pages/components/log-item/log-item.component";

import { NgxMaskModule } from "ngx-mask";
import { DireitosTitularComponent } from "src/app/lgpd/direitos-titular/pages/list/direitos-titular.component";
import { GerenciarPoliticasComponent } from "src/app/lgpd/gerenciar-politicas/pages/list/gerenciar-politicas.component";
import { LgpdRoutingModule } from "src/app/lgpd/lgpd-routing.module";
import { LgpdComponent } from "src/app/lgpd/lgpd.component";
import { PortalPrivacidadeComponent } from "src/app/lgpd/portal-privacidade/pages/list/portal-privacidade.component";
import { SharedModule } from "src/app/shared/shared.module";
import { CoreModule } from "./../core/core.module";
import { AddCookieComponent } from "./cookies/components/add-cookie/add-cookie.component";
import { CookiesListComponent } from "./cookies/pages/cookies-list/cookies-list.component";
import { SolicitacaoDetalhesComponent } from "./direitos-titular/components/solicitacao-detalhes/solicitacao-detalhes.component";
import { AddPoliticaComponent } from "./gerenciar-politicas/components/add-politica/add-politica.component";
import { AddGrupoCookieComponent } from "./group-cookies/components/add-grupo-cookie/add-grupo-cookie.component";
import { LogsConsentimentoListComponent } from "./logs-consentimento/pages/list/logs-consentimento-list/logs-consentimento-list.component";
import { LogsCookiesListComponent } from "./logs-cookies/pages/logs-cookies-list/logs-cookies-list.component";
import { LogsExclusaoComponent } from "./logs-exclusao/pages/components/logs-exclusao/logs-exclusao.component";
import { LogsExclusaoListComponent } from "./logs-exclusao/pages/logs-exclusao-list/logs-exclusao-list.component";
import { LogsOptinListComponent } from './logs-optin/pages/list/logs-optin-list/logs-optin-list.component';
import { LogsOptoutListComponent } from './logs-optout/pages/logs-optout-list.component';
import { PortalPrivacidadeComunicacoesComponent } from "./portal-privacidade/components/portal-privacidade-comunicacoes/portal-privacidade-comunicacoes.component";
import { PortalPrivacidadeDetalhesComponent } from "./portal-privacidade/components/portal-privacidade-detalhes/portal-privacidade-detalhes.component";
import { PortalPrivacidadeSegurancaComponent } from "./portal-privacidade/components/portal-privacidade-seguranca/portal-privacidade-seguranca.component";
import { PortalPrivacidadeMinhasInformacoesComponent } from "./portal-privacidade/pages/portal-privacidade-minhas-informacoes/portal-privacidade-minhas-informacoes.component";
import { AddSiteComponent } from './sites/components/add-site/add-site.component';
import { SiteListComponent } from './sites/pages/list/site-list/site-list.component';
import { AddTipoPoliticaComponent } from './tipos-politica/components/add-tipo-politica/add-tipo-politica.component';
import { TipoPoliticaListComponent } from './tipos-politica/pages/list/tipo-politica-list/tipo-politica-list.component';

@NgModule({
  declarations: [
    LgpdComponent,
    GerenciarPoliticasComponent,
    DireitosTitularComponent,
    PortalPrivacidadeComponent,
    PortalPrivacidadeComunicacoesComponent,
    PortalPrivacidadeSegurancaComponent,
    PortalPrivacidadeDetalhesComponent,
    AddPoliticaComponent,
    CookiesListComponent,
    AddCookieComponent,
    PortalPrivacidadeMinhasInformacoesComponent,
    LogsConsentimentoListComponent,
    AddGrupoCookieComponent,
    SiteListComponent,
    AddSiteComponent,
    TipoPoliticaListComponent,
    AddTipoPoliticaComponent,
    LogsOptinListComponent,
    SolicitacaoDetalhesComponent,
    LogsExclusaoListComponent,
    LogsExclusaoComponent,
    LogItemComponent,
    LogsEdicaoListComponent,
    LogEdicaoDetalhesComponent,
    LogsCookiesListComponent,
    LogsOptoutListComponent
  ],
  imports: [
    CommonModule,
    LgpdRoutingModule,
    SharedModule,
    CoreModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    NgxMaskModule.forChild(),
  ],
})
export class LgpdModule {}
