import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LogConsentimento } from 'src/app/shared/interfaces/log-consentimento.interface';
import { ParamsDefault } from 'src/app/shared/requests/params-padrao.interface';
import { RespostaPadrao } from 'src/app/shared/requests/resposta-padrao.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogConsentimentoService {
  private path = `${environment.apiCadastro.lgpd}/logsconsentimento`;
  private url = `${environment.apiCadastro.base}${this.path}`;

  constructor(
    private http: HttpClient,
    // private sessionService: SessionService
  ) { }

  listarTodos(filter: ParamsDefault): Promise<RespostaPadrao<LogConsentimento[]>> {
    let params = new HttpParams()
      .set('pageNumber', String(filter.pageNumber))
      .set('pageSize', String(filter.pageSize));

    if (filter.searchString != null && filter.searchString.length > 0) {
      params = params.set('searchString', String(filter.searchString))
    }

    if (filter.sitesIds != null && filter.sitesIds.length > 0) {
      params = params.set('sitesIds', JSON.stringify(filter.sitesIds));
    }

    return this.http.get<RespostaPadrao<LogConsentimento[]>>(
      this.url,
      {
        params,
        // headers,
      }
    )
      .toPromise();
  }

}
