import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LoginComponent } from "src/app/auth/pages/login/login.component";
import { ErrorComponent } from "./error/error.component";
import { AceitePoliticaModule } from "./aceite-politica/aceite-politica.module";
import { AdmModule } from "./adm/adm.module";
import { LgpdModule } from "./lgpd/lgpd.module";
import { LeadModule } from "./public-pages/lead/lead.module";
import { PortalPrivacidadeModule } from "./public-pages/portal-privacidade/portal-privacidade.module";
import { OnlyAdminGuard } from "./shared/guards/only-admin.guard";
import { OnlyAuthGuard } from "./shared/guards/only-auth.guard";
import { OnlyCrmGuard } from "./shared/guards/only-crm.guard";
import { CrmModule } from "./crm/crm.module";
import { CrmLoginModule } from "./crm-login/crm-login.module";
import { environment } from "src/environments/environment";
import { DescadastreSeComponent } from "./public-pages/descadastre-se/descadastre-se/descadastre-se.component";
import { DescadastreSeModule } from "./public-pages/descadastre-se/descadastre-se/descadastre-se.module";

const routes: Routes = [
  { path: "", redirectTo: environment.loginPage, pathMatch: "full" },
  { path: "login", component: LoginComponent },
  {
    path: "lgpd",
    loadChildren: () => LgpdModule,
    canActivate: [OnlyAdminGuard],
  },
  {
    path: "adm",
    loadChildren: () => AdmModule,
    canActivate: [OnlyAuthGuard],
  },
  {
    path: "descadastrar/:valorRota",
    component: DescadastreSeComponent,
    loadChildren: () => DescadastreSeModule,
  },
  {
    path: "portal-privacidade",
    loadChildren: () => PortalPrivacidadeModule,
  },
  {
    path: "aceite-politica",
    loadChildren: () => AceitePoliticaModule,
  },
  {
    path: "lead",
    loadChildren: () => LeadModule,
  },
  {
    path: "crm",
    loadChildren: () => CrmModule,
    canActivate: [OnlyCrmGuard],
  },
  {
    path: "crm-login",
    loadChildren: () => CrmLoginModule,
  },
  {
    path: "**",
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
