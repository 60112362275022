import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  loading$ = new BehaviorSubject(false);
  constructor() {}

  setLoading(loadingStatus: boolean) {
    this.loading$.next(loadingStatus);
  }
}
