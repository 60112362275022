<div class="container">

  <div class="page-title"> GRUPO DE COOKIE </div>

  <mat-tab-group animationDuration="0ms">

    <mat-tab label="Dados do Grupo de Cookie">
      <mat-card style="margin-top: 10px" class="mat-elevation-z8" *ngIf="!loading">
        <form [formGroup]="dadosForm">

          <mat-grid-list cols="1" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]=1>
              <mat-form-field appearance="fill" class="ancho">
                <mat-label>Descrição</mat-label>
                <input formControlName="Descricao" #Descricao matInput [(ngModel)]="grupoCookie.Descricao" maxlength="500">
                <mat-hint align="end">{{Descricao.value.length}} / 500</mat-hint>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

        </form>
      </mat-card>
    </mat-tab>
  </mat-tab-group>

  <mat-grid-list cols="1" rowHeight="70px">
    <mat-grid-tile [colspan]="1" [rowspan]=1>
      <div class="add-grupo-cookie__acoes">
        <button class="add-grupo-cookie__acoes-cancel" type="button" mat-button style="margin-right: 20px;" routerLink="/lgpd/cookies">
          Cancelar
        </button>
        <button class="add-grupo-cookie__acoes-save" type="button" mat-button (click)="salvar()">
          Salvar
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

</div>