import { DecodedJWT } from "./../data/interfaces/session/decoded-jwt.interface";
import { SessionService } from "./session.service";
import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthTokenService {
  constructor(private sessionService: SessionService) {}

  private getToken(): string {
    return this.sessionService.getSession().access_token;
  }

  public decodePayloadJWT(): DecodedJWT | null {
    try {
      return jwt_decode(this.getToken());
    } catch (Error) {
      return null;
    }
  }

  public decodeFromString(accessToken:string): DecodedJWT | null {
    try {
      return jwt_decode(accessToken);
    } catch (Error) {
      return null;
    }
  }
}
